import { removeDuplicates } from '../helpers/array';

export class AccessService {
	constructor(graphqlClient, loggedInUser) {
		this.graphqlClient = graphqlClient;
		this.loggedInUser = loggedInUser;
	}

	hasPermissionOnCompanyResource(name, user = this.loggedInUser) {
		return this.hasPermissionOnResource(name, 'company', this.loggedInUser.company.id, user);
	}

	hasPermissionOnResource(name, resourceType, resourceReferenceId, user = this.loggedInUser) {
		const policy = this.findPolicyByResourceTypeAndResourceReferenceId(resourceType, resourceReferenceId, user.policies);
		if (!policy) {
			return false;
		}

		const permission = this.findPermissionForUserInBindingsRole(name, user.id, policy.bindings);
		return !!permission;
	}

	findPolicyByResourceTypeAndResourceReferenceId(resourceType, resourceReferenceId, policies) {
		return policies.find(
			(policy) => { return policy.resource.type === resourceType && policy.resource.referenceId === resourceReferenceId; }
		);
	}

	hasPermissionOnAnyResource(name, user = this.loggedInUser) {
		for (const policy of user.policies) {
			const permission = this.findPermissionForUserInBindingsRole(name, user.id, policy.bindings);
			if (permission) {
				return true;
			}
		}

		return false;
	}

	findPermissionForUserInBindingsRole(name, userId, bindings) {
		for (const binding of bindings) {
			if (binding?.userIds.includes(userId)) {
				const permission = binding.role.permissions.find((permission) => { return permission.name === name; });
				if (permission) {
					return permission;
				}
			}
		}

		return null;
	}

	hasRoleOnCompanyResource(name, user = this.loggedInUser) {
		return this.hasRoleOnResource(name, 'company', this.loggedInUser.company.id, user);
	}

	hasRoleOnResource(name, resourceType, resourceReferenceId, user) {
		const policy = this.findPolicyByResourceTypeAndResourceReferenceId(resourceType, resourceReferenceId, user.policies);
		if (!policy) {
			return false;
		}

		return this.isRoleInBindings(name, policy.bindings);
	}

	isRoleInBindings(name, bindings) {
		for (const binding of bindings) {
			if (binding.role.name === name) {
				return true;
			}
		}

		return false;
	}

	getNumberOfServices(user = this.loggedInUser) {
		let services = [];
		for (const policy of user.policies) {
			for (const binding of policy.bindings) {
				if (binding.role.name === 'user.admin') {
					services.push('user management');
				}

				if (binding.role.name === 'fleet.admin' || binding.role.name === 'fleet.editor') {
					services.push('fleet management');
				}

				if (binding.role.name === 'ras.viewer') {
					services.push('ras');
				}
			}
		}

		services = removeDuplicates(services);

		return services.length;
	}

	getUserRoles(user = this.loggedInUser) {
		const roles = [];
		for (const policy of user.policies) {
			roles.push(...policy.bindings.map((binding) => { return binding.role.name; }));
		}

		return roles;
	}

	getUserGlobalRoles(user = this.loggedInUser) {
		const globalRoles = ['fleet.admin', 'user.admin'];
		const allRoles = this.getUserRoles(user);

		return allRoles.filter((role) => {
			return globalRoles.includes(role);
		});
	}

	findResourcesByType(type, user = this.loggedInUser) {
		const resources = [];

		for (const policy of user.policies) {
			if (policy.resource.type === type) {
				resources.push(policy.resource);
			}
		}

		return resources;
	}
}
