import React, { useContext } from 'react';
import { Toast } from '@syneto/compass-react';
import { NotificationContext } from '../contexts/NotificationContext';

export const Notification = () => {
	const { notifications, removeNotification } = useContext(NotificationContext);

	return (
		<div
			style={{
				position: 'fixed',
				bottom: 20,
				right: 0,
				margin: 30,
				width: 350,
				height: 'auto',
				zIndex: 99999,
			}}
		>
			{notifications.map((notification) => {
				const item = notification[notification.key];

				return item.text !== '' ? (
					<Toast
						color={item.type}
						show={true}
						onClose={() => {
							removeNotification(notification.key);
						}}
						delay={9000}
						autohide
						key={notification.key}
					>
						<Toast.Header
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							{item.type === 'danger' ? 'Error' : item.type.toUpperCase()}
						</Toast.Header>
						<Toast.Body>{item.text}</Toast.Body>
					</Toast>
				) : (
					''
				);
			})}
		</div>
	);
};
