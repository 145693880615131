import React, { useContext, useState } from 'react';
import { NotificationContext } from '../../contexts/NotificationContext';
import { Row, Col, Button } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import { InputField } from '../../components/Form';
import { ServiceContext } from '../../contexts/ServiceContext';

export const VirtualAppliance = () => {
	const { machineService, settingsService } = useContext(ServiceContext);
	const { pushDangerNotification } = useContext(NotificationContext);
	const { register, handleSubmit } = useForm();

	const [showDownloadFiles, setShowDownloadFiles] = useState(false);
	const [virtualApplianceSettings, setVirtualApplianceSettings] = useState(null);

	const onSubmit = async (values) => {
		try {
			const isActivationKeyValid = await machineService.validateMachineActivationKey(values.activationKey);
			if (!isActivationKeyValid) {
				pushDangerNotification('Invalid activation code.');
			} else {
				setVirtualApplianceSettings(await settingsService.getVirtualApplianceSettings(values.activationKey));
				setShowDownloadFiles(true);
			}
		} catch (error) {
			pushDangerNotification('Failed to validate activation code.');
		}
	};

	const renderNotAvailableText = () => {
		return <p className="text-muted font-weight-light mt-2">Url is not available</p>;
	};
	
	const DownloadFiles = () => {
		return (
			<div className="mt-5 ms-5">
				<h1 className="text-muted font-weight-light">Access Virtual Appliance Downloads</h1>

				<h4 className="text-muted font-weight-light mt-5 mb-4">Virtual appliance downloads for Syneto OS4</h4>

				<Row>
					<Col lg={2} md={4} sm={6} xs={8}>
						<p className="text-muted font-weight-light mt-2">Installation Manual</p>
					</Col>

					<Col>
						{(virtualApplianceSettings?.os4guideUrl && virtualApplianceSettings.os4guideUrl !== '') 
							? (
								<div>
									<a href={virtualApplianceSettings.os4guideUrl} target="_blank" rel="noopener noreferrer">
									Download PDF
									</a>
								</div>
							)
							: renderNotAvailableText()}
					</Col>
				</Row>
				
				<Row>
					<Col lg={2} md={4} sm={6} xs={8}>
						<p className="text-muted font-weight-light mt-2">Virtual Appliance Image</p>
					</Col>

					<Col>
						{(virtualApplianceSettings?.os4IsoUrl && virtualApplianceSettings.os4IsoUrl !== '') 
							? (
								<div>
									<a href={virtualApplianceSettings.os4IsoUrl} target="_blank" rel="noopener noreferrer">
									Download OVA
									</a>
								</div>
							)
							: renderNotAvailableText()}
					</Col>
				</Row>
				
				<Row>
					<Col lg={2} md={4} sm={6} xs={8}>
						<p className="text-muted font-weight-light mt-2">MD5 File</p>
					</Col>

					<Col>
						{(virtualApplianceSettings?.os4MD5Url && virtualApplianceSettings.os4MD5Url !== '') 
							? (
								<div>
									<a href={virtualApplianceSettings.os4MD5Url} target="_blank" rel="noopener noreferrer">
										Download MD5
									</a>
								</div>
							)
							: renderNotAvailableText()}
					</Col>
				</Row>

				<h4 className="text-muted font-weight-light mt-5 mb-4">Virtual appliance downloads for Syneto OS5</h4>
				
				<Row>
					<Col lg={2} md={4} sm={6} xs={8}>
						<p className="text-muted font-weight-light mt-2">Installation Manual</p>
					</Col>

					<Col>
						{(virtualApplianceSettings?.os5guideUrl && virtualApplianceSettings.os5guideUrl !== '') 
							? (
								<div>
									<a href={virtualApplianceSettings.os5guideUrl} target="_blank" rel="noopener noreferrer">
										Download PDF
									</a>
								</div>
							)
							: renderNotAvailableText()}
					</Col>
				</Row>
								
				<Row>
					<Col lg={2} md={4} sm={6} xs={8}>
						<p className="text-muted font-weight-light mt-2">Virtual Appliance Image</p>
					</Col>

					<Col>
						{(virtualApplianceSettings?.os5IsoUrl && virtualApplianceSettings.os5IsoUrl !== '') 
							? (<div>
								<a href={virtualApplianceSettings.os5IsoUrl} target="_blank" rel="noopener noreferrer">
									Download OVA
								</a>
							</div>
							)
							: renderNotAvailableText()}
					</Col>
				</Row>

				<Row>
					<Col lg={2} md={4} sm={6} xs={8}>
						<p className="text-muted font-weight-light mt-2">MD5 File</p>
					</Col>

					<Col>
						{(virtualApplianceSettings?.os5MD5Url && virtualApplianceSettings.os5MD5Url !== '') 
							? (<div>
								<a href={virtualApplianceSettings.os5MD5Url} target="_blank" rel="noopener noreferrer">
									Download MD5
								</a>
							</div>)
							: renderNotAvailableText()}
					</Col>
				</Row>
			</div>
		);
	};

	const VerifyActivationKeyForm = () => {
		return (
			<div className="mt-5 ms-5">
				<h1 className="text-muted font-weight-light">Verify activation key</h1>

				<form onSubmit={handleSubmit(onSubmit)}>
					<Row className="mt-5">
						<Col xs={11} sm={11} md={6} lg={4}>
							<p className="text-muted font-weight-light mt-5">
								In order to access your Virtual Appliance Download links, verify your activation code below.
							</p>

							<InputField {...register('activationKey')} />

							<Button type="submit" className="float-right mt-2">
								Verify
							</Button>
						</Col>
					</Row>
				</form>
			</div>
		);
	};

	return showDownloadFiles ? DownloadFiles() : VerifyActivationKeyForm();
};
