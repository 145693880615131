import React, { createContext, useContext, useReducer } from 'react';
import { ServiceContext } from './ServiceContext';
import { StoreContext } from './StoreContext';
import { NotificationContext } from './NotificationContext';

export const DashboardContext = createContext(undefined);

const initialState = {
	error: null,
	loading: true,
	machines: [],
	unassignedMachines: [],
	locations: [],
	users: [],
	usersWithFleetAdminRole: [],
};

const actions = {
	getDataLoading: 'dashboard/getDataLoading',
	getDataSuccess: 'dashboard/getDataSuccess',
	getDataFailure: 'dashboard/getDataFailure',
};

export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.getDataLoading: {
			return {
				...state,
				loading: true,
			};
		}
		case actions.getDataSuccess: {
			return {
				...state,
				loading: false,
				...action.payload,
			};
		}
		case actions.getDataFailure: {
			return {
				...state,
				error: action.payload,
				loading: true,
			};
		}
		default:
			return state;
	}
};

export const DashboardContextProvider = ({ children }) => {
	const { machineService, locationService, userService, accessService, policyService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;
	const { pushDangerNotification } = useContext(NotificationContext);

	const [dashboardState, dispatch] = useReducer(reducer, initialState);

	const getDataLoading = () => {
		return {
			type: actions.getDataLoading,
			payload: null,
		};
	};

	const getDataSuccess = (data) => {
		const { machines, locations, users, usersWithFleetAdminRole } = data;

		return {
			type: actions.getDataSuccess,
			payload: {
				machines: machines,
				unassignedMachines: machines.filter((machine) => { return machine.locationId === null; }),
				locations: locations,
				users: users,
				usersWithFleetAdminRole: usersWithFleetAdminRole,
			},
		};
	};

	const getDataFailure = (error) => {
		return {
			type: actions.getDataFailure,
			payload: error,
		};
	};

	const getData = async () => {
		try {
			dispatch(getDataLoading());

			const locationsResponse = await locationService.getLocations();
			const locations = locationsResponse.sort((a, b) => { return a?.name.localeCompare(b?.name); });

			const machines = await machineService.getMachinesByCompanyId(loggedInUser.company.id);
			const policies = await policyService.getPoliciesByCompanyId(loggedInUser.company.id);

			await locationService.attachDependenciesToLocations(machines, policies, locations);

			const users = await userService.getUsersByCompanyId(loggedInUser.company.id);
			const usersWithFleetAdminRole = users.filter((user) => { return accessService.hasRoleOnCompanyResource('fleet.admin', user); }
			);

			dispatch(getDataSuccess({ machines, locations, users, usersWithFleetAdminRole }));
		} catch (error) {
			dispatch(getDataFailure(error));
			pushDangerNotification('Failed to fetch dashboard data.');
		}
	};

	return (
		<DashboardContext.Provider
			value={{
				loading: dashboardState.loading,
				machines: dashboardState.machines,
				unassignedMachines: dashboardState.unassignedMachines,
				locations: dashboardState.locations,
				users: dashboardState.users,
				usersWithFleetAdminRole: dashboardState.usersWithFleetAdminRole,
				getData,
			}}
		>
			{children}
		</DashboardContext.Provider>
	);
};
