import React, { useContext, useState } from 'react';
import { Button, Table } from '@syneto/compass-react';
import { DashboardContext } from '../../../../contexts/DashboardContext';
import { AssignMachinesToLocationModal } from './components/AssignMachinesToLocationModal';

export const UnassignedMachines = () => {
	const { getData, unassignedMachines } = useContext(DashboardContext);

	const [selectedMachines, setSelectedMachines] = useState([]);
	const [showAssignMachinesModal, setShowAssignMachinesModal] = useState(false);

	const openAssignMachinesModal = (selected) => {
		if (selected.length) {
			setSelectedMachines(selected);
			setShowAssignMachinesModal(true);
		}
	};

	const renderActions = (cell, row) => {
		return (
			<Button role="tertiary" onClick={() => { return openAssignMachinesModal([row]); }}>
				Assign
			</Button>
		);
	};

	return (
		unassignedMachines.length > 0 && (
			<div className="col-xl-4 col-lg-6 col-md-12 unassigned-machines">
				<h5 className="font-weight-bold my-4">Unassigned machines</h5>
				<div className="overflow-auto table-background">
					<Table
						headerClasses="header-class"
						data={unassignedMachines}
						keyField="serialNumber"
						selectable="multiple"
						actions={[
							{
								label: 'Refresh',
								onClick: getData,
							},
							{
								label: 'Assign',
								onClick: (selected) => { return openAssignMachinesModal(selected); },
							},
						]}
					>
						<Table.Col field="serialNumber" align="left">
							Serial no.
						</Table.Col>
						<Table.Col field="productModel.title" align="left">
							Model
						</Table.Col>
						<Table.Col align="center" renderCell={renderActions}>
							Actions
						</Table.Col>
					</Table>
				</div>

				{showAssignMachinesModal && (
					<AssignMachinesToLocationModal
						show={showAssignMachinesModal}
						onHide={() => { return setShowAssignMachinesModal(false); }}
						selectedMachines={selectedMachines}
					/>
				)}
			</div>
		)
	);
};
