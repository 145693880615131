import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Icon, Popover } from '@syneto/compass-react';
import { getOSVersion, parseOSVersion } from '../../../../../helpers/OSVersionParser';
import { StoreContext } from '../../../../../contexts/StoreContext';

export const UpdateStatus = (props) => {
	const { machines } = props;
	const { state } = useContext(StoreContext);
	const { osLatestVersions } = state;

	const [expiringMachines, setExpiringMachines] = useState([]);

	useEffect(() => {
		let expiringMachinesArray = [];

		if (machines && machines.length) {
			machines.forEach((machine) => {
				const machineCurrentVersion = parseOSVersion(machine.osVersion);
				const osVersion = getOSVersion(machine.osVersion);
				const osLatestVersion = osLatestVersions && osVersion ?  parseOSVersion(osLatestVersions[osVersion]) : 'N/A';

				if (machineCurrentVersion < osLatestVersion) { expiringMachinesArray.push(machine); }
			});
		}

		setExpiringMachines(expiringMachinesArray);
	}, [machines]);

	if (!expiringMachines.length) {
		return (
			<div className="d-flex">
				All machines up to date
				<Icon className="pt-1 ms-2" name="fas fa-check-circle" color="#00b170" size="lg" />
			</div>
		);
	} else {
		return (
			<div className="location-card-status-row">
				{expiringMachines.length === 1 ? '1 Machine needs update' : ` ${expiringMachines.length} Machines need update`}

				<Popover
					trigger="click"
					placement="top"
					body={
						<>
							{expiringMachines.map((machine, index) => {
								return (
									<div key={index} className="location-card-popover-container">
										<strong>{machine.name || machine.serialNumber}</strong>
										<span>&nbsp; | {machine.osVersion} &nbsp;</span>
									</div>
								);
							})}
						</>
					}
					className="location-popover"
				>
					<span>
						<Icon
							className="pt-1 ms-2"
							name="fas fa-exclamation-triangle"
							color="#F4BE00"
							size="lg"
							style={{ cursor: 'pointer' }}
						/>
					</span>
				</Popover>
			</div>
		);
	}
};

UpdateStatus.propTypes = {
	machines: PropTypes.array.isRequired,
};
