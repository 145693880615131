import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Badge, Modal, Table } from '@syneto/compass-react';
import * as yup from 'yup';
import { NotificationContext } from '../../../../../contexts/NotificationContext';
import { ServiceContext } from '../../../../../contexts/ServiceContext';
import { DashboardContext } from '../../../../../contexts/DashboardContext';

export const AssignMachinesToLocationModal = (props) => {
	const { show, onHide, selectedMachines } = props;
	const { getData, locations } = useContext(DashboardContext);
	const { machineService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);

	const [selectedLocationId, setSelectedLocationId] = useState('');
	const selectedMachineSerialNumbers = selectedMachines.map((machine) => { return machine.serialNumber; });
	const [submitting, setSubmitting] = useState(false);

	const onSubmit = async () => {
		try {
			setSubmitting(true);
			const assignMachinesToLocationModel = yup.string().required('No location selected');
			await assignMachinesToLocationModel.validate(selectedLocationId);

			await machineService.addLocationToMachines(selectedLocationId, selectedMachineSerialNumbers);

			await getData();
			onHide();
			pushSuccessfulNotification(
				`${selectedMachineSerialNumbers.length > 1 ? 'Machines' : 'Machine'} assigned succesfully.`
			);
		} catch (error) {
			pushDangerNotification(
				`Failed to assign ${selectedMachineSerialNumbers.length > 1 ? 'machines' : 'machine'} to location.`
			);
			setSubmitting(false);
		}
	};

	const renderNoOfMachines = (cell, row) => {
		return <Badge>{`${row.machines.length} ${row.machines.length === 1 ? 'Machine' : 'Machines'}`}</Badge>;
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			content={
				<>
					<p className="text-muted">
						The following {selectedMachines.length > 1 ? 'machines' : 'machine'} will be assigned to your chosen
						location:
					</p>

					{selectedMachines.length &&
						selectedMachines.map((machine) => {
							return (
								<h5 key={machine.serialNumber} className="text-muted mb-4">
									{`${machine.serialNumber} / ${machine.productModel.title}`}
								</h5>
							);
						})}

					<Table
						data={locations}
						keyField="id"
						selectRow={{
							mode: 'radio',
							classes: 'selected',
							clickToSelect: true,
							hideSelectColumn: true,
							selected: [selectedLocationId],
							onSelect: (location) => { return setSelectedLocationId(location.id); },
						}}
						rowStyle={{
							cursor: 'pointer',
						}}
					>
						<Table.Col field="name">Locations</Table.Col>
						<Table.Col field="noOfMachines" align="right" renderCell={renderNoOfMachines} />
					</Table>

					{!locations.length ? (
						<p className="text-danger mt-4">
							No locations have been created yet. Please create one or more locations using the button from main
							dashboard page.
						</p>
					) : null}
				</>
			}
			title="Assign machine to location"
			actions={{
				primary: {
					label: 'Assign',
					onClick: onSubmit,
					disabled: submitting,
				},
				secondary: {
					label: 'Cancel',
				},
			}}
		/>
	);
};

AssignMachinesToLocationModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	selectedMachines: PropTypes.array.isRequired,
};
