import React, { forwardRef } from 'react';
import { Form } from '@syneto/compass-react';

export const Checkbox = forwardRef((props, ref) => {
	const { label, icon, id, required } = props;
	const { name, style } = props;
	const { className, disabled, errors, input, defaultValue, checked, onChange } = props;

	return (
		<Form.Group className="mb-3" controlId={id}>
			<Form.Check
				type="checkbox"
				label={
					<span>
						{label} {icon ? icon : ''}
					</span>
				}
				name={name}
				ref={ref}
				className={className}
				disabled={disabled}
				required={required}
				checked={checked}
				onChange={onChange}
				defaultValue={defaultValue}
				// feedback={ errors[ name ] }
				isInvalid={errors && errors[name]}
				style={style}
				{...input}
			/>

			<Form.Control.Feedback type="invalid">{errors && errors[name] && errors[name].message}</Form.Control.Feedback>
		</Form.Group>
	);
});
