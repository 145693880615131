import React, { createContext, useState } from 'react';

export const NotificationContext = createContext();

export const NotificationContextProvider = (props) => {
	const [key, setKey] = useState(0);
	const [notifications, setNotifications] = useState([]);

	const pushSuccessfulNotification = (text) => {
		setKey(key + 1);
		setNotifications([...notifications, { key, [key]: { type: 'success', text: text } }]);
	};

	const pushDangerNotification = (text) => {
		setKey(key + 1);
		setNotifications([...notifications, { key, [key]: { type: 'danger', text: text } }]);
	};

	const pushWarningNotification = (text) => {
		setKey(key + 1);
		setNotifications([...notifications, { key, [key]: { type: 'warning', text: text } }]);
	};

	const removeNotification = (key) => {
		let removeIndex;

		notifications.forEach((item, index) => {
			if (item.key === key) {
				removeIndex = index;
			}
		});

		setNotifications([...notifications.slice(0, removeIndex), ...notifications.slice(removeIndex + 1)]);
	};

	return (
		<NotificationContext.Provider
			value={{
				notifications,
				pushSuccessfulNotification,
				pushDangerNotification,
				pushWarningNotification,
				removeNotification,
			}}
		>
			{props.children}
		</NotificationContext.Provider>
	);
};
