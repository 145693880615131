import React, { createContext, useContext, useReducer } from 'react';
import _ from 'lodash';
import { MachineContext } from './MachineContext';
import { NotificationContext } from './NotificationContext';
import { StoreContext } from './StoreContext';
import { ServiceContext } from './ServiceContext';
import { deleteItemFromLocalStorage } from '../helpers/localStorage';

export const RasContext = createContext(undefined);

const initialState = {
	loadingTable: false,
	loadingTableError: false,
	loadingRasDestination: true,
	locationsWithRas: [],
	locationsWithoutRas: [],
	machinesRasInfo: null,
	selectedLocation: null,
	selectedLocationRasSettingsModal: null,
	rasConfiguration: null,
	rasDestinations: [],
	rasDestinationsEligibleForSplitTunneling: [],
	locationsAvailableForRas: [],
	locationsUnavailableForRas: [],
};

const actions = {
	getAllRasTableData: 'GET_ALL_RAS_TABLE_DATA',
	getAllRasTableDataError: 'GET_ALL_RAS_TABLE_DATA_ERROR',
	getLocationsWithRas: 'GET_LOCATIONS_WITH_RAS',
	getLocationsWithoutRas: 'GET_LOCATIONS_WITHOUT_RAS',
	getMachinesRasInfo: 'GET_MACHINES_RAS_INFO',
	getRasDestinations: 'GET_RAS_DESTINATIONS',
	updateLoadingTable: 'UPDATE_LOADING_TABLE',
	updateLoadingRasConfiguration: 'UPDATE_LOADING_RAS_CONFIGURATION',
	updateLoadingRasDestinations: 'UPDATE_LOADING_RAS_DESTINATIONS',
	updateMachinesRasInfo: 'UPDATE_MACHINES_RAS_INFO',
	updateSelectedLocation: 'UPDATE_SELECTED_LOCATION',
	updateSelectedLocationRasSettingsModal: 'UPDATE_SELECTED_LOCATION_RAS_SETTINGS_MODAL'
};

export const rasReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.getAllRasTableData: {
			return {
				...state,
				loadingTable: false,
				loadingTableError: false,
				...payload,
			};
		}
		case actions.getAllRasTableDataError: {
			return {
				...state,
				loadingTable: false,
				loadingTableError: true
			};
		}
		case actions.getRasDestinations: {
			return {
				...state,
				loadingRasDestination: false,
				...payload,
			};
		}
		case actions.updateLoadingTable:
			return { ...state, loadingTable: payload };
		case actions.updateLoadingRasDestinations:
			return { ...state, loadingRasDestination: payload };
		case actions.updateMachinesRasInfo:
			return { ...state, machinesRasInfo: payload };
		case actions.updateSelectedLocation:
			return {
				...state,
				selectedLocation: payload,
			};
		case actions.updateSelectedLocationRasSettingsModal:
			return { ...state, selectedLocationRasSettingsModal: payload };
		default:
			return state;
	}
};

export const RasContextProvider = ({ children }) => {
	const { machineService, policyService, locationService, rasService, accessService, destinationService } =
		useContext(ServiceContext);
	const { sortMachinesByOsVersion } = useContext(MachineContext);
	const { state } = useContext(StoreContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);

	const [rasState, dispatch] = useReducer(rasReducer, initialState);

	const { loggedInUser } = state;
	const { selectedLocation, selectedLocationRasSettingsModal, machinesRasInfo, locationsWithRas } = rasState;

	const poolingRasDeployingMachines = async () => {
		try {
			if (machinesRasInfo) {
				Object.keys(machinesRasInfo.fulfilled).forEach((serialNumber) => {
					if (machinesRasInfo.fulfilled[serialNumber].status === 'DEPLOYING') {
						setTimeout(async () => {
							const location = locationsWithRas.find(
								(locationWithRas) => { return locationWithRas.gatewayMachineSerialNumber === serialNumber; }
							);
							const updatedFulfilledRasInfo = { ...machinesRasInfo.fulfilled };

							updatedFulfilledRasInfo[serialNumber] = await rasService.getRas(
								serialNumber,
								machineService.getGatewayMachineOsVersion(location)
							);

							rasService.writeRasDeployLogsOnLocalStorage(updatedFulfilledRasInfo[serialNumber], serialNumber);

							const updatedRasInfoForAllMachines = {
								fulfilled: updatedFulfilledRasInfo,
								rejected: [...machinesRasInfo.rejected],
							};

							updateMachinesRasInfo(updatedRasInfoForAllMachines);

							if (updatedFulfilledRasInfo[serialNumber].status !== 'DEPLOYING') {
								// when the ras deploy is finished - reload ras table data && cleanup local storage
								await getRasTableData();
								deleteItemFromLocalStorage(serialNumber);
							}
						}, 10000);
					}
				});
			}
		} catch (error) {
			pushDangerNotification('Failed to fetch RAS data.');
		}
	};

	const getRasTableData = async () => {
		updateLoadingTable(true);
		
		try {
			const locations = await locationService.getRasLocations();
			const locationIds = locations.map((location) => { return location.id; });

			const machines = accessService.hasPermissionOnCompanyResource('location.create')
				? await machineService.getMachinesByCompanyId(loggedInUser.company.id)
				: locationIds.length
					? await machineService.getMachinesByLocationIds(locationIds)
					: [];
			sortMachinesByOsVersion(machines);

			const policies = await policyService.getPoliciesByCompanyId(loggedInUser.company.id);

			await locationService.attachDependenciesToLocations(machines, policies, locations);

			let locationsWithRas = locations.filter((location) => { return location.gatewayMachineSerialNumber !== ''; });
			const locationsWithoutRas = locations.filter((location) => { return location.gatewayMachineSerialNumber === '' && location; });

			let locationsAvailableForRas = [];
			let locationsUnavailableForRas = [];

			if (locationsWithoutRas) {
				locationsAvailableForRas = locationsWithoutRas.filter(
					(location) => {
						return rasService.locationHasAtLeastOneOsVersionSuitable(location.machines) !== 0 &&
							(accessService.hasPermissionOnCompanyResource('ras.deploy') ||
								accessService.hasPermissionOnResource('ras.deploy', 'location', location.id));
					}
				);
			}

			if (locationsWithoutRas) {
				locationsUnavailableForRas = locationsWithoutRas.filter(
					(location) => {
						return rasService.locationHasAtLeastOneOsVersionSuitable(location.machines) === 0 &&
							(accessService.hasPermissionOnCompanyResource('ras.deploy') ||
								accessService.hasPermissionOnResource('ras.deploy', 'location', location.id));
					}
				);
			}

			const machinesRasInfo = await rasService.getRasByLocations(locationsWithRas);

			if (Object.keys(machinesRasInfo?.rejected).length) {
				Object.keys(machinesRasInfo.rejected).forEach((serialNumber) => {
					pushDangerNotification(`${serialNumber} failed to fetch RAS data.`);
				});
			}

			// TODO: this needs to be refactored. maybe machinesRasInfo can be directly injected into locationsWithRas
			//  from the beginning, without doing all the fulfilled/rejected logic

			if (Object.keys(machinesRasInfo?.fulfilled).length) {
				Object.keys(machinesRasInfo.fulfilled).forEach((serialNumber) => {
					locationsWithRas =
						locationsWithRas?.length &&
						locationsWithRas.map((locationWihRas) => {
							if (locationWihRas.gatewayMachineSerialNumber === serialNumber) {
								return {
									...locationWihRas,
									ras: machinesRasInfo.fulfilled[serialNumber],
								};
							}

							return locationWihRas;
						});
				});
			}

			const allLocationsPromises =
				locationsWithRas?.length &&
				locationsWithRas.map((locationWihRas) => {
					if (locationWihRas.ras?.status === 'ACTIVE') {
						return rasService
							.getRasConfiguration(
								locationWihRas.gatewayMachineSerialNumber,
								machineService.getGatewayMachineOsVersion(locationWihRas)
							)
							.then((response) => {
								return { ...response, gatewayMachineSerialNumber: locationWihRas.gatewayMachineSerialNumber };
							});
					}

					return locationWihRas;
				});

			const rasConfigs = allLocationsPromises && (await Promise.all(allLocationsPromises));

			rasConfigs?.length &&
				rasConfigs.forEach((rasConfig) => {
					locationsWithRas =
						locationsWithRas?.length &&
						locationsWithRas.map((locationWihRas) => {
							if (locationWihRas.gatewayMachineSerialNumber === rasConfig.gatewayMachineSerialNumber) {
								return {
									...locationWihRas,
									rasConfig,
								};
							}

							return locationWihRas;
						});
				});

			dispatch({
				type: actions.getAllRasTableData,
				payload: {
					locationsWithRas,
					locationsWithoutRas,
					machinesRasInfo,
					locationsAvailableForRas,
					locationsUnavailableForRas,
				},
			});
		} catch (error) {
			fetchRasTablesHasError();
			pushDangerNotification('Failed to fetch RAS table data.');
		}
	};

	const getRasByLocations = async () => {
		try {
			const machinesRasInfo = await rasService.getRasByLocations(locationsWithRas);
			updateMachinesRasInfo(machinesRasInfo);
		} catch (e) {
			fetchRasTablesHasError();
			pushDangerNotification('Failed to fetch RAS table data.');
		}
	};

	const getDestinationsUsedInAclOrSplitTunneling = (location) => {
		let destinationsUsedInAclOrSplitTunneling = [];
		const rasInfo = rasState?.machinesRasInfo?.fulfilled[location?.gatewayMachineSerialNumber];

		const selectedRasLocation = locationsWithRas.find((l) => {
			return l.gatewayMachineSerialNumber === rasState?.selectedLocation?.gatewayMachineSerialNumber;
		});

		const rasUsers = (rasInfo && _.compact(rasInfo?.users)) || [];
		const splitTunnelingRoutes = (rasInfo && _.compact(selectedRasLocation?.rasConfig?.routes)) || [];

		rasUsers.forEach((user) => {
			user?.allowedDestinations?.length &&
				user.allowedDestinations.forEach((allowedDestination) => {
					destinationsUsedInAclOrSplitTunneling.push(allowedDestination);
				});
		});

		splitTunnelingRoutes.forEach((route) => {
			destinationsUsedInAclOrSplitTunneling.push(route.name);
		});

		return _.compact(destinationsUsedInAclOrSplitTunneling);
	};

	const getRasDestinations = async () => {
		try {
			const location = selectedLocationRasSettingsModal || selectedLocation;

			if (location) {
				const destinationsUsedInAclOrSplitTunneling = getDestinationsUsedInAclOrSplitTunneling(location);

				const rasDestinations = (await destinationService.destinationsByLocationId(location.id)).map(
					(rasDestination) => {
						if (destinationsUsedInAclOrSplitTunneling.includes(rasDestination.name)) {
							rasDestination.currentlyInUse = true;
						}

						return rasDestination;
					}
				);

				dispatch({
					type: actions.getRasDestinations,
					payload: {
						rasDestinations: rasDestinations,
						rasDestinationsEligibleForSplitTunneling: destinationService.filterDestinationsByFormat(rasDestinations, [
							'ip',
							'ipAndMask',
							'allIpsWithPort'
						]),
					},
				});
			}
		} catch (e) {
			pushDangerNotification('Failed to fetch RAS destinations.');
		}
	};

	const updateRasDestinations = async (newRasDestinations) => {
		try {
			dispatch({ type: actions.updateLoadingRasDestinations, payload: true });

			const locationId = selectedLocationRasSettingsModal?.id || selectedLocation?.id;
			const serialNumber =
				selectedLocationRasSettingsModal?.gatewayMachineSerialNumber || selectedLocation?.gatewayMachineSerialNumber;

			if (locationId) {
				const rasDestinationsResponse = await destinationService.setDestinations(locationId, newRasDestinations);
				serialNumber &&
					rasService.setRasNetworkDestinations(serialNumber, rasService.prepareDestinationsForHyper(newRasDestinations));

				if (rasDestinationsResponse) {
					await getRasDestinations();
				}

				pushSuccessfulNotification('Destinations have been successfully updated.');
			}
		} catch (error) {
			pushDangerNotification('Failed to submit destinations.');
		}
	};

	const updateLoadingTable = (payload) => { return dispatch({ type: actions.updateLoadingTable, payload }); };
	const updateLoadingRasConfiguration = (payload) => { return dispatch({ type: actions.updateLoadingRasConfiguration, payload }); };

	const updateMachinesRasInfo = (payload) => { return dispatch({ type: actions.updateMachinesRasInfo, payload }); };
	const updateSelectedLocation = (payload) => { return dispatch({ type: actions.updateSelectedLocation, payload }); };

	const updateSelectedLocationRasSettingsModal = (payload) => { return dispatch({ type: actions.updateSelectedLocationRasSettingsModal, payload }); };
	const fetchRasTablesHasError = () => { return dispatch({ type: actions.getAllRasTableDataError }); }; 

	return (
		<RasContext.Provider
			value={{
				loadingTable: rasState.loadingTable,
				loadingTableError: rasState.loadingTableError,
				loadingRasDestination: rasState.loadingRasDestination,
				locationsWithRas: rasState.locationsWithRas,
				locationsWithoutRas: rasState.locationsWithoutRas,
				machinesRasInfo: rasState.machinesRasInfo,
				selectedLocation: rasState.selectedLocation,
				selectedLocationRasSettingsModal: rasState.selectedLocationRasSettingsModal,
				rasConfiguration: rasState.rasConfiguration,
				rasDestinations: rasState.rasDestinations,
				rasDestinationsEligibleForSplitTunneling: rasState.rasDestinationsEligibleForSplitTunneling,
				locationsAvailableForRas: rasState.locationsAvailableForRas,
				locationsUnavailableForRas: rasState.locationsUnavailableForRas,
				poolingRasDeployingMachines,
				getRasTableData,
				getRasDestinations,
				getRasByLocations,
				updateLoadingTable,
				updateLoadingRasConfiguration,
				updateMachinesRasInfo,
				updateSelectedLocation,
				updateSelectedLocationRasSettingsModal,
				updateRasDestinations,
			}}
		>
			{children}
		</RasContext.Provider>
	);
};
