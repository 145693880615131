import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown, Icon, Table } from '@syneto/compass-react';
import _ from 'lodash';
import { StoreContext } from '../../contexts/StoreContext';
import { ServiceContext } from '../../contexts/ServiceContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { getOSVersion, parseOSVersion } from '../../helpers/OSVersionParser';
import DateFormatter from '../../helpers/DateFormatter';
import { CreateTicketModal } from './components/CreateTicketModal';
import { MachineDetails } from './components/MachineDetails';
import { Loading } from '../../components/Loading';
import { Does } from '../../components/Access/Does';
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import maintenanceCertificationTemplate from './pdf/maintenanceCertificationTemplate';
import CurrentlyActiveMaintenancePlan from '../../helpers/CurrentlyActiveMaintenancePlan';
import InfoIcon from '../../components/Form/InfoIcon';

const MACHINE_ACTIONS = {
	open_tiket: 'Open Tiket',
	download_manual: 'Download Manual',
	download_maintenance_certification: 'Download maintenance certification'
};

export const Inventory = () => {
	const location = useLocation();

	const { machineService, locationService, userService, accessService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser, osLatestVersions } = state;
	const { pushDangerNotification } = useContext(NotificationContext);

	const [showTicketModal, toggleTicketModal] = useState(false);
	const [machines, setMachines] = useState([]);
	const [selectedMachine, setSelectedMachine] = useState(null);
	const [loading, setLoading] = useState(true);

	const defaultFilterLocation = location?.state?.locationName || '';

	const loadMachinesData = useCallback(async () => {
		try {
			const locations = await locationService.getLocations();
			const locationIds = locations.map((location) => { return location.id; });
			const machines = accessService.hasPermissionOnCompanyResource('location.create')
				? await machineService.getMachinesByCompanyId(loggedInUser.company.id)
				: locationIds.length
					? await machineService.getMachinesByLocationIds(locationIds)
					: [];
			const users = await userService.getUsersByCompanyId(loggedInUser.company.id);

			machineService.attachDependenciesToMachines(locations, users, machines);

			const locationMachines = machines.filter((m) => { return m.location === defaultFilterLocation; });

			if (locationMachines.length) { setSelectedMachine(locationMachines[0]); }

			setMachines(machines);
			setLoading(false);
		} catch (error) {
			pushDangerNotification('Failed to fetch inventory data.');
		}
	}, [selectedMachine]);

	useEffect(() => {
		loadMachinesData();

		return () => {
			setLoading(true);
		};
	}, []);

	const renderLocation = (cell, row) => {
		return <div>{row.location ? row.location : 'N/A'}</div>;
	};

	const locationFilter = () => {
		const locations = new Set();
		const opts = [];
		machines.forEach((machine) => { return locations.add(machine.location); });
		locations.forEach((location) => { return location && opts.push({ label: location, value: location }); });

		return _.orderBy(opts, ['label'], ['asc']);
	};

	const renderHostName = (cell, row) => {
		return <div>{row.name ? row.name : 'N/A'}</div>;
	};

	const renderOSVersion = (cell, row) => {
		const machineCurrentVersion = parseOSVersion(row.osVersion);
		const osVersion = getOSVersion(row.osVersion);
		const osLatestVersion = osLatestVersions && osVersion ?  parseOSVersion(osLatestVersions[osVersion]) : 'N/A';

		return (
			<div className="d-flex align-items-center">
				<div className="me-3">{machineCurrentVersion ? machineCurrentVersion : 'N/A'}</div>
				{machineCurrentVersion < osLatestVersion && (
					<InfoIcon placement="right" text="Your Syneto OS is not up to date" iconType="exclamation" iconColor="#f4be00" />
				)}
			</div>
		);
	};

	const renderExpirationDate = (cell, row) => {
		if (row.maintenanceServices.length && row.maintenanceServices[0].expiresOn) {
			return DateFormatter.formatDateToSpecificFormat(row.maintenanceServices[0], 'expiresOn', 'DD/MM/YYYY');
		}

		return '';
	};

	const generateMaintenanceCertificationPdf = (expirationDate, maintenancePlan, serialNumber) => {
		const docDefinition = maintenanceCertificationTemplate(expirationDate, maintenancePlan, serialNumber);
		const { vfs } = vfsFonts.pdfMake;

		pdfMake.vfs = vfs;
		pdfMake.createPdf(docDefinition).download(`${new Date().valueOf()}.pdf`);
	};

	const dropdownOnSelectActions = (key, machine) => {
		switch (key) {
			case MACHINE_ACTIONS.open_tiket:
				toggleTicketModal(true);
				break;
			case MACHINE_ACTIONS.download_manual: {
				const win = window.open(
					`https://storage.googleapis.com/central-manuals/user-guide-syneto-os-${parseOSVersion(machine.osVersion)}.pdf`,
					'_blank'
				);

				win.focus();
				break;
			}
			case MACHINE_ACTIONS.download_maintenance_certification: {
				const currentlyActiveMaintenancePlan = CurrentlyActiveMaintenancePlan(machine.maintenanceServices[0]);
				generateMaintenanceCertificationPdf(
					renderExpirationDate(null, machine),
					currentlyActiveMaintenancePlan.maintenancePlan.customerFacingName,
					machine.serialNumber
				);
				break;
			}
			default:
				break;
		}
	};

	const renderActions = (cell, row) => {
		return (
			<Dropdown
				className="text-primary"
				label={<Icon name="fas fa-ellipsis-v" />}
				onSelect={(eventKey) => { return dropdownOnSelectActions(eventKey, row); }}
				items={[
					{
						label: 'Open a ticket',
						eventKey: MACHINE_ACTIONS.open_tiket
					},
					{
						label: 'Download manual',
						eventKey: MACHINE_ACTIONS.download_manual
					},
					{
						label: 'Download maintenance certification',
						eventKey: MACHINE_ACTIONS.download_maintenance_certification
					},
				]}
			/>
		);
	};

	return loading ? (
		<Loading />
	) : (
		<div className="min-vh-80 shadow p-3 bg-white rounded">
			{machines.length ? (
				<>
					<div className="min-vh-80 row">
						<div className="col-9 border-right">
							<Table
								data={machines}
								keyField="serialNumber"
								search
								selectRow={{
									mode: 'radio',
									classes: 'selected',
									clickToSelect: true,
									hideSelectColumn: true,
									selected: [selectedMachine?.serialNumber],
									onSelect: (device) => { return setSelectedMachine(device); },
								}}
							>
								<Table.Col field="productModel.title" align="left">
									Model
								</Table.Col>
								<Table.Col field="serialNumber" align="left">
									Serial Number
								</Table.Col>
								<Table.Col renderCell={renderHostName} field="name" align="left">
									Host Name
								</Table.Col>
								<Table.Col
									renderCell={renderLocation}
									field="location"
									align="left"
									filter="select"
									filterOptions={locationFilter()}
									filterDefault={defaultFilterLocation}
								>
									Location
								</Table.Col>
								<Table.Col renderCell={renderOSVersion} field="osVersion" align="left">
									OS
								</Table.Col>
								<Table.Col renderCell={renderExpirationDate} field="expirationDate" align="left">
									Expiration Date
								</Table.Col>
								<Table.Col width={32} align="left" renderCell={renderActions} />
							</Table>

							{selectedMachine ? (
								<CreateTicketModal
									selectedDevice={selectedMachine}
									showTicketModal={showTicketModal}
									toggleTicketModal={toggleTicketModal}
								/>
							) : null}
						</div>

						<div className="col-3 p-4">
							<Does
								loggedInUser
								havePermission="appliance.view"
								onAnyResource={true}
								yes={() => {
									return selectedMachine ? (
										<MachineDetails
											machine={selectedMachine}
											generateMaintenanceCertificationPdf={generateMaintenanceCertificationPdf}
										/>
									) : null;
								}
								}
							/>
						</div>
					</div>
				</>
			) : (
				<p>No machines</p>
			)}
		</div>
	);
};
