import { gql } from 'apollo-boost';

export class SettingsService {
	constructor(graphqlClient, backEndAxios) {
		this.graphqlClient = graphqlClient;
		this.backEndAxios = backEndAxios;
		this.apiVersion = 'v1';
	}

	async getSerenityEventsSettings() {
		const serenityEventsSettingsResponse = await this.graphqlClient.query({
			query: gql`
        query serenityEventsSettings {
          serenityEventsSettings {
            serenitySalesEmail
            serenitySupportEmail
            serenityMarketingSite
          }
        }
      `,
			fetchPolicy: 'no-cache',
		});

		return serenityEventsSettingsResponse.data.serenityEventsSettings;
	}

	async sendSerenityRequestEmail(userId, machineSerialNumber = '') {
		const sendSerenityRequestEmailResponse = await this.graphqlClient.query({
			query: gql`
        query ($userId: String!, $machineSerialNumber: String) {
          sendSerenityRequestEmail(userId: $userId, machineSerialNumber: $machineSerialNumber)
        }
      `,
			variables: {
				userId: userId,
				machineSerialNumber: machineSerialNumber,
			},
			fetchPolicy: 'no-cache',
		});

		return sendSerenityRequestEmailResponse.data.sendSerenityRequestEmail;
	}

	getVirtualApplianceSettings = (activationKey) => {
		return this.backEndAxios.get(`/central-backend/${this.apiVersion}/fleet-management-crud/get-virtual-appliance-links`, { params: { activationKey: activationKey } } )
			.then((response) => {
				return response?.data?.virtualApplianceSettings ?? [];
			})
			.catch((error) => {
				throw error?.response?.data?.detail ?? 'Failed to fetch virtual appliance links...';
			});
	};

	async getRenewalsSettings() {
		const renewalsSettingsResponse = await this.graphqlClient.query({
			query: gql`
        query {
          renewalsSettings {
            expiringIntervalSettingForCustomer
          }
        }
      `,
			fetchPolicy: 'no-cache',
		});

		return renewalsSettingsResponse.data.renewalsSettings;
	}
}
