import { useContext } from 'react';
import { ServiceContext } from '../../contexts/ServiceContext';
import PropTypes from 'prop-types';

export const Does = (props) => {
	const { user, havePermission, onResource, onAnyResource, yes, no } = props;
	const { accessService } = useContext(ServiceContext);

	if (
		(onAnyResource && accessService.hasPermissionOnAnyResource(havePermission, user)) ||
	(!onResource && accessService.hasPermissionOnCompanyResource(havePermission, user)) ||
	(onResource && accessService.hasPermissionOnResource(havePermission, onResource.type, onResource.referenceId, user))
	) {
		return yes();
	}

	if (no) {
		return no();
	}

	return null;
};

Does.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		policies: PropTypes.array,
		profile: PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
			phoneNumber: PropTypes.string.isRequired,
			roleInTheCompany: PropTypes.string.isRequired,
			hasVirtualApplianceAccess: PropTypes.bool,
			subscribeToNews: PropTypes.bool.isRequired,
			subscribeToProductNews: PropTypes.bool.isRequired,
			subscribeToSatisfactionSurveys: PropTypes.bool.isRequired,
			dataCollectionConsent: PropTypes.bool.isRequired,
		}),
	}),
	havePermission: PropTypes.string.isRequired,
	onResource: PropTypes.shape({
		type: PropTypes.string,
		referenceId: PropTypes.string,
	}),
	onAnyResource: PropTypes.bool,
	yes: PropTypes.func.isRequired,
	no: PropTypes.func,
};
