import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@syneto/compass-react';
import { profileFields } from '../fields';
import { InputField } from '../../../components/Form';

export const SecurityTab = (props) => {
	const { register, errors } = props;

	return (
		<>
			<Row>
				<Col>
					<h5>Security Settings</h5>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col xs={12} md={12} lg={3}>
					<span className="text-muted">Password</span>
				</Col>

				<Col>
					<Row>
						<Col>
							<InputField
								{...register(profileFields.password)}
								label="Current password"
								type="password"
								errors={errors}
							/>
						</Col>
						<Col>
							<InputField 
								{...register(profileFields.newPassword)}
								label="New password"
								type="password"
								errors={errors} 
							/>
						</Col>
						<Col>
							<InputField
								{...register(profileFields.retypeNewPassword)}
								label="Re-type new password"
								type="password"
								errors={errors}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};

SecurityTab.propTypes = {
	register: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
};
