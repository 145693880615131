import React, { createContext, useContext, useReducer } from 'react';
import { ServiceContext } from './ServiceContext';

export const MachineContext = createContext(undefined);

// RAS requires 4.6.0 OS version

const initialState = {
	machines: [],
	machinesWithOsVersionUnder460: [],
	machinesWithOsVersionAtLeast460: [],
	machinesWithOsVersionAtLeast461: [],
	machinesWithOsVersionAtLeast470: [],
};

const actions = {
	sortMachinesByOsVersion: 'machine/sortMachinesByOsVersion',
};

export const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.sortMachinesByOsVersion:
			return { ...state, ...payload };
		default:
			return state;
	}
};

export const MachineContextProvider = ({ children }) => {
	const { machineService } = useContext(ServiceContext);

	const [machineState, dispatch] = useReducer(reducer, initialState);

	const sortMachinesByOsVersion = (machines) => {
		const machinesWithOsVersionUnder460 = machineService.machinesWithOsVersionSmallerThan(machines, '4.6.0');
		const machinesWithOsVersionAtLeast460 = machineService.machinesWithOsVersionGreaterOrEqualThan(machines, '4.6.0');
		const machinesWithOsVersionAtLeast461 = machineService.machinesWithOsVersionGreaterOrEqualThan(machines, '4.6.1');
		const machinesWithOsVersionAtLeast470 = machineService.machinesWithOsVersionGreaterOrEqualThan(machines, '4.7.0');

		dispatch({
			type: actions.sortMachinesByOsVersion,
			payload: {
				machines,
				machinesWithOsVersionUnder460,
				machinesWithOsVersionAtLeast460,
				machinesWithOsVersionAtLeast461,
				machinesWithOsVersionAtLeast470,
			},
		});
	};

	return (
		<MachineContext.Provider
			value={{
				sortMachinesByOsVersion,
				machines: machineState.machines,
				machinesWithOsVersionUnder460: machineState.machinesWithOsVersionUnder460,
				machinesWithOsVersionAtLeast460: machineState.machinesWithOsVersionAtLeast460,
				machinesWithOsVersionAtLeast461: machineState.machinesWithOsVersionAtLeast461,
				machinesWithOsVersionAtLeast470: machineState.machinesWithOsVersionAtLeast470,
			}}
		>
			{children}
		</MachineContext.Provider>
	);
};
