import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';
import { Modal, ComboBox } from '@syneto/compass-react';
import { StandaloneSearchBox, GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { InputField } from '../../../components/Form';
import { DeleteLocationModal } from './DeleteLocationModal';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { buildComboBoxOptions } from '../../../helpers/select';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { DashboardContext } from '../../../contexts/DashboardContext';
import { Does } from '../../../components/Access/Does';
import { removeDuplicates } from '../../../helpers/array';

export const editLocationValidationSchema = yup.object().shape({
	name: yup.string().required('Cannot be empty'),
	address: yup.string().required('Cannot be empty'),
});

const libraries = ['places'];

export const EditLocationModal = (props) => {
	const { show, onHide, location } = props;
	const {
		getData,
		machines,
		unassignedMachines: unassignedMachinesFromContext,
		users,
		usersWithFleetAdminRole,
	} = useContext(DashboardContext);
	const { locationService, accessService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { register, handleSubmit, setValue, formState:{ errors } } = useForm({
		resolver: yupResolver(editLocationValidationSchema),
	});

	const addressInputRef = useRef(null);
	const machinesAssignedToLocation = machines.filter((machine) => { return machine.locationId === location.id; });
	const usersWithFleetEditorRole = users.filter((user) => { return location.managerUserIds.includes(user.id); });
	const idsOfUsersWithFleetAdminRole = usersWithFleetAdminRole.map(
		(userWithFleetAdminRole) => { return userWithFleetAdminRole.id; }
	);

	const [selectedMachines, setSelectedMachines] = useState([]);
	const [selectedManagers, setSelectedManagers] = useState([]);
	const [unassignedMachines, setUnassigndMachines] = useState(unassignedMachinesFromContext);
	const [showDeleteLocationModal, setShowDeleteLocationModal] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const [mapPosition, setMapPosition] = useState({
		lat: location?.addressCoordinates?.lat,
		lng: location?.addressCoordinates?.lng,
	});
	const [searchedLocation, setSearchedLocation] = useState({});

	const [pinDragged, setPinDragged] = useState(false);
	const [showPinWarningModal, setShowPinWarningModal] = useState(false);

	useEffect(() => {
		register('machineSerialNumbers');
		register('managerUserIds');

		handleMultiChangeMachines(buildComboBoxOptions(machinesAssignedToLocation, 'serialNumber', 'serialNumber'));
		handleMultiChangeManagers(
			buildComboBoxOptions(
				removeDuplicates([...usersWithFleetAdminRole, ...usersWithFleetEditorRole]),
				(user) => { return `${user.profile.firstName} ${user.profile.lastName}`; },
				'id',
				undefined,
				idsOfUsersWithFleetAdminRole
			)
		);
	}, []);

	const onSubmit = async (values) => {
		try {
			if (pinDragged) { return setShowPinWarningModal(true); }
			values.addressCoordinates = mapPosition;

			setSubmitting(true);
			await locationService.updateLocation({
				locationId: location.id,
				...values,
			});
			await getData();
			onHide();
			pushSuccessfulNotification('Location updated successfully.');
		} catch (error) {
			pushDangerNotification('Failed to update location.');
			setSubmitting(false);
		}
	};

	const onPlacesChanged = () => {
		const places = searchedLocation.getPlaces();

		if (places && places.length) {
			setMapPosition({
				lat: places[0].geometry.location.lat(),
				lng: places[0].geometry.location.lng(),
			});
			setValue('address', places[0].formatted_address);
		}
	};

	const onMarkerDragEnd = (e) => {
		const lat = e.latLng.lat();
		const lng = e.latLng.lng();

		setMapPosition({ lat, lng });
		setPinDragged(true);
	};

	const addToUnassignedMachines = (machinesToBeAdded, unassignedMachines) => {
		const newUnassignedMachines = removeDuplicates([...unassignedMachines, ...machinesToBeAdded]);
		setUnassigndMachines(newUnassignedMachines);
	};

	const handleMultiChangeMachines = (
		selectedOptions,
		{ action, removedValue } = { action: undefined, removedValue: undefined }
	) => {
		switch (action) {
			case 'remove-value':
			case 'pop-value': {
				const removedMachine = machines.length && machines.find((m) => { return m.serialNumber === removedValue.value; });
				addToUnassignedMachines([removedMachine], unassignedMachines);
				break;
			}
			case 'clear': {
				addToUnassignedMachines(machinesAssignedToLocation, unassignedMachines);
				break;
			}
			default:
				break;
		}

		setValue(
			'machineSerialNumbers',
			selectedOptions?.length && selectedOptions.filter((option) => { return option.isClearable; }).map((option) => { return option.value; })
		);
		setSelectedMachines(selectedOptions);
	};

	const handleMultiChangeManagers = (
		selectedOptions,
		{ action, removedValue } = { action: undefined, removedValue: undefined }
	) => {
		switch (action) {
			case 'remove-value':
			case 'pop-value':
				if (removedValue.isFixed) {
					return;
				}
				break;
			case 'clear':
				selectedOptions = selectedManagers.filter((option) => { return !option.isClearable; });
				break;
			default:
				break;
		}

		setValue(
			'managerUserIds',
			selectedOptions.filter((option) => { return option.isClearable; }).map((option) => { return option.value; })
		);
		setSelectedManagers(selectedOptions);
	};

	const focusInputAddress = () => { return addressInputRef.current.focus(); };

	return (
		<>
			<Modal
				show={show}
				onHide={onHide}
				slidingPanel={true}
				title="Edit Location"
				content={
					<form>
						<InputField
							{...register('name')}
							label="Location Name*"
							errors={errors}
							defaultValue={location.name}
						/>

						<LoadScriptNext googleMapsApiKey="AIzaSyBUEuEuAKxRVphfHZoUbfDbmEAerBI2jKI" libraries={libraries}>
							<StandaloneSearchBox
								onLoad={(autocomplete) => { return setSearchedLocation(autocomplete); }}
								onPlacesChanged={onPlacesChanged}
							>
								<InputField
									{...register('address')}
									label="Address*"
									placeholder=""
									defaultValue={location.address}
									errors={errors}
								/>
							</StandaloneSearchBox>

							{mapPosition && mapPosition.lat && (
								<GoogleMap
									center={mapPosition}
									zoom={18}
									mapContainerStyle={{
										width: '100%',
										height: 250,
										marginBottom: 20,
									}}
								>
									<Marker position={mapPosition} onDragEnd={(e) => { return onMarkerDragEnd(e); }} draggable={true} />
								</GoogleMap>
							)}
						</LoadScriptNext>

						<Does
							loggedInUser
							havePermission="location.appliance.assign"
							onAnyResource={true}
							yes={() => {
								return (
									<>
										<p>
											<b>Add machines</b>
										</p>
										<ComboBox
											options={buildComboBoxOptions(unassignedMachines, 'serialNumber', 'serialNumber')}
											value={selectedMachines}
											name="machineSerialNumbers"
											placeholder="Select machine"
											onChange={handleMultiChangeMachines}
											isMulti
											styles={{ menu: (styles) => { return { ...styles, zIndex: 999 }; } }}
										/>
									</>
								);
							}}
						/>

						<Does
							loggedInUser
							havePermission="location.manager.assign"
							onCompanyResource
							yes={() => {
								return (
									<>
										<p className="mt-3">
											<b>Managed by</b>
										</p>
										<ComboBox
											options={buildComboBoxOptions(
												users,
												(user) => { return `${user.profile.firstName} ${user.profile.lastName}`; },
												'id',
												undefined,
												idsOfUsersWithFleetAdminRole
											)}
											value={selectedManagers}
											name="managerUserIds"
											placeholder="Select managers"
											onChange={handleMultiChangeManagers}
											isMulti
											styles={{
												menu: (styles) => { return { ...styles, zIndex: 999 }; },
												multiValue: (base, state) => {
													return !state.data.isClearable ? { ...base, backgroundColor: 'gray' } : base;
												},
												multiValueLabel: (base, state) => {
													return !state.data.isClearable ? { ...base, backgroundColor: 'gray', paddingRight: 6 } : base;
												},
												multiValueRemove: (base, state) => {
													return !state.data.isClearable ? { ...base, display: 'none' } : base;
												},
											}}
										/>
									</>
								);
							}}
						/>
					</form>
				}
				actions={{
					primary: {
						label: 'Update',
						onClick: handleSubmit(onSubmit),
						width: 96,
						disabled: submitting,
					},
					secondary: {
						label: 'Cancel',
						onClick: onHide,
						width: 96,
					},
					tertiary: accessService.hasPermissionOnCompanyResource('location.remove')
						? {
							appearance: 'danger',
							role: 'tertiary',
							label: 'Delete',
							onClick: () => { return setShowDeleteLocationModal(true); },
						}
						: null,
				}}
			/>

			{showPinWarningModal && (
				<Modal
					show={showPinWarningModal}
					onHide={() => { return setShowPinWarningModal(false); }}
					title="Warning"
					content="It looks like you have changed the location pin on the map. Please review if the address inserted
	        				in the address field is correct: The accuracy of this address is very important to us, as this is where any
	        				spare parts for your machines will be shipped."
					actions={{
						primary: {
							label: 'The address is correct',
							onClick: () => {
								setPinDragged(false);
								setShowPinWarningModal(false);
							},
						},
						secondary: {
							label: 'Modify the address',
							onClick: () => {
								setShowPinWarningModal(false);
								setTimeout(focusInputAddress, 300);
							},
						},
					}}
				/>
			)}
			<Does
				loggedInUser
				havePermission="location.remove"
				onCompanyResource
				yes={() => {
					return showDeleteLocationModal && (
						<DeleteLocationModal
							show={showDeleteLocationModal}
							onHide={() => { return setShowDeleteLocationModal(false); }}
							onHideEditModal={onHide}
							location={location}
						/>
					);
				}
				}
			/>
		</>
	);
};

EditLocationModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	location: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		address: PropTypes.string.isRequired,
		addressCoordinates: PropTypes.object.isRequired,
		machineSerialNumbers: PropTypes.array,
	}).isRequired,
};
