import '@syneto/compass-react/lib/styles.css';
import './assets/css/style.scss';

import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { RouteLayout } from './components/RouteLayout';
import { Registration } from './pages/Registration';
import { InvitationRegistration } from './pages/InvitationRegistration';
import { Authentication } from './pages/Authentication';
import { PasswordRecovery } from './pages/PasswordRecovery';
import { PasswordReset } from './pages/PasswordReset';
import { Dashboard } from './pages/Dashboard';
import { Inventory } from './pages/Inventory';
import { SerenityEvents } from './pages/SerenityEvents';
import { Monitoring } from './pages/Monitoring';
import { Users } from './pages/Users';
import { DAS } from './pages/DAS';
import { RAS } from './pages/RAS';
import { Profile } from './pages/Profile';
import { VirtualAppliance } from './pages/VirtualAppliance';
import { Support } from './pages/Support';
import { Welcome } from './pages/Welcome';
import { PageNotFound } from './pages/PageNotFound';
import { Authorization } from './components/Authorization';
import { Impersonate } from './pages/Impersonate';
import { RasContextProvider } from './contexts/RasContext';
import { DashboardContextProvider } from './contexts/DashboardContext';
import { MachineContextProvider } from './contexts/MachineContext';

export const App = () => {
	return (
		<Authorization>
			<Routes>
				<Route path="/authentication" element={<Authentication />} />
				<Route path="/password-recovery" element={<PasswordRecovery />} />
				<Route path="/password-reset/:passwordResetToken" element={<PasswordReset />} />
				<Route path="/registration" element={<Navigate to="/sign-up" />} />
				<Route path="/" element={<RouteLayout/>}>
					<Route path="sign-up" element={<Registration />} />
					<Route path="company-account-registration/:hash" element={<InvitationRegistration />} />
					<Route path="profile" element={<Profile />} />
					<Route path="dashboard" element={
						<DashboardContextProvider>
							<Dashboard />
						</DashboardContextProvider>
					} />
					<Route path="inventory" element={<Inventory />} />
					<Route path="serenity-events" element={<SerenityEvents />} />
					<Route path="monitoring" element={<Monitoring />} />
					<Route path="users" element={<Users />} />
					<Route path="das" element={<DAS />} />
					<Route path="ras" element={
						<MachineContextProvider>
							<RasContextProvider>
								<RAS />
							</RasContextProvider>
						</MachineContextProvider>
					} />
					<Route path="virtual-appliance" element={<VirtualAppliance />} />
					<Route path="support" element={<Support />} />
					<Route path="welcome" element={<Welcome />} />
				</Route>
				<Route path="impersonate" element={<Impersonate />} />
				<Route path="*" element={<PageNotFound />} />
			</Routes>
		</Authorization>
	); 
};
