import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Spinner } from '@syneto/compass-react';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { RasContext } from '../../../../contexts/RasContext';
import { ServiceContext } from '../../../../contexts/ServiceContext';

export const UndeployRASConfirmationModal = (props) => {
	const { show, onHide, onHideRASSettingsModal, undeployMessage, location } = props;
	const { rasService, machineService } = useContext(ServiceContext);

	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { getRasTableData, updateLoadingTable } = useContext(RasContext);

	const [submitting, setSubmitting] = useState(false);

	const onSubmit = async () => {
		try {
			setSubmitting(true);

			await rasService.undeployRas(location.gatewayMachineSerialNumber);
			await machineService.removeRasGateway(location.id);

			updateLoadingTable(true);
			getRasTableData();

			onHide();
			onHideRASSettingsModal();
			localStorage.removeItem('rasLogs');

			pushSuccessfulNotification('RAS settings updated successfully.');
		} catch (error) {
			pushDangerNotification(error.message.replace(/GraphQL error:/g, '').trim());
			setSubmitting(false);
		}
	};

	return (
		<Modal show={show} onHide={onHide}>
			<Modal.Header>
				<Modal.Title>Confirm RAS Remove</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<p>{undeployMessage}</p>
			</Modal.Body>

			<Modal.Footer>
				<div className="d-flex w-100 justify-content-between">
					<Button width={96} role="secondary" className="me-3" onClick={onHide}>
            Cancel
					</Button>

					<Button width={96} onClick={onSubmit} disabled={submitting}>
						{submitting ? (
							<span>
								<Spinner animation="border" variant="primary" size="sm" className="text-muted" />
                &nbsp; Disabling...
							</span>
						) : (
							<span>Remove</span>
						)}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

UndeployRASConfirmationModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	onHideRASSettingsModal: PropTypes.func.isRequired,
	undeployMessage: PropTypes.string.isRequired,
	location: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		address: PropTypes.string.isRequired,
		machineSerialNumbers: PropTypes.array,
	}).isRequired,
};
