import React, { useState, useEffect, useContext } from 'react';
import { Button, Table, Tabs } from '@syneto/compass-react';
import { AddUserModal } from './components/AddUserModal';
import { UserDetails } from './components/UserDetails';
import { Loading } from '../../components/Loading';
import { StoreContext } from '../../contexts/StoreContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import './style.scss';
import ConvertTimestampToDateTime from '../../helpers/ConvertTimestampToDateTime';
import { ServiceContext } from '../../contexts/ServiceContext';
import { Does } from '../../components/Access/Does';

export const Users = () => {
	const { userService, accessService } = useContext(ServiceContext);
	const { state, dispatch } = useContext(StoreContext);
	const { loggedInUser } = state;
	const { pushDangerNotification } = useContext(NotificationContext);

	const [showAddModal, setShowAddModal] = useState(false);
	const [users, setUsers] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		loadUsersData();

		return () => {
			setLoading(true);
		};
	}, []);

	const loadUsersData = async () => {
		try {
			const users = await userService.getUsersByCompanyId(loggedInUser.company.id);
			await reloadLoggedInUser();

			setUsersAndSelectedUserHidingMasterAccountsFromRegularUsers(users);

			setLoading(false);
		} catch (error) {
			pushDangerNotification('Failed to fetch users data.');
		}
	};

	const reloadLoggedInUser = async () => {
		if (users.length > 0) {
			dispatch({
				payload: {
					loggedInUser: await userService.getLoggedInUser()
				},
			});
		}
	};

	const setUsersAndSelectedUserHidingMasterAccountsFromRegularUsers = (users) => {
		if (!accessService.hasPermissionOnCompanyResource('account.company.update')) {
			users = users.filter((user) => {
				return !accessService.hasPermissionOnCompanyResource('account.company.update', user);
			});
		}

		setUsers(users);
		setSelectedUserFromUsers(users);
	};

	const setSelectedUserFromUsers = (users) => {
		if (!selectedUser) {
			return setSelectedUser(users[0]);
		}

		const selectedUserIndex = users.findIndex((user) => { return user.id === selectedUser.id; });
		if (selectedUserIndex === -1) {
			return setSelectedUser(users[0]);
		}

		return setSelectedUser(users[selectedUserIndex]);
	};

	const renderLastLogin = (cell, row) => {
		return row.lastUsed ? ConvertTimestampToDateTime(row.lastUsed) : 'N/A';
	};

	return loading ? (
		<Loading />
	) : (
		<div className="min-vh-80 shadow p-3 bg-white rounded">
			<div className="min-vh-80 row">
				<div className="col-9 border-right">
					{users.length ? (
						<Table
							data={users}
							keyField="id"
							search
							extra={
								<div style={{ display: 'flex', flex: 1 }}>
									<Does
										loggedInUser
										havePermission="user.create"
										onCompanyResource
										yes={() => {
											return (
												<>
													<Button role="secondary" onClick={() => { return setShowAddModal(true); }}>
														Add User
													</Button>
													{showAddModal && <AddUserModal show={showAddModal} onHide={() => { return setShowAddModal(false); }} />}
												</>
											);
										}}
									/>
								</div>
							}
							selectRow={{
								mode: 'radio',
								classes: 'selected',
								clickToSelect: true,
								hideSelectColumn: true,
								selected: [selectedUser.id],
								onSelect: (user) => { return setSelectedUser(user); },
							}}
						>
							<Table.Col field="email" align="left">
								Email/User
							</Table.Col>
							<Table.Col field="profile.firstName" align="left">
								First Name
							</Table.Col>
							<Table.Col field="profile.lastName" align="left">
								Last Name
							</Table.Col>
							<Table.Col field="lastUsed" align="left" renderCell={renderLastLogin}>
								Last login
							</Table.Col>
						</Table>
					) : (
						<div className="w-100 h-100 no-users-data" />
					)}
				</div>
				{users.length ? (
					<div className="col-3 p-4">
						<Tabs>
							<Tabs.Tab eventKey="userDetails" title="Details">
								<Does
									loggedInUser
									havePermission="user.view"
									onCompanyResource
									yes={() => { return <UserDetails user={selectedUser} loadUsersData={loadUsersData} />; }}
								/>
							</Tabs.Tab>
						</Tabs>
					</div>
				) : (
					<div className="col-3">
						<div className="w-100 h-100 no-user-details" />
					</div>
				)}
			</div>
		</div>
	);
};
