import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Form, Modal, Button, Row, Col, Spinner, Badge } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { rasFields } from '../../fields';
import { rasSettingsValidationSchema } from './validation';
import { RasContext } from '../../../../contexts/RasContext';
import { MachineContext } from '../../../../contexts/MachineContext';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { ServiceContext } from '../../../../contexts/ServiceContext';
import { InputField, Select, ComboxBoxField } from '../../../../components/Form';
import { UndeployRASConfirmationModal } from './UndeployRASConfirmationModal';
import { RasDestinationsModal } from '../RasTabs/Destinations/RasDestinationsModal';
import { Does } from '../../../../components/Access/Does';
import { buildComboBoxOptions, buildSelectOptions } from '../../../../helpers/select';
import { deleteItemFromLocalStorage } from '../../../../helpers/localStorage';
import InfoIcon from '../../../../components/Form/InfoIcon';

export const RasSettingsModal = (props) => {
	const { show, onHide } = props;

	const { rasService, machineService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { machinesWithOsVersionUnder460, machinesWithOsVersionAtLeast470 } = useContext(MachineContext);
	const {
		machinesRasInfo,
		selectedLocationRasSettingsModal,
		locationsWithRas,
		rasDestinations,
		rasDestinationsEligibleForSplitTunneling,
		getRasTableData,
		getRasDestinations,
		updateLoadingTable,
	} = useContext(RasContext);

	const { register, unregister, handleSubmit, setValue, clearErrors, reset, formState: { errors } } = useForm({
		resolver: yupResolver(yup.object().shape(rasSettingsValidationSchema))
	});

	const internalNetworkTypes = [
		{ label: 'DHCP', value: 'auto' },
		{ label: 'Manual', value: 'manual' },
	];

	const selectedLocation = selectedLocationRasSettingsModal;

	const locationHasGatewaySet = selectedLocation && selectedLocation.gatewayMachineSerialNumber !== '';
	const [selectedGatewayForm, setSelectedGatewayForm] = useState(selectedLocation.gatewayMachineSerialNumber);
	const [rasInfo, setRasInfo] = useState(null);
	const [rasConfigurationForm, setRasConfigurationForm] = useState(null);
	const [internalNetworkConfigurationMethod, setInternalNetworkConfigurationMethod] = useState('none');
	const [deploymentMethod, setDeploymentMethod] = useState(
		internalNetworkConfigurationMethod === 'none' ? 'behindNAT' : 'DMZ'
	);
	const [routeVpnTunnel, setRouteVpnTunnel] = useState('allTraffic');
	const [selectedDestinations, setSelectedDestinations] = useState([]);
	const [showDestinationsModal, setShowDestinationsModal] = useState(false);
	const [showUndeployRASConfirmationModal, setShowUndeployRASConfirmationModal] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		// register route and selectDestinations only for machines with OS >= 4.7.0
		if (show && machinesWithOsVersionAtLeast470.includes(selectedGatewayForm)) {
			getRasDestinations();
		}
	}, [show, selectedGatewayForm]);

	useEffect(() => {
		// register route and selectDestinations only for machines with OS >= 4.7.0
		if (show && machinesWithOsVersionAtLeast470.includes(selectedGatewayForm)) {
			register(rasFields.route);
			setValue(rasFields.route, routeVpnTunnel);
			register(rasFields.selectDestinations);

			if (routeVpnTunnel === 'allTraffic') {
				setValue(rasFields.selectDestinations, []);
			} else {
				setValue(
					rasFields.selectDestinations,
					selectedDestinations.map((option) => { return option.value; })
				);
			}
		} else {
			unregister(rasFields.route);
			unregister(rasFields.selectDestinations);
		}

	}, [show, selectedGatewayForm, routeVpnTunnel]);

	useEffect(() => {
		register(rasFields.externalNetworkConfigurationMethod);
		setValue(rasFields.externalNetworkConfigurationMethod, 'manual');

		register(rasFields.internalNetworkConfigurationMethod);
		setValue(rasFields.internalNetworkConfigurationMethod, internalNetworkConfigurationMethod);

		loadRASSettingsData();

		if (locationHasGatewaySet) {
			register(rasFields.gatewayMachineSerialNumber);
			setValue(rasFields.gatewayMachineSerialNumber, selectedLocation.gatewayMachineSerialNumber);
		}

		return () => {
			unregister(rasFields.externalNetworkConfigurationMethod);
			unregister(rasFields.internalNetworkConfigurationMethod);

			if (locationHasGatewaySet) {
				unregister(rasFields.gatewayMachineSerialNumber);
			}
		};
	}, []);

	useEffect(() => {
		setValue(rasFields.internalNetworkConfigurationMethod, internalNetworkConfigurationMethod);
	}, [internalNetworkConfigurationMethod]);

	useEffect(() => {
		if (rasConfigurationForm) {
			setInternalNetworkConfigurationMethod(rasConfigurationForm.internalNetworkConfigurationMethod);
			if (rasConfigurationForm?.routes?.length > 0) {
				setRouteVpnTunnel('specificDestinations');
				const routes = _.compact(rasConfigurationForm.routes).map((route) => { return route.name; });
				const selectedRasDestinations = rasDestinationsEligibleForSplitTunneling.filter((destination) => { return routes.includes(destination.name); }
				);
				handleChangeDestinations(buildComboBoxOptions(selectedRasDestinations, 'name', 'name'));
			}
		}
	}, [rasConfigurationForm, rasDestinationsEligibleForSplitTunneling]);

	const loadRASSettingsData = () => {
		try {
			if (locationHasGatewaySet) {
				const rasInfo = machinesRasInfo && machinesRasInfo.fulfilled[selectedLocation.gatewayMachineSerialNumber];
				setRasInfo(rasInfo);

				if (rasInfo && rasInfo.status === 'ACTIVE') {
					const selectedLocationConfig =
						locationsWithRas?.length &&
						locationsWithRas.find((l) => { return l.gatewayMachineSerialNumber === selectedLocation.gatewayMachineSerialNumber; });

					setRasConfigurationForm(selectedLocationConfig?.rasConfig);
					reset();
				}
			}
		} catch (error) {
			pushDangerNotification('Failed to fetch RAS settings data.');
		}
	};

	const onSubmit = async (values) => {
		try {
			setSubmitting(true);

			if (values.route) {
				values = {
					...values,
					networkDestinations: rasService.prepareDestinationsForHyper(rasDestinations),
					routes: rasService.prepareDestinationsForHyper(
						rasDestinationsEligibleForSplitTunneling.filter((destination) => { return values.selectDestinations.includes(destination.name); }
						)
					),
				};
			}

			if (rasConfigurationForm) {
				await rasService.setRasConfiguration(values, rasConfigurationForm);
			} else {
				await rasService.deployRas(values);
				await machineService.addRasGateway(selectedLocation.id, values.gatewayMachineSerialNumber);

				deleteItemFromLocalStorage(values.gatewayMachineSerialNumber);
			}

			updateLoadingTable(true);
			getRasTableData();

			onHide();
			pushSuccessfulNotification('RAS settings updated successfully.');
		} catch (error) {
			pushDangerNotification(error.message.replace(/GraphQL error:/g, '').trim());
			setSubmitting(false);
		}
	};

	const handleChangeDestinations = (selectedOptions) => {
		selectedOptions = selectedOptions || [];
		setValue(
			'selectDestinations',
			selectedOptions.map((option) => { return option.value; }),
			true
		);
		setSelectedDestinations(selectedOptions);
	};

	const renderRASSettingsForm = () => {
		return (
			<form>
				<div className="d-flex justify-content-between text-muted mb-3">
					RAS Gateway Device*
					<InfoIcon 
						placement="top" 
						tooltipStyle="pt-1 ms-2" 
						text="Choose one of the devices registered for this location. Minimum OS version required: 4.6.0"
						iconType="question"
					/>
				</div>

				<Select
					{...register(rasFields.gatewayMachineSerialNumber)}
					emptyOption="Please select"
					options={buildSelectOptions(
						selectedLocation.machines,
						'serialNumber',
						'serialNumber',
						machinesWithOsVersionUnder460
					)}
					defaultValue={selectedLocation.gatewayMachineSerialNumber}
					onChange={(e) => {
						setSelectedGatewayForm(e.target.value);
						setValue(rasFields.gatewayMachineSerialNumber, e.target.value);
					}}
					onInput={(event) => {
						if (event.target.value) {
							clearErrors(rasFields.gatewayMachineSerialNumber);
						}
					}}
					errors={errors}
					disabled={locationHasGatewaySet}
				/>

				<div className="text-muted mb-3">Hostname*</div>
				<InputField
					{...register(rasFields.fqdn)}
					placeholder="eg. syneto-ras.syneto.eu"
					defaultValue={rasConfigurationForm ? rasConfigurationForm.fqdn : null}
					errors={errors}
				/>

				<div className="h6 font-weight-bold mt-5 mb-3">RAS Gateway VM Network Interfaces</div>

				<div className="d-flex justify-content-between text-muted mb-3">
					Deployment method*
					<InfoIcon 
						placement="top" 
						tooltipStyle="pt-1 ms-2" 
						text="Two deployment scenarios are supported. Use DMZ when the RAS gateway has a publicly routed IP and two
						network interfaces (public and private). Use Behind NAT for installations where the RAS gateway is behind a
						NAT and has only a private network interface."
						iconType="question"
					/>
				</div>

				<div className="d-flex mb-3">
					<Form.Check
						inline
						name={rasFields.deploymentMethod}
						label="Behind NAT"
						type="radio"
						id="behindNAT"
						checked={deploymentMethod === 'behindNAT'}
						onChange={() => {
							setDeploymentMethod('behindNAT');
							setInternalNetworkConfigurationMethod('none');
						}}
						style={{ minHeight: 'auto' }}
					/>
					<Form.Check
						inline
						name={rasFields.deploymentMethod}
						label="DMZ"
						type="radio"
						id="DMZ"
						checked={deploymentMethod === 'DMZ'}
						onChange={() => {
							setDeploymentMethod('DMZ');
							setInternalNetworkConfigurationMethod('manual');
						}}
						style={{ minHeight: 'auto' }}
					/>
				</div>

				<div className="d-flex justify-content-between text-muted mb-3">
					{deploymentMethod === 'behindNAT' ? (
						<>
							Connect to Virtual Network*
							<InfoIcon 
								placement="top" 
								tooltipStyle="pt-1 ms-2" 
								text="The name of the ESXi private virtual network the RAS gateway network interface is attached to."
								iconType="question"
							/>
						</>
					) : (
						<>
							Connect to External Virtual Network*
							<InfoIcon 
								placement="top" 
								tooltipStyle="pt-1 ms-2" 
								text="The name of the ESXi virtual network the RAS gateway public network interface is attached to."
								iconType="question"
							/>
						</>
					)}
				</div>

				<InputField
					{...register(rasFields.externalNetwork)}
					placeholder="eg. VM Network"
					defaultValue={rasConfigurationForm ? rasConfigurationForm.externalNetwork : null}
					errors={errors}
				/>

				<div className="text-muted mb-3">IP / Netmask*</div>
				<InputField
					{...register(rasFields.externalIP)}
					placeholder="eg. 192.168.1.252/24"
					defaultValue={rasConfigurationForm ? rasConfigurationForm.externalIP : null}
					errors={errors}
				/>

				<Row>
					<Col>
						<div className="text-muted mb-3">DNS*</div>
						<InputField
							{...register(rasFields.dns)}
							placeholder="eg. 8.8.8.8, 8.8.4.4"
							defaultValue={rasConfigurationForm ? rasConfigurationForm.dns : null}
							errors={errors}
						/>
					</Col>
					<Col>
						<div className="text-muted mb-3">Gateway*</div>
						<InputField
							{...register(rasFields.gatewayIP)}
							placeholder="eg. 10.12.0.1"
							defaultValue={rasConfigurationForm ? rasConfigurationForm.gatewayIP : null}
							errors={errors}
						/>
					</Col>
				</Row>

				{deploymentMethod === 'DMZ' && (
					<>
						<div className="d-flex justify-content-between text-muted mb-3">
							Connect to Internal Virtual Network*
							<InfoIcon 
								placement="top" 
								tooltipStyle="pt-1 ms-2" 
								text="The name of the ESXi virtual network the RAS gateway private network interface is attached to."
								iconType="question"
							/>
						</div>

						<InputField
							{...register(rasFields.internalNetwork)}
							placeholder="eg. VM Network"
							defaultValue={rasConfigurationForm ? rasConfigurationForm.internalNetwork : null}
							errors={errors}
						/>

						<div className="d-flex mb-3">
							{internalNetworkTypes.map((networkType) => {
								return (
									<Form.Check
										key={`internalSettingsType-${networkType.value}`}
										type="radio"
										id={`internalSettingsType-${networkType.value}`}
										name={rasFields.internalSettingsType}
										label={networkType.label}
										checked={internalNetworkConfigurationMethod === networkType.value}
										onChange={() => {
											setInternalNetworkConfigurationMethod(networkType.value);
											// const fields = ["internalNetwork", "internalIP"]
											const fields = ['internalIP'];
											fields.forEach((field) => {
												setValue(field, '');
												clearErrors(field);
											});
										}}
										inline
									/>
								);
							})}
						</div>

						<div className="text-muted mb-3">IP / Netmask*</div>
						<InputField
							{...register(rasFields.internalIP)}
							placeholder="eg. 192.168.1.252/24"
							readOnly={internalNetworkConfigurationMethod === 'auto' || internalNetworkConfigurationMethod === 'none'}
							defaultValue={rasConfigurationForm ? rasConfigurationForm.internalIP : null}
							errors={errors}
						/>
					</>
				)}

				<div className="h6 font-weight-bold mb-3">Access settings</div>

				<div className="text-muted mb-3">Public Endpoint*</div>
				<InputField
					{...register(rasFields.publicIpOrFqdn)}
					placeholder="eg. vpn.syneto.eu:1234 or 12.13.14.15:1617"
					defaultValue={rasConfigurationForm ? rasConfigurationForm.publicIpOrFqdn : null}
					errors={errors}
				/>

				<div className="d-flex justify-content-between text-muted mb-3">
					Route via VPN tunnel
					<div>
						{selectedGatewayForm && !machinesWithOsVersionAtLeast470.includes(selectedGatewayForm) ? (
							<>
								<Badge appearance="warning">Not supported</Badge>
								<InfoIcon 
									placement="top" 
									tooltipStyle="pt-1 ms-2" 
									text="The selected RAS Gateway Device is at an older Syneto OS version which does not support granular VPN routing. Please update Syneto OS to the latest version to enable this functionality."
									iconType="question"
								/>
							</>
						) : null}
					</div>
				</div>

				<div className="d-flex mb-3">
					<Form.Check
						inline
						name={rasFields.route}
						label="All traffic"
						type="radio"
						id="allTraffic"
						checked={routeVpnTunnel === 'allTraffic'}
						onChange={() => {
							setRouteVpnTunnel('allTraffic');
							setValue('route', routeVpnTunnel);
						}}
						disabled={!machinesWithOsVersionAtLeast470.includes(selectedGatewayForm)}
						style={{ minHeight: 'auto' }}
					/>
					<Form.Check
						inline
						name={rasFields.route}
						label="Specific Destinations"
						type="radio"
						id="specificDestinations"
						checked={routeVpnTunnel === 'specificDestinations'}
						onChange={() => {
							setRouteVpnTunnel('specificDestinations');
							setValue('route', routeVpnTunnel);
						}}
						disabled={!machinesWithOsVersionAtLeast470.includes(selectedGatewayForm)}
						style={{ minHeight: 'auto' }}
					/>
				</div>

				{routeVpnTunnel === 'specificDestinations' && (
					<>
						<div className="d-flex justify-content-between">
							<p className="font-weight-bold">Select destinations</p>

							<Button role="link" onClick={() => { return setShowDestinationsModal(true); }}>
								Add new
							</Button>
						</div>

						<ComboxBoxField
							placeholder="Select destinations"
							name={rasFields.selectDestinations}
							options={buildComboBoxOptions(rasDestinationsEligibleForSplitTunneling, 'name', 'name')}
							value={selectedDestinations}
							onChange={handleChangeDestinations}
							isMulti
							errors={errors}
						/>

						{showDestinationsModal && (
							<RasDestinationsModal
								show={showDestinationsModal}
								onHide={() => { return setShowDestinationsModal(false); }}
								destinationsCurrentlyInUseInForm={selectedDestinations}
							/>
						)}
					</>
				)}
			</form>
		);
	};

	const renderUndeployButton = () => {
		return (
			<>
				<Button width={96} role="link" appearance="danger" onClick={() => { return setShowUndeployRASConfirmationModal(true); }}>
					Remove
				</Button>
				{showUndeployRASConfirmationModal && (
					<UndeployRASConfirmationModal
						show={showUndeployRASConfirmationModal}
						onHide={() => { return setShowUndeployRASConfirmationModal(false); }}
						onHideRASSettingsModal={onHide}
						undeployMessage="Are you sure you want to remove the Remote Access Service on this location? This action
            will destroy the RAS appliance and all VPN users will loose their access."
						location={selectedLocation}
					/>
				)}
			</>
		);
	};

	const renderContentBasedOnRasInfo = () => {
		if (!rasInfo || rasInfo.status === 'ACTIVE') {
			return renderRASSettingsForm();
		}

		if (rasInfo.status === 'INACTIVE') {
			return (
				<p>
					RAS is inactive even though it was enabled on this location. This could be due to the RAS appliance being
					powered off or not reachable. If you need help diagnosing this issue, please contact{' '}
					<a href="https://helpdesk.syneto.eu/" target="_blank" rel="noopener noreferrer">
						Syneto Customer Support
					</a>
					.
				</p>
			);
		}

		if (rasInfo.status === 'DEPLOYING') {
			return <p>Please wait. The RAS appliance is currently deploying.</p>;
		}

		if (rasInfo.status === 'FAILED') {
			return (
				<p>
					The deployment of your RAS appliance appears to have failed. Please undeploy Remote Access, click Save and
					come back here to try again.
				</p>
			);
		}
	};

	return (
		<Modal show={show} onHide={onHide} slidingPanel={true}>
			<Modal.Header>
				<Modal.Title>Remote Access Server Settings</Modal.Title>
			</Modal.Header>

			<Modal.Body>{renderContentBasedOnRasInfo()}</Modal.Body>

			<Modal.Footer>
				<div className="d-flex w-100 justify-content-between">
					<div>
						{rasConfigurationForm || (rasInfo && rasInfo.status === 'FAILED') ? (
							<Does
								loggedInUser
								havePermission="ras.unDeploy"
								onCompanyResource
								yes={renderUndeployButton}
								no={() => {
									return (
										<Does
											loggedInUser
											havePermission="ras.unDeploy"
											onResource={{
												type: 'location',
												referenceId: selectedLocation?.id,
											}}
											yes={renderUndeployButton}
										/>
									);
								}}
							/>
						) : null}
					</div>

					<div>
						<Button width={96} role="secondary" className="me-3" onClick={onHide}>
							Cancel
						</Button>

						<Button
							width={96}
							onClick={handleSubmit(onSubmit)}
							disabled={
								submitting ||
								(rasInfo &&
									(rasInfo.status === 'DEPLOYING' || rasInfo.status === 'INACTIVE' || rasInfo.status === 'FAILED'))
							}
						>
							{submitting ? (
								<span>
									<Spinner animation="border" variant="primary" size="sm" className="text-muted" />
									&nbsp; Saving...
								</span>
							) : (
								<span>Save</span>
							)}
						</Button>
					</div>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

RasSettingsModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
};
