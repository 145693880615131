import React, { useContext } from 'react';
import { Tooltip } from '@syneto/compass-react';
import moment from 'moment';
import { RasContext } from '../../../../contexts/RasContext';
import { StoreContext } from '../../../../contexts/StoreContext';
import { RasDeploymentDropdown } from '../RasDeploymentDropdown';
import '../../style.scss';

export const NoRasDeployments = (props) => {
	const { onLocationSelect } = props;

	const { locationsWithoutRas } = useContext(RasContext);

	const { state } = useContext(StoreContext);
	const { services } = state;

	const onChange = (eventKey) => {
		const selectedLocation = locationsWithoutRas.find((location) => { return location.name === eventKey; });
		onLocationSelect(selectedLocation);
	};

	return (
		<div>
			<div className="no-ras-deployments my-5" />
			<h1 className="text-primary text-center mb-3">No RAS Deployments</h1>
			<h6 className="text-muted text-center">The Remote Access Service is a managed SSL VPN service with granular</h6>
			<h6 className="text-muted text-center">
				access control per user and location. To deploy it, for each location mark one
			</h6>
			<h6 className="text-muted text-center">appliance as the RAS gateway. Minimum SynetoOS version: 4.6.0</h6>

			{services?.ras?.active ? (
				<div className="d-flex justify-content-center mt-5">
					<RasDeploymentDropdown label="New Deployment" onSelect={(eventKey) => { return onChange(eventKey); }} />
				</div>
			) : (
				<Tooltip
					placement="top"
					text={`The RAS service for your account has expired ${services?.ras?.settings.endingPeriod
						? `on ${moment(services?.ras?.settings.endingPeriod).format('DD/MM/YYYY')}`
						: ''
					}. To continue using the service, please renew your service subscription.`}
				>
					<div className="d-flex justify-content-center mt-5">
						<RasDeploymentDropdown label="New Deployment" onSelect={(eventKey) => { return onChange(eventKey); }} />
					</div>
				</Tooltip>
			)}
		</div>
	);
};
