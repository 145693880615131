import React, { forwardRef } from 'react';
import { Form } from '@syneto/compass-react';

export const Select = forwardRef((props, ref) => {
	const { label, id, name, emptyOption, required, disabled, input, errors, onChange, onInput, defaultValue } = props;
	let { options } = props;

	if (options && options.props && options.props.children) {
		// we need the line below for optgroups
		options = options.props.children;
	}

	return (
		<Form.Group className="mb-3" controlId={id}>
			{label ? (
				<Form.Label>
					{label}
					{required ? '*' : null}
				</Form.Label>
			) : null}

			<Form.Control
				as="select"
				name={name}
				ref={ref}
				disabled={disabled}
				isInvalid={errors && errors[name]}
				onChange={onChange}
				onInput={onInput}
				defaultValue={defaultValue}
				data-testid="select-option"
				{...input}
			>
				{emptyOption && <option value={''}>{emptyOption}</option>}
				{options}
			</Form.Control>

			<Form.Control.Feedback type="invalid">{errors && errors[name] && errors[name].message}</Form.Control.Feedback>
		</Form.Group>
	);
});
