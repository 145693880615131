import React, { forwardRef } from 'react';
import { Form } from '@syneto/compass-react';

export const InputField = forwardRef((props, ref) => {
	const {
		input,
		label,
		icon,
		id,
		required,
		disabled,
		type,
		name,
		placeholder,
		style,
		className,
		maxLength,
		pattern,
		onChange,
		onFocus,
		onBlur,
		onInput,
		onPaste,
		onKeyUp,
		onKeyPress,
		defaultValue,
		errors,
		readOnly,
	} = props;

	return (
		<Form.Group className="mb-3" controlId={id}>
			{label ? (
				<Form.Label>
					{label}
					{required ? '*' : null} {icon ? icon : null}
				</Form.Label>
			) : null}

			<Form.Control
				type={type}
				name={name}
				placeholder={placeholder}
				ref={ref}
				style={style}
				className={className}
				maxLength={maxLength}
				pattern={pattern}
				disabled={disabled}
				readOnly={readOnly}
				isInvalid={errors && errors[name]}
				onChange={onChange}
				onFocus={onFocus}
				onBlur={onBlur}
				onInput={onInput}
				onPaste={onPaste}
				onKeyUp={onKeyUp}
				onKeyPress={onKeyPress}
				defaultValue={defaultValue}
				{...input}
			/>

			<Form.Control.Feedback type="invalid">{errors && errors[name] && errors[name].message}</Form.Control.Feedback>
		</Form.Group>
	);
});

InputField.defaultProps = {
	type: 'text',
};
