import * as yup from 'yup';
import { registrationFields } from './fields';
import { rEmail } from '../../constants/validation';

export const registrationValidationSchema = {
	[registrationFields.email]: yup.string().required('Cannot be empty').trim().matches( rEmail , 'Email format is incorrect'),
	[registrationFields.password]: 
		yup
			.string()
			.required('Cannot be empty')
			.min(6, 'The password has to have minimum 6 characters'),
	[registrationFields.checkPassword]: 
		yup
			.string()
			.required('Cannot be empty')
			.test('passwords-match', 'Passwords do not match', function (value) {
				return this.parent.password === value;
			}),
	[registrationFields.firstName]: yup.string().required('Cannot be empty'),
	[registrationFields.lastName]: yup.string().required('Cannot be empty'),
	[registrationFields.role]: yup.string().required('Cannot be empty'),
	[registrationFields.phoneNumber]: 
		yup
			.string()
			.required('Cannot be empty')
			.matches(/^\+{0,1}\d*$/, 'Phone number is invalid'),
	[registrationFields.companyName]: yup.string().required('Cannot be empty'),
	[registrationFields.companyVat]: yup.string().required('Cannot be empty'),
	[registrationFields.companyMarketSector]: yup.string().required('Cannot be empty'),
	[registrationFields.companyNumberEmployees]: yup.string().required('Cannot be empty'),
	[registrationFields.companyCountry]: yup.string().required('Cannot be empty'),
	[registrationFields.companyState]: yup.string().required('Cannot be empty'),
	[registrationFields.companyCity]: yup.string().required('Cannot be empty'),
	[registrationFields.companyAddress]: yup.string().required('Cannot be empty'),
	[registrationFields.companyPostalCode]: yup.string().required('Cannot be empty'),
	[registrationFields.companyEmail]: yup.string().required('Cannot be empty').trim().matches( rEmail , 'Email format is incorrect'),
	[registrationFields.companyWebsite]: yup.string().required('Cannot be empty'),
	[registrationFields.subscribeNews]: yup.bool(),
	[registrationFields.subscribeProduct]: yup.bool(),
	[registrationFields.subscribeSatisfactionSurveys]: yup.bool(),
	[registrationFields.dataCollectionConsent]: 
		yup
			.bool()
			.required('You must agree with data being collected and stored')
			.oneOf([true], 'You must agree with data being collected and stored')
};
