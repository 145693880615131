import { gql } from 'apollo-boost';
import { parseOSVersion } from '../helpers/OSVersionParser';
import { CURRENT_SYNETO_OS_VERSIONS } from '../config/constants';

export class MachineService {
	constructor(graphqlClient, backEndAxios) {
		this.backEndAxios = backEndAxios;
		this.graphqlClient = graphqlClient;
	}

	async getMachinesByCompanyId(companyId) {
		const machinesByCompanyIdResponse = await this.graphqlClient.query({
			query: gql`
        query machinesByCompanyId($companyId: String!) {
          machinesByCompanyId(companyId: $companyId) {
            id
            createdAt
            updatedAt
            name
            serialNumber
            activationDate
            osVersion
            esxiVersion
            serenityEnabled
            monitoringEnabled
            hasChronos
            locationId
            isRasGateway
            productModel {
              title
            }
            maintenanceServices {
              startDate
              duration
              endDate
              hasSerenity
              maintenancePlan {
                customerFacingName
                endUserDescription
                serenity
              }
              maintenanceServiceRenewals {
                startDate
                duration
                endDate
                hasSerenity
                maintenancePlan {
                  customerFacingName
                  endUserDescription
                  serenity
                }
              }
              expiresOn
            }
            userId
          }
        }
      `,
			variables: {
				companyId: companyId,
			},
			fetchPolicy: 'no-cache',
		});

		return machinesByCompanyIdResponse.data.machinesByCompanyId;
	}

	async getMachinesByLocationIds(locationIds) {
		const machinesByLocationIdsResponse = await this.graphqlClient.query({
			query: gql`
        query ($locationIds: [String]!) {
          machinesByLocationIds(locationIds: $locationIds) {
            id
            createdAt
            updatedAt
            name
            serialNumber
            activationDate
            osVersion
            esxiVersion
            serenityEnabled
            monitoringEnabled
            hasChronos
            locationId
            isRasGateway
            productModel {
              title
            }
            maintenanceServices {
              startDate
              duration
              endDate
              hasSerenity
              maintenancePlan {
                customerFacingName
                endUserDescription
                serenity
              }
              maintenanceServiceRenewals {
                startDate
                duration
                endDate
                hasSerenity
                maintenancePlan {
                  customerFacingName
                  endUserDescription
                  serenity
                }
              }
              expiresOn
            }
            userId
          }
        }
      `,
			variables: {
				locationIds: locationIds,
			},
			fetchPolicy: 'no-cache',
		});

		return machinesByLocationIdsResponse.data.machinesByLocationIds;
	}

	async addLocationToMachines(locationId, machineSerialNumbers) {
		const addLocationToMachinesResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($locationId: String!, $machineSerialNumbers: [String]!) {
          addLocationToMachines(locationId: $locationId, machineSerialNumbers: $machineSerialNumbers)
        }
      `,
			variables: {
				locationId: locationId,
				machineSerialNumbers: machineSerialNumbers,
			},
			fetchPolicy: 'no-cache',
		});

		return addLocationToMachinesResponse.data.addLocationToMachines;
	}

	async addRasGateway(locationId, machineSerialNumber) {
		const addRasGatewayResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation addRasGateway($locationId: String!, $machineSerialNumber: String!) {
          addRasGateway(locationId: $locationId, machineSerialNumber: $machineSerialNumber)
        }
      `,
			variables: {
				locationId: locationId,
				machineSerialNumber: machineSerialNumber,
			},
			fetchPolicy: 'no-cache',
		});

		return addRasGatewayResponse.data.addRasGateway;
	}

	async removeRasGateway(locationId) {
		const removeRasGatewayResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($locationId: String!) {
          removeRasGateway(locationId: $locationId)
        }
      `,
			variables: {
				locationId: locationId,
			},
			fetchPolicy: 'no-cache',
		});

		return removeRasGatewayResponse.data.removeRasGateway;
	}

	async validateMachineActivationKey(activationKey) {
		const validateMachineActivationKeyResponse = await this.graphqlClient.query({
			query: gql`
        query ($activationKey: String!) {
          validateMachineActivationKey(activationKey: $activationKey)
        }
      `,
			variables: {
				activationKey: activationKey,
			},
			fetchPolicy: 'no-cache',
		});

		return validateMachineActivationKeyResponse.data.validateMachineActivationKey;
	}

	async sendFreshDeskTicketEmail(inputData) {
		const sendFreshDeskTicketEmailResponse = await this.graphqlClient.query({
			query: gql`
        query ($ticket: FreshDeskTicketInput!) {
          sendFreshDeskTicketEmail(ticket: $ticket)
        }
      `,
			variables: {
				ticket: {
					name: inputData.name,
					email: inputData.email,
					company: inputData.company,
					subject: inputData.subject,
					comment: inputData.comment,
					serialNumber: inputData.serialNumber,
					osVersion: inputData.osVersion,
					esxiVersion: inputData.esxiVersion,
				},
			},
			fetchPolicy: 'no-cache',
		});

		return sendFreshDeskTicketEmailResponse.data.sendFreshDeskTicketEmail;
	}

	async sendRASAdditionalSeatsRequestEmail(machineSerialNumber, userId) {
		const sendRASAdditionalSeatsRequestEmailResponse = await this.graphqlClient.query({
			query: gql`
        query ($machineSerialNumber: String, $userId: String!) {
          sendRASAdditionalSeatsRequestEmail(machineSerialNumber: $machineSerialNumber, userId: $userId)
        }
      `,
			variables: {
				machineSerialNumber: machineSerialNumber,
				userId: userId,
			},
			fetchPolicy: 'no-cache',
		});

		return sendRASAdditionalSeatsRequestEmailResponse.data.sendSerenityRequestEmail;
	}

	machinesWithOsVersionSmallerThan = (machines, osVersion) => {
		const machinesArray = [];

		if (machines?.length) {
			machines.forEach((machine) => {
				if (machine.osVersion && machine.osVersion < osVersion) {
					machinesArray.push(machine.serialNumber);
				}
			});
		}

		return machinesArray;
	};

	machinesWithOsVersionGreaterOrEqualThan = (machines, osVersion) => {
		const machinesArray = [];

		if (machines?.length) {
			machines.forEach((machine) => {
				if (machine.osVersion && machine.osVersion >= osVersion) {
					machinesArray.push(machine.serialNumber);
				}
			});
		}

		return machinesArray;
	};

	attachDependenciesToMachines = (locations, users, machines) => {
		machines.forEach((machine) => {
			const location = locations.find((location) => { return machine.locationId === location.id; });
			const user = users.find((user) => { return user.id === machine.userId; });

			machine.location = location ? location.name : '';
			machine.user = user ? user : null;
		});
	};

	getGatewayMachineOsVersion = (location) => {
		const gatewayMachine = location?.machines.find(
			(machine) => { return machine.serialNumber === location.gatewayMachineSerialNumber; }
		);
		return parseOSVersion(gatewayMachine?.osVersion);
	};

	getOsLatestVersions = () => {
		return this.backEndAxios.get('/central-backend/syneto-os-update/get-latest-versions')
			.then((response) => {
				return response?.data?.versions ?? CURRENT_SYNETO_OS_VERSIONS;
			})
			.catch(() => {
				throw error?.response?.data?.detail ?? 'Failed to fetch latest OS versions...';
			});
	};
}
