import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dropdown, Nav, Navbar, Icon, Alert } from '@syneto/compass-react';
import Image from '../../assets/img/syneto.svg';
import './style.scss';
import { StoreContext } from '../../contexts/StoreContext';
import { ServiceContext } from '../../contexts/ServiceContext';

const DROPDOWN_ACTIONS = {
	profile: 'Profile',
	logOut: 'Log Out'
};

export const TitleBar = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { authenticationService } = useContext(ServiceContext);
	const { dispatch, state: {
		loggedInUser, isAuthorized
	} } = useContext(StoreContext);

	const setTitle = (path) => {
		const title = {
			'/dashboard': 'Dashboard',
			'/inventory': 'Inventory',
			'/serenity-events': 'SerenITy Events',
			'/monitoring': 'Monitoring',
			'/users': 'Users',
			'/das': 'Desktop as a Service',
			'/ras': 'Remote Access Service',
			'/profile': 'Profile',
			'/virtual-appliance': 'Virtual Appliance',
			'/support': 'Support',
			'/welcome': 'Syneto Central HUB',
		};
		return title[path];
	};

	const onSelectAction = async (key) => {
		switch (key) {
			case DROPDOWN_ACTIONS.profile:
				navigate('/profile');
				break;
			case DROPDOWN_ACTIONS.logOut: {
				await authenticationService.logout();

				dispatch({
					payload: {
						isAuthorized: false,
						loggedInUser: null,
					},
				});

				navigate('/authentication');
				break;
			}
			default:
				break;
		}
	};

	return isAuthorized ? (
		<Navbar bg="white" expand="lg" className="syneto">
			<div className='container-fluid'>
				<Navbar.Brand href="#home">{setTitle(location.pathname)}</Navbar.Brand>

				<Navbar.Toggle aria-controls="basic-navbar-nav" />

				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ms-auto syneto d-flex align-items-center gap-1" variant="pills" defaultActiveKey="#">
						{loggedInUser.impersonated && <Alert variant="danger">Impersonating</Alert>}
						<Dropdown
							label={
								<Nav.Item className='me-0'>
									<Nav.Link eventKey="dropdownButton" title="Item">
										<Icon name="fas fa-user" size="lg" />
										<span> {`${loggedInUser.profile.firstName} ${loggedInUser.profile.lastName}`} </span>
										<Icon name="fas fa-caret-down" size="md" />
									</Nav.Link>
								</Nav.Item>
							}
							onSelect={(eventKey) => { return onSelectAction(eventKey); }}
							items={[
								{
									label: 'Profile',
									eventKey: DROPDOWN_ACTIONS.profile
								},
								{
									label: 'Log out',
									eventKey: DROPDOWN_ACTIONS.logOut
								},
							]}
						/>
					</Nav>
				</Navbar.Collapse>
			</div>
		</Navbar>
	) : (
		<Navbar bg="white" expand="lg" className="syneto syneto-navbar">
			<Navbar.Brand href="/" className="syneto-brand bg-brand-highlight">
				<div className="brand text-white">
					<img src={Image} alt="logo" height={20} /> &nbsp;
					<span>| Central</span>
				</div>
			</Navbar.Brand>
		</Navbar>
	);
};

