import React, { useContext, useState } from 'react';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { Modal } from '@syneto/compass-react';
import PropTypes from 'prop-types';
import { ServiceContext } from '../../../contexts/ServiceContext';

export const DeleteUserModal = (props) => {
	const { show, onHide, onHideEditModal, user, loadUsersData } = props;
	const { userService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);

	const [submitting, setSubmitting] = useState(false);

	const onSubmit = async () => {
		try {
			setSubmitting(true);
			await userService.removeUser(user.id);
			await loadUsersData();
			onHide();
			onHideEditModal();
			pushSuccessfulNotification('User removed successfully.');
		} catch (error) {
			pushDangerNotification('Failed to remove user.');
			setSubmitting(false);
		}
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			content={`Are you sure you want to delete ${user.profile.firstName} ${user.profile.lastName} user?`}
			title="Confirm Delete"
			actions={{
				primary: {
					label: 'Delete',
					onClick: onSubmit,
					disabled: submitting,
				},
				secondary: {
					label: 'Cancel',
					onClick: onHide,
				},
			}}
		/>
	);
};

DeleteUserModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	onHideEditModal: PropTypes.func.isRequired,
	user: PropTypes.shape({
		id: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		policies: PropTypes.array,
		profile: PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
			phoneNumber: PropTypes.string.isRequired,
			roleInTheCompany: PropTypes.string.isRequired,
			hasVirtualApplianceAccess: PropTypes.bool,
			subscribeToNews: PropTypes.bool.isRequired,
			subscribeToProductNews: PropTypes.bool.isRequired,
			subscribeToSatisfactionSurveys: PropTypes.bool.isRequired,
			dataCollectionConsent: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	loadUsersData: PropTypes.func.isRequired,
};
