import { useContext } from 'react';
import { ServiceContext } from '../../contexts/ServiceContext';
import PropTypes from 'prop-types';

export const HasRoleOnCompanyResource = (props) => {
	const { children, name, user } = props;
	const { accessService } = useContext(ServiceContext);

	if (accessService.hasRoleOnCompanyResource(name, user)) {
		return children;
	}

	return null;
};

HasRoleOnCompanyResource.propTypes = {
	name: PropTypes.string.isRequired,
	user: PropTypes.object,
};
