import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, Button, Badge } from '@syneto/compass-react';
import { NotificationContext } from '../../contexts/NotificationContext';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { StoreContext } from '../../contexts/StoreContext';
import { profileFields, profileFieldsPerTabs } from './fields';
import { profileValidationSchema, profileWithoutCompanyValidationSchema } from './validation';
import { ProfileTab } from './components/ProfileTab';
import { CompanyTab } from './components/CompanyTab';
import { SecurityTab } from './components/SecurityTab';
import { ServiceContext } from '../../contexts/ServiceContext';


export const Profile = () => {
	const { userService, accessService, authenticationService } = useContext(ServiceContext);
	const { state, dispatch } = useContext(StoreContext);
	const navigate = useNavigate();
	const { loggedInUser } = state;
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const hasPermissionToUpdateCompanyProfile = accessService.hasPermissionOnCompanyResource('account.company.update');
	const schema = yup.object().shape(
		hasPermissionToUpdateCompanyProfile
			? profileValidationSchema
			: profileWithoutCompanyValidationSchema
	);
	const { register, unregister, handleSubmit, formState: { errors }, clearErrors, setValue } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			password: '',
			newPassword: '',
			retypeNewPassword: '',
		},
	});

	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		register(profileFields.email);
		setValue(profileFields.email, loggedInUser.email);

		register(profileFields.subscribeProduct);
		setValue(profileFields.subscribeProduct, loggedInUser.profile.subscribeToNews);

		register(profileFields.subscribeNews);
		setValue(profileFields.subscribeNews, loggedInUser.profile.subscribeToNews);

		register(profileFields.subscribeSatisfactionSurveys);
		setValue(profileFields.subscribeSatisfactionSurveys, loggedInUser.profile.subscribeToSatisfactionSurveys);

		register(profileFields.dataCollectionConsent);
		setValue(profileFields.dataCollectionConsent, loggedInUser.profile.dataCollectionConsent);

		return () => {
			unregister(profileFields.email);
			unregister(profileFields.subscribeProduct);
			unregister(profileFields.subscribeNews);
			unregister(profileFields.subscribeSatisfactionSurveys);
			unregister(profileFields.dataCollectionConsent);
		};
	}, []);

	const onSubmit = async (values) => {
		try {
			setSubmitting(true);
			await userService.updateUser({
				[profileFields.id]: loggedInUser.id,
				[profileFields.hasPermissionToUpdateCompanyProfile]: hasPermissionToUpdateCompanyProfile,
				...values,
				isSupportContact: loggedInUser.profile.isSupportContact === true,
			});
			pushSuccessfulNotification('Profile updated successfully.');
			await reloadLoggedInUser();
			clearSecuritySettingsFields();
			setSubmitting(false);
		} catch (error) {
			pushDangerNotification(error.message.replace(/GraphQL error:/g, '').trim());
			setSubmitting(false);
		}
	};

	const reloadLoggedInUser = async () => {
		dispatch({
			payload: {
				loggedInUser: await userService.getLoggedInUser()
			},
		});
	};

	const logoutIfPasswordChanged = async () => {
		if (!loggedInUser?.mfaTokenUsed) {
			try {
				await authenticationService.logout();

				dispatch({
					payload: {
						isAuthorized: false,
						loggedInUser: null,
					},
				});

				navigate('/authentication');
			} catch (error) {
				return error;
			}
		}
	};

	const clearSecuritySettingsFields = () => {
		setValue(profileFields.password, '');
		setValue(profileFields.newPassword, '');
		setValue(profileFields.retypeNewPassword, '');
	};

	const getNumberOfErrorsPerTab = (tab) => {
		let numberOfErrors = 0;

		profileFieldsPerTabs[tab].forEach((field) => {
			if (errors[field]) {
				numberOfErrors++;
			}
		});

		return numberOfErrors;
	};

	const getTabTitle = (title) => {
		const numberOfErrorsPerTab = getNumberOfErrorsPerTab(title);

		return numberOfErrorsPerTab > 0 ? (
			<>
				{title}
				{numberOfErrorsPerTab > 0 && (
					<Badge appearance="danger" role="strong" width={64} className="ms-2">
						{`${numberOfErrorsPerTab} ${numberOfErrorsPerTab > 1 ? 'errors' : 'error'}`}
					</Badge>
				)}
			</>
		) : title;
	};

	logoutIfPasswordChanged();

	return (
		<div className="min-vh-80 shadow p-4 mb-4 bg-white rounded">
			<form>
				<Tabs defaultActiveKey="profile">
					<Tabs.Tab eventKey="profile" title={getTabTitle('Profile')}>
						<ProfileTab
							register={register}
							errors={errors}
							setValue={setValue}
							clearErrors={clearErrors}
							loggedInUser={loggedInUser}
						/>
					</Tabs.Tab>
					{/*There's a reason behind the shitty solution below. Compass Tabs implementation will interepret everything you might put here as child elements, so if you pass anything else than a Tab child element (like null for example), you'll get loads of errors */}
					<Tabs.Tab
						eventKey="companyProfile"
						title={getTabTitle('Company Profile')}
						style={hasPermissionToUpdateCompanyProfile ? {} : { display: 'none' }}
					>
						{hasPermissionToUpdateCompanyProfile && (
							<CompanyTab
								register={register}
								errors={errors}
								clearErrors={clearErrors}
								setValue={setValue}
								loggedInUser={loggedInUser}
							/>
						)}
					</Tabs.Tab>
					<Tabs.Tab eventKey="securitySettings" title={getTabTitle('Security Settings')}>
						<SecurityTab register={register} errors={errors} />
					</Tabs.Tab>
				</Tabs>
				<div className="mt-5 d-flex flex-row-reverse">
					<Button width={128} role="primary" onClick={handleSubmit(onSubmit)} disabled={submitting}>
						Update Information
					</Button>
				</div>
			</form>
		</div>
	);
};
