import React, { useContext, useState, useEffect } from 'react';
import { Badge, Button, Col, Modal, Icon, Row, Tooltip } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { rasFields } from '../../../fields';
import { destinationsValidationSchema } from './validation';
import { RasContext } from '../../../../../contexts/RasContext';
import { NotificationContext } from '../../../../../contexts/NotificationContext';
import { InputField } from '../../../../../components/Form';
import { findDuplicates } from '../../../../../helpers/array';
import { restrictInputDestinationsName } from '../../../../../helpers/input';
import InfoIcon from '../../../../../components/Form/InfoIcon';

export const RasDestinationsModal = (props) => {
	const { show, onHide, destinationsCurrentlyInUseInForm } = props;

	const { pushDangerNotification } = useContext(NotificationContext);
	const { rasDestinations, getRasDestinations, updateRasDestinations } = useContext(RasContext);

	const [rasDestinationsLocal, setRasDestinationsLocal] = useState([]);

	useEffect(() => {
		getRasDestinations();
	}, []);

	useEffect(() => {
		setRasDestinationsLocal(
			rasDestinations.map((destination) => {
				return {
					name: destination.name,
					networkDestinations: destination.networkDestinations,
					currentlyInUse:
						destination.currentlyInUse ||
						(destinationsCurrentlyInUseInForm &&
							destinationsCurrentlyInUseInForm.find(
								(destinationCurrentlyInUseInForm) => { return destinationCurrentlyInUseInForm.label === destination.name; }
							) !== undefined),
				};
			})
		);
	}, [rasDestinations, destinationsCurrentlyInUseInForm]);

	const { register, handleSubmit, reset, formState: { errors } } = useForm({
		resolver: yupResolver(yup.object().shape(destinationsValidationSchema)),
	});

	const addNetworkDestination = (values) => {
		try {
			const networkDestinations = values.networkDestinations.split(',').map((v) => { return v.trim(); });

			networkDestinations.forEach((n, i) => {
				if (n === '*') {
					networkDestinations[i] = '0.0.0.0/8';
				}
			});

			rasDestinationsLocal.length &&
				rasDestinationsLocal.forEach((rasDestination) => {
					if (rasDestination.name === values.name) {
						throw new Error('Destination name already exists.');
					}

					if (findDuplicates(networkDestinations)) {
						throw new Error('Network destinations need to be unique.');
					}
				});

			setRasDestinationsLocal([
				...rasDestinationsLocal,
				{
					name: values.name,
					networkDestinations,
				},
			]);

			reset();
		} catch (e) {
			pushDangerNotification(e.message);
		}
	};

	const removeNetworkDestination = (destinationName) => {
		setRasDestinationsLocal(rasDestinationsLocal.filter((destination) => { return destination.name !== destinationName; }));
	};

	const onSubmit = async () => {
		updateRasDestinations(
			rasDestinationsLocal.map((destination) => {
				return {
					name: destination.name,
					networkDestinations: destination.networkDestinations,
				};
			})
		);
		onHide();
	};

	return (
		<Modal show={show} onHide={onHide} slidingPanel={true}>
			<Modal.Header>
				<Modal.Title>Manage Network Destinations</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="border-bottom pb-4">
					<form>
						<Row>
							<Col xs={11} md={11} lg={11}>
								<InputField
									{...register(rasFields.name)}
									label="Name"
									placeholder="For eg: 'Jira', 'Production Servers"
									onKeyPress={restrictInputDestinationsName}
									errors={errors}
								/>
							</Col>
							<Col xs={1} md={1} lg={1}>
								<InfoIcon 
									placement="bottom" 
									tooltipStyle="pt-1 ms-2" 
									text="Give the IPs, networks, ports and services at this location easy to remember names. Defining addresses helps when changing the network topology at one location."
									iconType="info"
									iconColor="#22a0e9"
								/>
							</Col>
						</Row>
						<Row>
							<Col xs={11} md={11} lg={11}>
								<InputField
									{...register(rasFields.networkDestinations)}
									label="Network Destination"
									placeholder="For eg: '192.168.1.0/24, *:8080'"
									errors={errors}
								/>
							</Col>
							<Col xs={1} md={1} lg={1}>
								<InfoIcon
									placement="bottom"
									text="Valid formats: 192.168.1.15, 192.168.1.15:8080, 192.168.1.0/24, 192.168.1.0/24:8080, *:8080. &nbsp; Separate multiple entries with comma."
									iconType="info"
									iconColor="#22a0e9"
								/>
							</Col>
						</Row>
					</form>

					<div className="d-flex justify-content-end me-5">
						<Button width={96} role="secondary" onClick={handleSubmit(addNetworkDestination)}>
							Add
						</Button>
					</div>
				</div>

				<p className="mt-4 text-muted">Current Destinations</p>

				{rasDestinationsLocal.length ? (
					rasDestinationsLocal.map((destination) => {
						return (
							<div className="d-flex justify-content-between mb-2" key={destination.name} data-testid={destination.name}>
								<p>{destination.name}</p>

								<div className="d-flex justify-content-end">
									<div className="d-flex flex-wrap mt-2 ms-5 me-2">
										{destination &&
											destination.networkDestinations &&
											destination.networkDestinations.map((networkDestination, index) => {
												return (
													<div className="d-flex" key={index}>
														<Badge className="ms-1 mb-2">{networkDestination}</Badge>
													</div>
												);
											})}
									</div>

									{destination.currentlyInUse && destination.currentlyInUse === true ? (
										<Tooltip placement="top" text="This destination is used and cannot be deleted">
											<div className="cannotShowTooltipsOnDisabledButtons">
												<Button role="tertiary" disabled={true}>
													<Icon name="fas fa-times" />
												</Button>
											</div>
										</Tooltip>
									) : (
										<Button role="tertiary" onClick={() => { return removeNetworkDestination(destination.name); }}>
											<Icon name="fas fa-times" />
										</Button>
									)}
								</div>
							</div>
						);
					})
				) : (
					<div>No destinations</div>
				)}
			</Modal.Body>

			<Modal.Footer>
				<div>
					<Button
						width={96}
						role="secondary"
						className="me-3"
						onClick={() => {
							// reset local ras destinations on hide
							setRasDestinationsLocal(
								rasDestinations.map((destination) => {
									return {
										name: destination.name,
										networkDestinations: destination.networkDestinations,
									};
								})
							);

							onHide();
						}}
					>
						Cancel
					</Button>
					<Button width={96} onClick={onSubmit}>
						Save
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	);
};
