import React, { useEffect, useContext, useState } from 'react';
import { StatusActive } from './StatusActive';
import { StatusDeploying } from './StatusDeploying';
import { StatusFailed } from './StatusFailed';
import { StatusConnectionProblems } from './StatusConnectionProblems';
import { Loading } from '../../../../../components/Loading';
import { RasContext } from '../../../../../contexts/RasContext';

export const RASSettings = (props) => {
	const { onLocationSelect } = props;

	const { machinesRasInfo, selectedLocation, locationsWithRas } = useContext(RasContext);

	const rasStatus = machinesRasInfo.fulfilled[selectedLocation?.gatewayMachineSerialNumber]?.status;

	const [rasConfiguration, setRasConfiguration] = useState(null);

	useEffect(() => {
		const findRasLocation = locationsWithRas.find((l) => {
			return l.gatewayMachineSerialNumber === selectedLocation?.gatewayMachineSerialNumber;
		});
		setRasConfiguration(findRasLocation?.rasConfig);
	}, [locationsWithRas, selectedLocation]);

	const displayRasSettings = () => {
		switch (rasStatus) {
			case 'ACTIVE':
				return (
					<StatusActive
						rasConfiguration={rasConfiguration}
						selectedLocation={selectedLocation}
						onLocationSelect={onLocationSelect}
					/>
				);
			case 'DEPLOYING':
				return <StatusDeploying selectedLocation={selectedLocation} />;
			case 'FAILED':
				return <StatusFailed selectedLocation={selectedLocation} />;
			case 'INACTIVE':
				return <h6>The RAS VM is inactive.</h6>;
			case 'Connection Problems':
				return <StatusConnectionProblems />;
			default:
				if (machinesRasInfo.rejected[selectedLocation?.gatewayMachineSerialNumber]) {
					return <StatusConnectionProblems />;
				}

				return <StatusDeploying selectedLocation={selectedLocation} />;
		}
	};

	if (!selectedLocation) { return <Loading />; }

	return displayRasSettings();
};
