import { gql } from 'apollo-boost';
import moment from 'moment';
import _ from 'lodash';

export class RasService {
	constructor(graphqlClient, machineService) {
		this.graphqlClient = graphqlClient;
		this.machineService = machineService;
	}

	async getRas(hyperSerialNumber, hyperOsVersion) {
		const getRasResponse = await this.graphqlClient.query({
			query: gql`
        query getRas($hyperSerialNumber: String!, $hyperOsVersion: String!) {
          getRas(hyperSerialNumber: $hyperSerialNumber, hyperOsVersion: $hyperOsVersion) {
            users {
              username
              email
              allowedDestinations
            }
            status
            currentStep {
              dateTime
              message
            }
            logsTail
          }
        }
      `,
			variables: {
				hyperSerialNumber: hyperSerialNumber,
				hyperOsVersion: hyperOsVersion,
			},
			fetchPolicy: 'no-cache',
		});

		return getRasResponse.data.getRas;
	}

	async getRasByLocations(locationsWithRas) {
		const rasInfoAggregator = {
			fulfilled: [],
			rejected: [],
		};

		const promisesArray = [];
		for (const locationWithRas of locationsWithRas) {
			const rasPromise = await this.getRasPromise(
				locationWithRas.gatewayMachineSerialNumber,
				this.machineService.getGatewayMachineOsVersion(locationWithRas)
			);

			promisesArray.push(rasPromise);
		}

		const promisesSettled = await Promise.allSettled(promisesArray);

		promisesSettled.forEach((promiseSettled) => {
			switch (promiseSettled.status) {
				case 'fulfilled': {
					rasInfoAggregator.fulfilled[promiseSettled.value.hyperSerialNumber] = promiseSettled.value;
					break;
				}
				case 'rejected': {
					rasInfoAggregator.rejected[promiseSettled.reason.hyperSerialNumber] = promiseSettled.reason.message;
					break;
				}
				default:
					return null;
			}
		});

		return rasInfoAggregator;
	}

	async getRasPromise(hyperSerialNumber, hyperOsVersion) {
		try {
			const rasInfo = await this.getRas(hyperSerialNumber, hyperOsVersion);
			return Promise.resolve({
				hyperSerialNumber,
				...rasInfo
			});
		} catch (error) {
			return Promise.reject({
				hyperSerialNumber,
				message: error.message,
			});
		}
	}

	prepareDestinationsForHyper(destinations) {
		return destinations.map((destination) => {
			return {
				name: destination.name,
				addresses: destination.networkDestinations.map((networkDestination) => {
					return {
						IP: networkDestination,
						protocol: ['udp', 'tcp'],
					};
				}),
			};
		});
	}

	async deployRas(inputData) {
		const {
			gatewayMachineSerialNumber,
			externalNetwork,
			externalNetworkConfigurationMethod,
			externalIP,
			dns,
			gatewayIP,
			internalNetwork,
			internalNetworkConfigurationMethod,
			internalIP,
			publicIpOrFqdn,
			fqdn,
			networkDestinations,
			routes,
		} = inputData;
		const rasConfiguration = {};

		externalNetwork && (rasConfiguration.externalNetwork = externalNetwork);
		rasConfiguration.externalNetworkConfigurationMethod = externalNetworkConfigurationMethod;

		if (externalNetworkConfigurationMethod === 'manual') {
			externalIP && (rasConfiguration.externalIP = externalIP);
			dns && (rasConfiguration.dns = dns);
			gatewayIP && (rasConfiguration.gatewayIP = gatewayIP);
		}

		internalNetwork && (rasConfiguration.internalNetwork = internalNetwork);
		rasConfiguration.internalNetworkConfigurationMethod = internalNetworkConfigurationMethod;

		if (internalNetworkConfigurationMethod === 'manual') {
			internalIP && (rasConfiguration.internalIP = internalIP);
		}

		publicIpOrFqdn && (rasConfiguration.publicIpOrFqdn = publicIpOrFqdn);
		fqdn && (rasConfiguration.fqdn = fqdn);

		networkDestinations && (rasConfiguration.networkDestinations = networkDestinations);
		routes && (rasConfiguration.routes = routes);

		const deployRasResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation deployRas($hyperSerialNumber: String!, $rasConfiguration: RasConfigurationInput!) {
          deployRas(hyperSerialNumber: $hyperSerialNumber, rasConfiguration: $rasConfiguration)
        }
      `,
			variables: {
				hyperSerialNumber: gatewayMachineSerialNumber,
				rasConfiguration: rasConfiguration,
			},
			fetchPolicy: 'no-cache',
		});

		return deployRasResponse.data.deployRas;
	}

	async getRasConfiguration(hyperSerialNumber, hyperOsVersion) {
		const getRasConfigurationResponse = await this.graphqlClient.query({
			query: gql`
        query getRasConfiguration($hyperSerialNumber: String!, $hyperOsVersion: String!) {
          getRasConfiguration(hyperSerialNumber: $hyperSerialNumber, hyperOsVersion: $hyperOsVersion) {
            externalNetworkConfigurationMethod
            externalNetwork
            externalIP
            internalNetworkConfigurationMethod
            internalNetwork
            internalIP
            gatewayIP
            dns
            fqdn
            publicIpOrFqdn
            networkDestinations {
              name
              addresses {
                IP
                protocol
              }
            }
            routes {
              name
              addresses {
                IP
                protocol
              }
            }
          }
        }
      `,
			variables: {
				hyperSerialNumber: hyperSerialNumber,
				hyperOsVersion: hyperOsVersion,
			},
			fetchPolicy: 'no-cache',
		});

		return getRasConfigurationResponse.data.getRasConfiguration;
	}

	async setRasConfiguration(inputData, currentRasConfiguration) {
		const {
			gatewayMachineSerialNumber,
			externalNetwork,
			externalNetworkConfigurationMethod,
			externalIP,
			dns,
			gatewayIP,
			internalNetwork,
			internalNetworkConfigurationMethod,
			internalIP,
			publicIpOrFqdn,
			fqdn,
			networkDestinations,
			routes,
		} = inputData;
		const rasConfiguration = {};

		if (externalNetwork !== currentRasConfiguration.externalNetwork) {
			rasConfiguration.externalNetwork = externalNetwork;
		}

		if (externalNetworkConfigurationMethod !== currentRasConfiguration.externalNetworkConfigurationMethod) {
			rasConfiguration.externalNetworkConfigurationMethod = externalNetworkConfigurationMethod;
		}

		if (externalNetworkConfigurationMethod === 'manual') {
			if (
				externalNetworkConfigurationMethod !== currentRasConfiguration.externalNetworkConfigurationMethod ||
				externalIP !== currentRasConfiguration.externalIP
			) {
				rasConfiguration.externalIP = externalIP;
			}

			if (
				externalNetworkConfigurationMethod !== currentRasConfiguration.externalNetworkConfigurationMethod ||
				dns !== currentRasConfiguration.dns
			) {
				rasConfiguration.dns = dns;
			}

			if (
				externalNetworkConfigurationMethod !== currentRasConfiguration.externalNetworkConfigurationMethod ||
				gatewayIP !== currentRasConfiguration.gatewayIP
			) {
				rasConfiguration.gatewayIP = gatewayIP;
			}
		}

		if (
			externalNetworkConfigurationMethod !== currentRasConfiguration.externalNetworkConfigurationMethod ||
			internalNetwork !== currentRasConfiguration.internalNetwork
		) {
			rasConfiguration.internalNetwork = internalNetwork;
		}

		if (internalNetworkConfigurationMethod !== currentRasConfiguration.internalNetworkConfigurationMethod) {
			rasConfiguration.internalNetworkConfigurationMethod = internalNetworkConfigurationMethod;
		}

		if (internalNetworkConfigurationMethod === 'manual') {
			if (
				internalNetworkConfigurationMethod !== currentRasConfiguration.internalNetworkConfigurationMethod ||
				internalIP !== currentRasConfiguration.internalIP
			) {
				rasConfiguration.internalIP = internalIP;
			}
		}

		if (publicIpOrFqdn !== currentRasConfiguration.publicIpOrFqdn) {
			rasConfiguration.publicIpOrFqdn = publicIpOrFqdn;
		}

		if (fqdn !== currentRasConfiguration.fqdn) {
			rasConfiguration.fqdn = fqdn;
		}

		if (!_.isEqual(networkDestinations, currentRasConfiguration.networkDestinations)) {
			rasConfiguration.networkDestinations = networkDestinations;
		}

		if (!_.isEqual(routes, currentRasConfiguration.routes)) {
			rasConfiguration.routes = routes;
		}

		const setRasConfigurationRasResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation setRasConfiguration($hyperSerialNumber: String!, $rasConfiguration: RasConfigurationInput!) {
          setRasConfiguration(hyperSerialNumber: $hyperSerialNumber, rasConfiguration: $rasConfiguration)
        }
      `,
			variables: {
				hyperSerialNumber: gatewayMachineSerialNumber,
				rasConfiguration: rasConfiguration,
			},
			fetchPolicy: 'no-cache',
		});

		return setRasConfigurationRasResponse.data.setRasConfiguration;
	}

	async cancelRasDeploy(hyperSerialNumber) {
		const cancelRasDeployResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($hyperSerialNumber: String!) {
          cancelRasDeploy(hyperSerialNumber: $hyperSerialNumber)
        }
      `,
			variables: {
				hyperSerialNumber: hyperSerialNumber,
			},
			fetchPolicy: 'no-cache',
		});

		return cancelRasDeployResponse.data.cancelRasDeploy;
	}

	async undeployRas(hyperSerialNumber) {
		const undeployRasResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($hyperSerialNumber: String!) {
          undeployRas(hyperSerialNumber: $hyperSerialNumber)
        }
      `,
			variables: {
				hyperSerialNumber: hyperSerialNumber,
			},
			fetchPolicy: 'no-cache',
		});

		return undeployRasResponse.data.undeployRas;
	}

	async getAddRasUserPromise(
		hyperSerialNumber,
		hyperOsVersion,
		userId,
		email,
		locationId,
		networkDestinations,
		allowedDestinations
	) {
		try {
			await this.addRasUser(
				hyperSerialNumber,
				hyperOsVersion,
				userId,
				email,
				locationId,
				networkDestinations,
				allowedDestinations
			);

			return Promise.resolve({
				email
			});
		} catch (error) {
			return Promise.reject({
				email,
				message: error.message,
			});
		}
	}

	async addRasUsers(inputData) {
		const { users, hyperSerialNumber, hyperOsVersion, locationId, networkDestinations, allowedDestinations } = inputData;
		const addRasUserAggregator = {
			fulfilled: [],
			rejected: [],
		};

		const promisesArray = [];
		for (const user of users) {
			const addRasUserPromise = await this.getAddRasUserPromise(
				hyperSerialNumber,
				hyperOsVersion,
				user.id,
				user.value,
				locationId,
				networkDestinations,
				allowedDestinations
			);

			promisesArray.push(addRasUserPromise);
		}

		const promisesSettled = await Promise.allSettled(promisesArray);

		promisesSettled.forEach((promiseSettled) => {
			switch (promiseSettled.status) {
				case 'fulfilled': {
					addRasUserAggregator.fulfilled[promiseSettled.value.email] = promiseSettled.value;
					break;
				}
				case 'rejected': {
					addRasUserAggregator.rejected[promiseSettled.reason.email] = promiseSettled.reason.message;
					break;
				}
				default:
					return null;
			}
		});

		return addRasUserAggregator;
	}

	async addRasUser(
		hyperSerialNumber,
		hyperOsVersion,
		userId,
		email,
		locationId,
		networkDestinations,
		allowedDestinations
	) {
		const addRasUserResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation (
          $hyperSerialNumber: String!
          $hyperOsVersion: String!
          $userId: String!
          $email: String!
          $locationId: String!
          $networkDestinations: [RasNetworkDestinationInput]
          $allowedDestinations: [RasNetworkDestinationInput]
        ) {
          addRasUser(
            hyperSerialNumber: $hyperSerialNumber
            hyperOsVersion: $hyperOsVersion
            userId: $userId
            email: $email
            locationId: $locationId
            networkDestinations: $networkDestinations
            allowedDestinations: $allowedDestinations
          )
        }
      `,
			variables: {
				hyperSerialNumber: hyperSerialNumber,
				hyperOsVersion: hyperOsVersion,
				userId: userId,
				email: email,
				locationId: locationId,
				networkDestinations: networkDestinations,
				allowedDestinations: allowedDestinations,
			},
			fetchPolicy: 'no-cache',
		});

		return addRasUserResponse.data.addRasUser;
	}

	async removeRasUser(
		hyperSerialNumber,
		hyperOsVersion,
		userId,
		email,
		locationId,
		networkDestinations,
		allowedDestinations
	) {
		const removeRasUserResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation (
          $hyperSerialNumber: String!
          $hyperOsVersion: String!
          $userId: String!
          $email: String!
          $locationId: String!
          $networkDestinations: [RasNetworkDestinationInput]
          $allowedDestinations: [RasNetworkDestinationInput]
        ) {
          removeRasUser(
            hyperSerialNumber: $hyperSerialNumber
            hyperOsVersion: $hyperOsVersion
            userId: $userId
            email: $email
            locationId: $locationId
            networkDestinations: $networkDestinations
            allowedDestinations: $allowedDestinations
          )
        }
      `,
			variables: {
				hyperSerialNumber: hyperSerialNumber,
				hyperOsVersion: hyperOsVersion,
				userId: userId,
				email: email,
				locationId: locationId,
				networkDestinations: networkDestinations,
				allowedDestinations: allowedDestinations,
			},
			fetchPolicy: 'no-cache',
		});

		return removeRasUserResponse.data.removeRasUser;
	}

	async filterLocationsTheUserHasRasAccessTo(locations, rasInfo, email) {
		const locationsTheUserHasAccessTo = [];

		Object.keys(rasInfo).forEach((serialNumber) => {
			const rasInfoEntry = rasInfo[serialNumber];

			if (rasInfoEntry.users && rasInfoEntry.users.length > 0) {
				const user = rasInfoEntry.users.find((user) => { return user.email === email; });
				if (user) {
					const location = locations.find((location) => { return location.gatewayMachineSerialNumber === serialNumber; });
					if (location) {
						location.user = user;
						locationsTheUserHasAccessTo.push(location);
					}
				}
			}
		});

		return locationsTheUserHasAccessTo;
	}

	async downloadRasConfigurationFile(email, password, token, hyperSerialNumber, hyperOsVersion) {
		const downloadRasConfigurationFileResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($credentials: CredentialsInput!, $hyperSerialNumber: String!, $hyperOsVersion: String!) {
          downloadRasConfigurationFile(
            credentials: $credentials
            hyperSerialNumber: $hyperSerialNumber
            hyperOsVersion: $hyperOsVersion
          )
        }
      `,
			variables: {
				credentials: {
					email: email,
					password: password,
					token: token,
				},
				hyperSerialNumber: hyperSerialNumber,
				hyperOsVersion: hyperOsVersion,
			},
			fetchPolicy: 'no-cache',
		});

		return downloadRasConfigurationFileResponse.data.downloadRasConfigurationFile;
	}

	async setRasNetworkDestinations(hyperSerialNumber, networkDestinations) {
		const setRasNetworkDestinationsResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($hyperSerialNumber: String!, $networkDestinations: [RasNetworkDestinationInput]!) {
          setRasNetworkDestinations(hyperSerialNumber: $hyperSerialNumber, networkDestinations: $networkDestinations)
        }
      `,
			variables: {
				hyperSerialNumber: hyperSerialNumber,
				networkDestinations: networkDestinations,
			},
			fetchPolicy: 'no-cache',
		});

		return setRasNetworkDestinationsResponse.data.setRasNetworkDestinations;
	}

	async setRasUserAllowedDestinations(hyperSerialNumber, email, networkDestinations, allowedDestinations) {
		const setRasUserAllowedDestinationsResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation (
          $hyperSerialNumber: String!
          $email: String!
          $networkDestinations: [RasNetworkDestinationInput]!
          $allowedDestinations: [RasNetworkDestinationInput]!
        ) {
          setRasUserAllowedDestinations(
            hyperSerialNumber: $hyperSerialNumber
            email: $email
            networkDestinations: $networkDestinations
            allowedDestinations: $allowedDestinations
          )
        }
      `,
			variables: {
				hyperSerialNumber: hyperSerialNumber,
				email: email,
				networkDestinations: networkDestinations,
				allowedDestinations: allowedDestinations,
			},
			fetchPolicy: 'no-cache',
		});

		return setRasUserAllowedDestinationsResponse.data.setRasUserAllowedDestinations;
	}

	locationHasAtLeastOneOsVersionSuitable(machines) {
		return machines && machines.filter((machine) => { return machine.osVersion && machine.osVersion >= '4.6.0'; }).length;
	}

	writeRasDeployLogsOnLocalStorage(machine, serialNumber) {
		const dateTime = machine?.currentStep?.dateTime;
		const message = machine?.currentStep?.message;
		const currentStep = `${dateTime && moment.unix(dateTime).format(' HH:mm')} ${message}`;

		const getRasLogs = JSON.parse(localStorage.getItem(`${serialNumber}`));

		let newRasLogs = [];

		// push the first step log
		if (!getRasLogs) {
			newRasLogs.push(currentStep);
		}

		// push the rest of the steps, avoiding  writing the same log message again
		if (getRasLogs?.length && currentStep !== getRasLogs[getRasLogs.length - 1]) {
			newRasLogs = [...getRasLogs, currentStep];
		}

		// write the logs on local storage
		if (newRasLogs.length) {
			localStorage.setItem(`${serialNumber}`, JSON.stringify(newRasLogs));
		}
	}
}
