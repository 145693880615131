// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/monitoring-splash.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/img/no-users-data.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monitoring-splash{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-position:center;background-size:contain;height:256px}.no-recipients-data{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___});background-repeat:no-repeat;background-position:center;height:256px}`, "",{"version":3,"sources":["webpack://./src/pages/Monitoring/style.scss"],"names":[],"mappings":"AAAA,mBACE,wDAAA,CACA,2BAAA,CACA,0BAAA,CACA,uBAAA,CACA,YAAA,CAGF,oBACE,wDAAA,CACA,2BAAA,CACA,0BAAA,CACA,YAAA","sourcesContent":[".monitoring-splash {\n  background-image: url(\"../../assets/img/monitoring-splash.svg\");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: contain;\n  height: 256px;\n}\n\n.no-recipients-data {\n  background-image: url(\"../../assets/img/no-users-data.svg\");\n  background-repeat: no-repeat;\n  background-position: center;\n  height: 256px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
