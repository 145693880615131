import React from 'react';
import { Spinner } from '@syneto/compass-react';
import PropTypes from 'prop-types';

export const Loading = (props) => {
	const { text, type } = props;

	return (
		<div
			className={type === 'inline' ? 'd-inline-block' : 'd-flex align-items-center justify-content-center w-100 h-100'}
		>
			<div className="text-center">
				<Spinner animation="border" variant="primary" {...props} />
				{text && <div className="mt-3">{text}</div>}
			</div>
		</div>
	);
};

Loading.propTypes = {
	text: PropTypes.string,
	type: PropTypes.string,
	animation: PropTypes.string,
	variant: PropTypes.string,
	size: PropTypes.string,
};
