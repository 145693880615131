import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Button, Icon, Popover, Tooltip } from '@syneto/compass-react';
import moment from 'moment';
import { trimString } from '../../../helpers/string';
import DateFormatter from '../../../helpers/DateFormatter';
import MaintenanceStatusFormatter from '../../../helpers/MaintenanceStatusFormatter';
import CurrentlyActiveMaintenancePlan from '../../../helpers/CurrentlyActiveMaintenancePlan';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { Loading } from '../../../components/Loading';
import { StoreContext } from '../../../contexts/StoreContext';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { SerenityEventsButton } from './SerenityEventsButton';

export const MachineDetails = (props) => {
	const { machine, generateMaintenanceCertificationPdf } = props;
	const { settingsService, locationService } = useContext(ServiceContext);
	const { pushSuccessfulNotification, pushDangerNotification } = useContext(NotificationContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;

	const [serenityEventsSettings, setSerenityEventsSettings] = useState(null);
	const [loading, setLoading] = useState(true);
	const [expiringInterval, setExpiringInterval] = useState(30);

	useEffect(() => {
		const getLocationsRenewalsSettings = async () => {
			const renewalsSettings = await locationService.getLocationsRenewalsSettings();
			const interval = renewalsSettings?.expiringIntervalSettingForCustomer?.split('-');
			setExpiringInterval(parseInt(interval[0]));
		};

		getLocationsRenewalsSettings();
	}, []);

	useEffect(() => {
		loadMachineDetailsData();
	}, [machine]);

	const loadMachineDetailsData = async () => {
		try {
			setSerenityEventsSettings(await settingsService.getSerenityEventsSettings());
			setLoading(false);
		} catch (error) {
			pushDangerNotification('Failed to fetch machine details data.');
		}
	};

	const sendSerenityRequestEmail = async () => {
		try {
			await settingsService.sendSerenityRequestEmail(loggedInUser.id, machine.serialNumber);
			pushSuccessfulNotification('SerenITy request has been successfully sent.');
		} catch (error) {
			pushDangerNotification('Failed to send SerenITy request.');
		}
	};

	const maintenanceStatus = MaintenanceStatusFormatter.maintenanceStatus(machine.maintenanceServices[0].expiresOn);

	const currentlyActiveMaintenancePlan = CurrentlyActiveMaintenancePlan(machine.maintenanceServices[0]);

	const getValidityDuration = ({ duration, maintenanceServiceRenewals }) => {
		let durationAndRenewals = duration;

		if (maintenanceServiceRenewals && maintenanceServiceRenewals.length > 0) {
			maintenanceServiceRenewals.forEach((maintenanceExtension) => {
				durationAndRenewals += maintenanceExtension.duration;
			});
		}

		if (durationAndRenewals >= 12) {
			const years = parseInt(durationAndRenewals / 12);
			const months = durationAndRenewals % 12;

			const yearText = years === 1 
				? `${years} Year` 
				: `${years} Years`;
								
			const monthText = months > 0 
				? months === 1 ? `${months} Month` : `${months} Months`
				: '';

			return monthText !== '' ? `${yearText} & ${monthText}`: yearText;
		} else {
			return durationAndRenewals === 1 ? `${durationAndRenewals} Month` : `${durationAndRenewals} Months`; 
		}
	};

	const activationDate = DateFormatter.formatDateToSpecificFormat(machine, 'activationDate', 'DD/MM/YYYY');

	const expirationDate = DateFormatter.formatDateToSpecificFormat(
		machine.maintenanceServices[0],
		'expiresOn',
		'DD/MM/YYYY'
	);

	function maintenanceStatusFormatter(maintenanceService) {
		const currentDate = moment().format('YYYY-MM-DD');
		let status = 'Active';

		if (maintenanceService.expiresOn && moment(maintenanceService.expiresOn).diff(currentDate, 'days') < 0) {
			status = 'Expired';
		}

		if (
			maintenanceService.expiresOn &&
			moment(maintenanceService.expiresOn).diff(currentDate, 'days') > 0 &&
			moment(maintenanceService.expiresOn).diff(currentDate, 'days') <= expiringInterval
		) {
			status = 'Expiring';
		}

		return status;
	}

	function renderSerenityEnabled(serenityEnabled) {
		let hasSerenity, status, message;
		hasSerenity = currentlyActiveMaintenancePlan.hasSerenity;

		status = 'Not supported';
		message = (
			<div className="m-1">
				<div className="pb-3">
					SerenITy is the pro-active support service created by Syneto for constant monitoring of systems and sending
					automatic alerts to Customer Support.
				</div>
				<div className="pb-3">
					This machine is not entitled for the SerenITy service. Our sales team can get in touch to inform you how it
					can be activated.
				</div>
				<div>
					If you want to learn how, click the [Send request] button below. You will be contacted at the following email
					address:
				</div>
				<div className="pb-3 font-weight-500">{loggedInUser.email}</div>
				<div className="container p-0">
					<div className="float-left pt-2 mb-3">
						<a
							className="btn-link"
							href={serenityEventsSettings.serenityMarketingSite}
							target="_blank"
							rel="noopener noreferrer"
						>
							Learn more <Icon name="fa fa-external-link" />
						</a>
					</div>
					<div className="float-right mb-3">
						<Button type="submit" onClick={sendSerenityRequestEmail}>
							Send request
						</Button>
					</div>
				</div>
			</div>
		);

		if (hasSerenity && serenityEnabled === true) {
			status = 'Active';
			message = (
				<div>
					<div className="pb-2">This machine is protected using a proactive support service called SerenITy.</div>
					<a
						className="btn-link"
						href={serenityEventsSettings.serenityMarketingSite}
						target="_blank"
						rel="noopener noreferrer"
					>
						Learn more <Icon name="fa fa-external-link" />
					</a>
				</div>
			);
		}

		if (hasSerenity && (serenityEnabled === false || serenityEnabled == null)) {
			status = 'Inactive';
			message = (
				<div className="m-1">
					<div className="pb-3">
						This machine is entitled to the proactive support service called SerenITy. However, SerenITy is currently
						disabled for this appliance.
					</div>
					<div className="container p-0">
						<div className="float-left pt-2 pb-3">
							<a
								className="btn-link"
								href={serenityEventsSettings.serenityMarketingSite}
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more <Icon name="fa fa-external-link" />
							</a>
						</div>
					</div>
				</div>
			);
		}

		if (maintenanceStatus === 'Expired') {
			status = 'Inactive';
			message = (
				<div className="m-1">
					<div className="pb-3">
						This machine is no longer protected using a proactive support service called SerenITy, as its maintenance
						has expired.
					</div>
					<div className="container p-0">
						<div className="float-left pt-2 pb-3">
							<a
								className="btn-link"
								href={serenityEventsSettings.serenityMarketingSite}
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn more <Icon name="fa fa-external-link" />
							</a>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div style={{ cursor: 'help' }}>
				<Popover body={message} trigger="click">
					<Badge appearance={status === 'Active' ? 'success' : 'danger'}>{status}</Badge>
				</Popover>
			</div>
		);
	}

	return loading ? (
		<Loading />
	) : (
		<>
			<h5 className="mb-4">Machine Details</h5>
			<p className="text-muted">Device Details</p>
			<div className="d-flex justify-content-between">
				<p>Machine Name</p>
				{machine.name ? <p className="text-primary">{machine.name}</p> : 'N/A'}
			</div>
			<div className="d-flex justify-content-between mb-4">
				<p>Activated by</p>
				{`${machine.user.profile.firstName} ${machine.user.profile.lastName}`.length > 40 ? (
					<Tooltip
						placement="bottom"
						theme="light"
						text={`${machine.user.profile.firstName} ${machine.user.profile.lastName}`}
					>
						<Badge appearance="secondary">
							{trimString(`${machine.user.profile.firstName} ${machine.user.profile.lastName}`, 40)}
						</Badge>
					</Tooltip>
				) : (
					<Badge appearance="secondary">{`${machine.user.profile.firstName} ${machine.user.profile.lastName}`}</Badge>
				)}
			</div>
			<p className="text-muted">Maintenance</p>
			<div className="d-flex justify-content-between">
				<p>Status</p>
				<Badge
					appearance={
						maintenanceStatusFormatter(machine.maintenanceServices[0]) === 'Active'
							? 'success'
							: maintenanceStatusFormatter(machine.maintenanceServices[0]) === 'Expiring'
								? 'warning'
								: 'danger'
					}
				>
					{maintenanceStatusFormatter(machine.maintenanceServices[0])}
				</Badge>
			</div>
			<div className="d-flex justify-content-between">
				<p>Type</p>
				<div>
					{currentlyActiveMaintenancePlan.maintenancePlan.customerFacingName}
					<Popover
						trigger="click"
						body={
							<>
								{currentlyActiveMaintenancePlan.maintenancePlan.endUserDescription}
								<div className="pt-2">
									<a className="btn-link" href="https://syneto.eu/support/" target="_blank" rel="noopener noreferrer">
										Read more <Icon name="fa fa-external-link" />
									</a>
								</div>
							</>
						}
					>
						<span>
							<Icon name="fa fa-question-circle ms-2" />
						</span>
					</Popover>
					<Tooltip placement="left" theme="light" text="Download maintenance certification">
						<a
							className="text-neutral-90"
							style={{ cursor: 'pointer' }}
							onClick={() => {
								return generateMaintenanceCertificationPdf(
									expirationDate,
									currentlyActiveMaintenancePlan.maintenancePlan.customerFacingName,
									machine.serialNumber
								);
							}
							}
						>
							<Icon name="fa fa-file-pdf ms-2" />
						</a>
					</Tooltip>
				</div>
			</div>
			<div className="d-flex justify-content-between">
				<p>Duration</p>
				<p>{getValidityDuration(machine.maintenanceServices[0])}</p>
			</div>
			<div className="d-flex justify-content-between">
				<p>Activation Date</p>
				<p>{activationDate}</p>
			</div>
			<div className="d-flex justify-content-between mb-4">
				<p>Expiration Date</p>
				<p>{expirationDate}</p>
			</div>
			<p className="text-muted">Support</p>
			<div className="d-flex justify-content-between">
				<p>SerenITy Status</p>
				{renderSerenityEnabled(machine.serenityEnabled)}
			</div>
			{currentlyActiveMaintenancePlan.hasSerenity &&
				machine.serenityEnabled === true &&
				maintenanceStatus !== 'Expired' && (
				<div className="d-flex justify-content-between">
					<p>SerenITy Events</p>
					<SerenityEventsButton machine={machine} />
				</div>
			)}
		</>
	);
};

MachineDetails.propTypes = {
	machine: PropTypes.object.isRequired,
};
