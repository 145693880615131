import React, { useContext, useState, useEffect } from 'react';
import { IdentityVerificationModal } from '../../IdentityVerificationModal';
import { StoreContext } from '../../../../../contexts/StoreContext';
import { RasContext } from '../../../../../contexts/RasContext';
import { ServiceContext } from '../../../../../contexts/ServiceContext';
import './style.scss';

export const Download = () => {
	const { rasService } = useContext(ServiceContext);
	const { machinesRasInfo, selectedLocation } = useContext(RasContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;

	const [showIdentityVerificationModal, setShowIdentityVerificationModal] = useState(false);
	const [locationUsers, setLocationUsers] = useState([]);
	const [locationUsersEmails, setLocationUsersEmails] = useState([]);

	useEffect(() => {
		if (selectedLocation) {
			const allLocationUsers = machinesRasInfo.fulfilled[selectedLocation.gatewayMachineSerialNumber]?.users;
			setLocationUsers(allLocationUsers);

			const locationUsersEmail = (allLocationUsers?.length && allLocationUsers.map((user) => { return user.email; })) || [];
			setLocationUsersEmails(locationUsersEmail);
		}
	}, [selectedLocation, machinesRasInfo]);

	if (locationUsersEmails.includes(loggedInUser.email)) {
		const username = locationUsers.find((user) => { return user.email === loggedInUser.email; })?.username;

		return (
			<div>
				<h5>{selectedLocation && selectedLocation.name}</h5>

				<p className="mt-3">
          You may download the Remote Access configuration file below and connect using the following credentials:
				</p>

				<span className="mt-4">
          Username: <strong>{username}</strong>
				</span>
				<br />
				<p className="mb-4">
          Password: <strong>your central account password</strong>
				</p>

				<div className="download-vpn-card" onClick={() => { return setShowIdentityVerificationModal(true); }}>
					<div className="download-vpn-card-cover" />
					<p className="download-vpn-card-cover-text text-primary">Download</p>
				</div>

				{selectedLocation && (
					<IdentityVerificationModal
						show={showIdentityVerificationModal}
						onHide={() => { return setShowIdentityVerificationModal(false); }}
						user={loggedInUser}
						location={selectedLocation}
						rasService={rasService}
					/>
				)}
			</div>
		);
	} else {
		return 'Your account is not assigned to this RAS Location.';
	}
};
