import React, { useContext, useEffect, useState } from 'react';
import { Icon } from '@syneto/compass-react';
import { Navigate } from 'react-router-dom';
import { StoreContext } from '../../contexts/StoreContext';
import { ServiceContext } from '../../contexts/ServiceContext';
import { DashboardContext } from '../../contexts/DashboardContext';
import { Loading } from '../../components/Loading';
import { LocationCard } from './components/LocationCard';
import { AddLocationModal } from './components/AddLocationModal';
import { EditLocationModal } from './components/EditLocationModal';
import { OnboardingTour } from './components/OnboardingTour';
import { UnassignedMachines } from './components/UnassignedMachines';
import { Does } from '../../components/Access/Does';
import './style.scss';

export const Dashboard = () => {
	const { getData, unassignedMachines, locations, loading } = useContext(DashboardContext);
	const { accessService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser, services } = state;

	const [showAddLocationModal, setShowAddLocationModal] = useState(false);
	const [showEditLocationModal, setShowEditLocationModal] = useState(false);
	const [currentLocation, setCurrentLocation] = useState(null);

	useEffect(() => {
		if (accessService.hasPermissionOnAnyResource('location.list')) { getData(); }
	}, []);

	const shouldRedirectToWelcomePage = () => {
		return (
			!accessService.hasPermissionOnAnyResource('location.list') &&
			!accessService.hasPermissionOnAnyResource('appliance.list') &&
			!accessService.hasPermissionOnAnyResource('user.list') &&
			(!accessService.hasPermissionOnAnyResource('ras.list') ||
				(!services?.ras?.active && accessService.hasPermissionOnAnyResource('ras.list')))
		);
	};

	const shouldRedirectToUsersPage = () => {
		return (
			!accessService.hasPermissionOnAnyResource('location.list') &&
			!accessService.hasPermissionOnAnyResource('appliance.list') &&
			accessService.hasPermissionOnAnyResource('user.list')
		);
	};

	const shouldRedirectToRasPage = () => {
		return (
			!accessService.hasPermissionOnAnyResource('location.list') &&
			!accessService.hasPermissionOnAnyResource('appliance.list') &&
			!accessService.hasPermissionOnAnyResource('user.list')
		);
	};

	const renderLocationCard = (location) => {
		return (
			<div
				key={location.id}
				className={unassignedMachines.length > 0 ? 'col-xl-6 col-md-12' : 'col-xl-4 col-lg-6 col-md-12'}
				style={{ marginBottom: '30px', minHeight: '305px' }}
			>
				<LocationCard
					location={location}
					showEditLocationModal={() => {
						setCurrentLocation(location);
						setShowEditLocationModal(true);
					}}
				/>
			</div>
		);
	};

	if (shouldRedirectToWelcomePage()) {
		return <Navigate to="/welcome" />;
	} else if (shouldRedirectToUsersPage()) {
		return <Navigate to="/users" />;
	} else if (shouldRedirectToRasPage()) {
		return <Navigate to="/ras" />;
	}

	return loading ? (
		<Loading />
	) : (
		<>
			<Does
				loggedInUser
				havePermission="account.company.update"
				onCompanyResource
				yes={() => { return !loggedInUser.profile.seenWelcomeBanner && <OnboardingTour />; }}
			/>
			<div className="row">
				<Does
					loggedInUser
					havePermission="location.appliance.assign"
					onAnyResource={true}
					yes={() => { return <UnassignedMachines />; }}
				/>
				<div className={unassignedMachines.length > 0 ? 'col-xl-8 col-lg-6 col-md-12' : 'col-xl-12'}>
					<div className="row d-flex flex-wrap justify-content-start">
						{locations.map((location) => {
							return (
								<Does
									key={location.id}
									loggedInUser
									havePermission="location.view"
									onCompanyResource
									yes={() => { return renderLocationCard(location); }}
									no={() => {
										return (
											<Does
												loggedInUser
												havePermission="location.view"
												onResource={{
													type: 'location',
													referenceId: location.id,
												}}
												yes={() => { return renderLocationCard(location); }}
											/>
										);
									}}
								/>
							);
						})}
						<Does
							loggedInUser
							havePermission="location.create"
							onCompanyResource
							yes={() => {
								return (
									<>
										<div
											className={unassignedMachines.length > 0 ? 'col-xl-6 col-md-12' : 'col-xl-4 col-lg-6 col-md-12'}
											style={{ marginBottom: '30px', minHeight: '305px' }}
										>
											<div
												className="new-location-card d-flex align-items-center justify-content-center h-100"
												onClick={() => { return setShowAddLocationModal(!showAddLocationModal); }}
											>
												<div className="pt-4 pb-4 text-center">
													<Icon name="fa fa-plus-circle" color="#5b69e1" style={{ fontSize: '48px' }} />
													<h3 className="mt-4 mb-0 font-weight-bold text-accent">New Location</h3>
												</div>
											</div>
										</div>
										{showAddLocationModal && (
											<AddLocationModal show={showAddLocationModal} onHide={() => { return setShowAddLocationModal(false); }} />
										)}
									</>
								);
							}}
						/>
					</div>
				</div>
			</div>
			<Does
				loggedInUser
				havePermission="location.update"
				onAnyResource={true}
				yes={() => {
					return showEditLocationModal && (
						<EditLocationModal
							show={showEditLocationModal}
							onHide={() => { return setShowEditLocationModal(false); }}
							location={currentLocation}
						/>
					);
				}
				}
			/>
		</>
	);
};
