import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Table } from '@syneto/compass-react';
import _ from 'lodash';
import { toUppercase } from '../../helpers/string';
import ConvertTimestampToDateTime from '../../helpers/ConvertTimestampToDateTime';
import { SerenityEventDetails } from './components/SerenityEventDetails';
import { StoreContext } from '../../contexts/StoreContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { Loading } from '../../components/Loading';
import './style.scss';
import MaintenanceStatusFormatter from '../../helpers/MaintenanceStatusFormatter';
import CurrentlyActiveMaintenancePlan from '../../helpers/CurrentlyActiveMaintenancePlan';
import { ServiceContext } from '../../contexts/ServiceContext';
import { Does } from '../../components/Access/Does';

export const SerenityEvents = () => {
	const { serenityEventService, locationService, machineService, settingsService, accessService } =
		useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;
	const { pushSuccessfulNotification, pushDangerNotification } = useContext(NotificationContext);

	const [serenityEvents, setSerenityEvents] = useState([]);
	const [serenityStatus, setSerenityStatus] = useState('Not supported');
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [serenityEventsSettings, setSerenityEventsSettings] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		loadSerenityEventsData();

		return () => {
			setLoading(true);
		};
	}, []);

	const loadSerenityEventsData = async () => {
		try {
			const locations = await locationService.getLocations();
			const locationIds = locations.map((location) => { return location.id; });
			const machines = accessService.hasPermissionOnCompanyResource('location.create')
				? await machineService.getMachinesByCompanyId(loggedInUser.company.id)
				: locationIds.length
					? await machineService.getMachinesByLocationIds(locationIds)
					: [];

			const machinesWithSerenityActive = getMachinesWithSerenityActive(machines);
			const machinesWithSerenityInactive = getMachinesWithSerenityInactive(machines);
			machinesWithSerenityActive.length && setSerenityStatus('Active');
			machinesWithSerenityInactive.length && setSerenityStatus('Inactive');

			if (machinesWithSerenityActive.length) {
				const getSerenityEventsBySerialNumbers = await serenityEventService.getSerenityEventsBySerialNumbers(
					machinesWithSerenityActive.map((machine) => { return machine.serialNumber; })
				);
				const filteredEvents = filterEvents(getSerenityEventsBySerialNumbers);

				setSerenityEvents(filteredEvents);
				setSelectedEvent(filteredEvents[0]);
			}

			setSerenityEventsSettings(await settingsService.getSerenityEventsSettings());
			setLoading(false);
		} catch (error) {
			pushDangerNotification('Failed to fetch SerenITy events data.');
		}
	};

	const getMachinesWithSerenityActive = (machines) => {
		return machines.filter(
			(machine) => {
				return (CurrentlyActiveMaintenancePlan(machine.maintenanceServices[0]).hasSerenity && machine.serenityEnabled) ||
					MaintenanceStatusFormatter.maintenanceStatus(machine.maintenanceServices[0].expiresOn) === 'Expired';
			}
		);
	};
	const getMachinesWithSerenityInactive = (machines) => {
		return machines.filter(
			(machine) => {
				return (CurrentlyActiveMaintenancePlan(machine.maintenanceServices[0]).hasSerenity && !machine.serenityEnabled) ||
					MaintenanceStatusFormatter.maintenanceStatus(machine.maintenanceServices[0].expiresOn) === 'Expired';
			}
		);
	};

	const formatSerenityEventsStatus = (status) => {
		switch (status) {
			case 'new':
				return 'info';
			case 'pending':
				return 'warning';
			case 'resolved':
				return 'success';
			case 'closed':
				return 'secondary';
			default:
				return null;
		}
	};

	const filterEvents = (events) => {
		let orderedEvents = [];

		// orderedEvents = array with events desc ordered. The events contain only the most recent state and userState
		events.forEach((event) => {
			const orderedStates = _.orderBy(event.state, 'timestamp', 'desc');
			const orderedUserStates = _.orderBy(event.userState, 'timestamp', 'desc');

			if (orderedStates && orderedStates.length && orderedUserStates && orderedUserStates.length) {
				const orderedAndStrippedEvent = {
					...event,
					state: orderedStates[0],
					userState: orderedUserStates[0],
				};

				orderedEvents.push(orderedAndStrippedEvent);
			}
		});

		// returns an array that do not contain 'new', 'ignore' and 'acknowledged' states
		return orderedEvents.filter((orderedEvent) => {
			return (
				orderedEvent.state.value !== 'new' &&
				orderedEvent.state.value !== 'ignored' &&
				orderedEvent.userState.value !== 'acknowledged'
			);
		});
	};

	const renderStatus = (cell, row) => {
		return <Badge appearance={`${formatSerenityEventsStatus(row.state.value)}`}>{toUppercase(row.state.value)}</Badge>;
	};

	const renderTimestamp = (cell, row) => { return ConvertTimestampToDateTime(row.recordTimestamp); };

	const serialNumberFilter = () => {
		const serialNumbers = new Set();
		const opts = [];
		serenityEvents.forEach((event) => {
			serialNumbers.add(event.emitter.serialNumber);
		});
		serialNumbers.forEach((serialNumber) => { return opts.push({ label: serialNumber, value: serialNumber }); });
		return opts;
	};

	const sendSerenityRequestEmail = async () => {
		try {
			await settingsService.sendSerenityRequestEmail(loggedInUser.id);
			pushSuccessfulNotification('SerenITy request has been successfully sent.');
		} catch (error) {
			pushDangerNotification('Failed to send SerenITy request.');
		}
	};

	return loading ? (
		<Loading />
	) : (
		<div className="min-vh-80 shadow p-3 bg-white rounded">
			{serenityEvents.length && selectedEvent ? (
				<>
					<div className="min-vh-80 row">
						<div className="col-9 border-right">
							<Table
								data={serenityEvents}
								keyField="id"
								search
								selectRow={{
									mode: 'radio',
									classes: 'selected',
									clickToSelect: true,
									hideSelectColumn: true,
									selected: [selectedEvent.id],
									onSelect: (event) => { return setSelectedEvent(event); },
								}}
							>
								<Table.Col field="emitter.serialNumber" filter="select" filterOptions={serialNumberFilter()}>
									Serial Number
								</Table.Col>
								<Table.Col
									field="state.value"
									filter="select"
									filterOptions={[
										{ label: 'Pending', value: 'pending' },
										{ label: 'Resolved', value: 'resolved' },
										{ label: 'Closed', value: 'closed' },
									]}
									renderCell={renderStatus}
								>
									Status
								</Table.Col>
								<Table.Col field="timestamp" renderCell={renderTimestamp}>
									Date - Time
								</Table.Col>
								<Table.Col field="content.subject">Event Title</Table.Col>
							</Table>
						</div>
						<div className="col-3 p-4">
							<Does
								loggedInUser
								havePermission="appliance.view"
								onAnyResource={true}
								yes={() => {
									return (
										<SerenityEventDetails
											serenityEvent={selectedEvent}
											formatSerenityEventsStatus={formatSerenityEventsStatus}
										/>
									);
								}}
							/>
						</div>
					</div>
				</>
			) : (
				<div>
					<div className="no-serenity-events my-5" />
					<h1 className="text-primary text-center mb-3">
						{serenityStatus === 'Not supported' ? 'SerenITy events' : 'No SerenITy events'}
					</h1>
					{serenityStatus === 'Not supported' && (
						<>
							<h6 className="text-muted text-center">
								SerenITy is the pro-active support service created by Syneto for constant monitoring of systems and
								sending automatic alerts to Customer Support.
							</h6>
							<h6 className="text-muted text-center">Your machines are not entitled for the SerenITy service.</h6>
							<h6 className="text-muted text-center">
								Our sales team can get in touch to inform you how it can be activated.
							</h6>
							<h6 className="text-muted text-center">
								If you want to learn how, click the [Send request] button below. You will be contacted at the following
								email address: {loggedInUser.email}
							</h6>
							<div className="d-flex justify-content-around mt-5">
								<Button role="link" href={serenityEventsSettings.serenityMarketingSite} target="_blank">
									Learn more
								</Button>
								<Button type="submit" onClick={sendSerenityRequestEmail}>
									Send request
								</Button>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};
