import { registrationFields } from '../Registration/fields';

const commonFields = { ...registrationFields };
delete commonFields.checkPassword;

export const profileFields = {
	...commonFields,
	id: 'id',
	hasPermissionToUpdateCompanyProfile: 'hasPermissionToUpdateCompanyProfile',
	globalRoles: 'globalRoles',
	newPassword: 'newPassword',
	retypeNewPassword: 'retypeNewPassword',
};

const commonProfileFields = { ...profileFields };
const fieldsToBeExcluded = [
	'companyName',
	'companyVat',
	'companyMarketSector',
	'companyNumberEmployees',
	'companyCountry',
	'companyState',
	'companyCity',
	'companyAddress',
	'companyPostalCode',
	'companyEmail',
	'companyWebsite',
];

fieldsToBeExcluded.forEach((field) => {
	delete commonProfileFields[field];
});

export const profileWithoutCompanyFields = {
	...commonProfileFields,
};

export const profileFieldsPerTabs = {
	Profile: [
		profileFields.email,
		profileFields.firstName,
		profileFields.lastName,
		profileFields.role,
		profileFields.phoneNumber,
		profileFields.dataCollectionConsent,
	],
	'Company Profile': [
		profileFields.companyName,
		profileFields.companyVat,
		profileFields.companyMarketSector,
		profileFields.companyNumberEmployees,
		profileFields.companyEmail,
		profileFields.companyWebsite,
		profileFields.companyCountry,
		profileFields.companyState,
		profileFields.companyCity,
		profileFields.companyAddress,
		profileFields.companyPostalCode,
	],
	'Security Settings': [profileFields.password, profileFields.newPassword, profileFields.retypeNewPassword],
};
