import { gql } from 'apollo-boost';

export class TokenService {
	constructor(graphqlClient) {
		this.graphqlClient = graphqlClient;
	}

	async verifyTokenHash(token) {
		const verifyTokenHashResponse = await this.graphqlClient.query({
			query: gql`
        query ($token: String!) {
          verifyTokenHash(token: $token)
        }
      `,
			variables: {
				token: token,
			},
			fetchPolicy: 'no-cache',
		});

		return verifyTokenHashResponse.data.verifyTokenHash;
	}
}
