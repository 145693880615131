import React, { useContext } from 'react';
import moment from 'moment';
import { Alert } from '@syneto/compass-react';
import { StoreContext } from '../../../../../contexts/StoreContext';

export const StatusConnectionProblems = () => {
	const { state } = useContext(StoreContext);
	const { services } = state;

	return (
		<>
			{!services?.ras?.active ? (
				<Alert variant="warning" className="d-flex">
          The RAS service for your account has expired{' '}
					{services?.ras?.settings.endingPeriod
						? `on ${moment(services?.ras?.settings.endingPeriod).format('DD/MM/YYYY')}`
						: ''}
          . To continue using the service, please renew your service subscription.
				</Alert>
			) : null}

			<h6>Connection problems...</h6>
		</>
	);
};
