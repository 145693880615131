export const registrationFields = {
	email: 'email',
	password: 'password',
	checkPassword: 'checkPassword',
	firstName: 'firstName',
	lastName: 'lastName',
	role: 'role',
	phoneNumber: 'phoneNumber',
	isSupportContact: 'isSupportContact',
	companyName: 'companyName',
	companyVat: 'companyVat',
	companyMarketSector: 'companyMarketSector',
	companyNumberEmployees: 'companyNumberEmployees',
	companyCountry: 'companyCountry',
	companyState: 'companyState',
	companyCity: 'companyCity',
	companyAddress: 'companyAddress',
	companyPostalCode: 'companyPostalCode',
	companyEmail: 'companyEmail',
	companyWebsite: 'companyWebsite',
	subscribeNews: 'subscribeNews',
	subscribeProduct: 'subscribeProduct',
	subscribeSatisfactionSurveys: 'subscribeSatisfactionSurveys',
	dataCollectionConsent: 'dataCollectionConsent',
};
