import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Modal, Tooltip, Icon } from '@syneto/compass-react';
import * as yup from 'yup';
import { StoreContext } from '../../../contexts/StoreContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { userFields } from '../fields';
import { userValidationSchema } from '../validation';
import { Checkbox, InputField } from '../../../components/Form';
import { DeleteUserModal } from './DeleteUserModal';
import { Does } from '../../../components/Access/Does';
import InfoIcon from '../../../components/Form/InfoIcon';

export const EditUserModal = (props) => {
	const { show, onHide, user, loadUsersData } = props;

	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { userService, accessService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;

	const validationSchema = yup.object().shape(userValidationSchema);

	const { register, unregister, handleSubmit, setValue, formState: { errors } } = useForm({
		resolver: yupResolver(validationSchema),
	});

	const [globalRoles, setGlobalRoles] = useState(accessService.getUserGlobalRoles(user));
	const [isSupportContact, setIsSupportContact] = useState(user.profile.isSupportContact === true);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showWarningModal, setShowWarningModal] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		register(userFields.email);
		setValue(userFields.email, user.email);

		return () => {
			unregister(userFields.email);
		};
	}, []);

	useEffect(() => {
		const userRoles = accessService.getUserRoles().length ? accessService.getUserRoles() : null;

		// display warning modal if a regular user tries to remove his user manager role (user.admin)
		if (loggedInUser.email === user.email && userRoles.includes('user.admin') && !globalRoles.includes('user.admin')) {
			setShowWarningModal(true);
		}
	}, [accessService, globalRoles, loggedInUser.email, user.email]);

	const onSubmit = async (values) => {
		try {
			setSubmitting(true);
			await userService.updateUser({
				id: user.id,
				...values,
				subscribeNews: user.profile.subscribeToNews,
				subscribeProduct: user.profile.subscribeToProductNews,
				subscribeSatisfactionSurveys: user.profile.subscribeToSatisfactionSurveys,
				dataCollectionConsent: user.profile.dataCollectionConsent,
				globalRoles: globalRoles,
				isSupportContact: globalRoles.includes('fleet.admin') ? isSupportContact : false,
			});
			await loadUsersData();
			onHide();
			pushSuccessfulNotification('User updated successfully.');
		} catch (error) {
			pushDangerNotification('Failed to update user.');
			setSubmitting(false);
		}
	};

	const updateRoles = (role, checked) => {
		let existingRoles = [...globalRoles];

		if (!checked) {
			return setGlobalRoles(existingRoles.filter((existingRole) => { return existingRole !== role; }));
		}

		return setGlobalRoles([...existingRoles, role]);
	};

	return (
		<>
			<Modal show={show} onHide={onHide} slidingPanel={true}>
				<Modal.Header>
					<Modal.Title>Edit User</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<form>
						<InputField
							label="E-mail"
							defaultValue={user.email}
							disabled={true}
						/>
						<InputField
							label="First Name"
							errors={errors}
							defaultValue={user.profile.firstName}
							{...register(userFields.firstName)}
						/>
						<InputField
							label="Last Name"
							errors={errors}
							defaultValue={user.profile.lastName}
							{...register(userFields.lastName)}
						/>
						<InputField
							label="Role"
							errors={errors}
							defaultValue={user.profile.roleInTheCompany}
							{...register(userFields.role)}
						/>
						<InputField
							label="Phone"
							errors={errors}
							defaultValue={user.profile.phoneNumber}
							{...register(userFields.phoneNumber)}
						/>
						<Does
							loggedInUser
							havePermission="user.role.assign"
							onCompanyResource
							yes={() => {
								return (
									<Form.Group controlId="globalPermissions">
										<Form.Label>Global Permissions</Form.Label>
										<div className="mt-2">
											<Checkbox
												id="fleetManager"
												name="fleetManager"
												label="Fleet Manager"
												errors={errors}
												onChange={(event) => { return updateRoles('fleet.admin', event.target.checked); }}
												checked={globalRoles.includes('fleet.admin')}
												disabled={accessService.hasPermissionOnCompanyResource('account.company.update', user)}
											/>
											<Checkbox
												id="userManager"
												name="userManager"
												label="User Manager"
												errors={errors}
												onChange={(event) => { return updateRoles('user.admin', event.target.checked); }}
												checked={globalRoles.includes('user.admin')}
												disabled={accessService.hasPermissionOnCompanyResource('account.company.update', user)}
											/>
										</div>
									</Form.Group>
								);
							}}
						/>
						<Does
							user={user}
							havePermission="location.create"
							yes={() => {
								return (
									<>
										<p className="font-weight-bold">Options</p>
										<div className="d-flex justify-content-between">
											<div className="d-flex">
												<p>Syneto Support Contact</p>
												<InfoIcon
													placement="top"
													text="This user will be the point of contact for the Syneto Support Team in case of any system issues."
													tooltipStyle="mt-0 ms-2"
												/>
											</div>
											<div className="d-flex">
												<p className="text-muted me-2">{isSupportContact ? 'Active' : 'Inactive'}</p>
												<Form.Switch onChange={() => { return setIsSupportContact(!isSupportContact); }} checked={isSupportContact} />
											</div>
										</div>
									</>
								);
							}}
						/>
					</form>
				</Modal.Body>

				<Modal.Footer>
					<div
						style={{
							flex: 1,
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Does
							loggedInUser
							havePermission="user.remove"
							onCompanyResource
							yes={() => {
								return loggedInUser.id === user.id ? (
									<Tooltip placement="right" text="You cannot delete your own user">
										<div className="cannotShowTooltipsOnDisabledButtons">
											<Button appearance="danger" role="tertiary" disabled={true}>
												Delete
											</Button>
										</div>
									</Tooltip>
								) : (
									<>
										<Button appearance="danger" role="tertiary" onClick={() => { return setShowDeleteModal(true); }}>
											Delete
										</Button>
										{showDeleteModal && loggedInUser.id !== user.id && (
											<DeleteUserModal
												show={showDeleteModal}
												onHide={() => { return setShowDeleteModal(false); }}
												onHideEditModal={onHide}
												user={user}
												loadUsersData={loadUsersData}
											/>
										)}
									</>
								);
							}
							}
							no={() => { return <div />; }}
						/>
						<div>
							<Button className="me-3" role="secondary" onClick={onHide}>
								Cancel
							</Button>
							<Button onClick={handleSubmit(onSubmit)} disabled={submitting}>
								Update
							</Button>
						</div>
					</div>
				</Modal.Footer>

				<Modal
					show={showWarningModal}
					onHide={() => { return setShowWarningModal(false); }}
					title="Disable user management"
					content="If this service is disabled you will no longer be able to reactivate it without the help of your master user."
					actions={{
						primary: {
							label: 'Confirm',
							onClick: () => { return setShowWarningModal(false); },
							appearance: 'danger',
						},
						secondary: {
							label: 'Cancel',
							onClick: () => {
								updateRoles('user.admin', true);
								setShowWarningModal(false);
							},
						},
					}}
				/>
			</Modal>
		</>
	);
};

EditUserModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	user: PropTypes.shape({
		id: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		policies: PropTypes.array,
		profile: PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
			phoneNumber: PropTypes.string.isRequired,
			roleInTheCompany: PropTypes.string.isRequired,
			hasVirtualApplianceAccess: PropTypes.bool,
			subscribeToNews: PropTypes.bool.isRequired,
			subscribeToProductNews: PropTypes.bool.isRequired,
			subscribeToSatisfactionSurveys: PropTypes.bool.isRequired,
			dataCollectionConsent: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	loadUsersData: PropTypes.func.isRequired,
};
