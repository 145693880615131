import * as yup from 'yup';
import _ from 'lodash';
import { rasFields } from '../../../fields';

export const destinationsValidationSchema = {
	[rasFields.name]: yup
		.string()
		.required('Name is required')
		.test('test-can-be-stringified', 'Name contains not permitted characters', function (value) {
			const destination = {
				name: value,
				networkDestinations: [],
			};

			const destinationJSONString = JSON.stringify(destination);
			try {
				const parsedDestination = JSON.parse(destinationJSONString);
				return _.isEqual(destination, parsedDestination);
			} catch (error) {
				return false;
			}
		}),
	[rasFields.networkDestinations]: yup
		.string()
		.required('Network destination is required')
		.test('test-networkDestinations', 'Network destination is invalid', function (value) {
			const values = value.split(',');

			const validation =
				values.length &&
				values.filter((ip) => {
					ip = ip.trim();

					const IP =
						/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g;
					const IPPort =
						/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]):[0-9]+$/g;
					const IPNetmask =
						/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/g;
					const IPNetmaskPort =
						/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\/(\d){1,3}:[0-9]+$/g;
					const allPort = /^\*:[0-9]+$/g;
					const all = /^\*$/g;

					let isValidIP = IP.test(ip);
					let isValidIPPort = IPPort.test(ip);
					let isValidIPNetmask = IPNetmask.test(ip);
					let isValidIPNetmaskPort = IPNetmaskPort.test(ip);
					let isValidAllPort = allPort.test(ip);
					let isValidAll = all.test(ip);

					return (
						!isValidIP && !isValidIPPort && !isValidIPNetmask && !isValidIPNetmaskPort && !isValidAllPort && !isValidAll
					);
				});

			return !validation.length;
		}),
};
