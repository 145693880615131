import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Row, Col, Button } from '@syneto/compass-react';
import { StoreContext } from '../../../contexts/StoreContext';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { monitoringSecurityFields } from '../fields';
import { monitoringSecurityValidationSchema } from '../validation';
import { InputField } from '../../../components/Form';

const ChangeMonitoringPasswordTab = () => {
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;
	const { monitoringService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const [submitting, setSubmitting] = useState(false);

	const schema = yup.object().shape(monitoringSecurityValidationSchema);
	const { register, unregister, setValue, reset, handleSubmit, formState: { errors, isDirty } } = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			newPassword: '',
			retypeNewPassword: '',
		}
	});

	useEffect(() => {
		register(monitoringSecurityFields.companyId);

		return () => {
			unregister(monitoringSecurityFields.companyId);
		};
	}, []);

	useEffect(() => {
		if (loggedInUser?.company?.id && !isDirty) {
			setValue(monitoringSecurityFields.companyId, loggedInUser?.company?.id);
		}
	}, [loggedInUser, loggedInUser.company, isDirty]);

	const onSubmit = (values) => {
		const formData = {
			company_id: values.companyId,
			new_grafana_password: values.newPassword
		};

		setSubmitting(true);
		monitoringService.changeAdminPassword(formData)
			.then((messate) => {
				pushSuccessfulNotification(messate);
				reset();
			})
			.catch((errorMessage) => {
				pushDangerNotification(errorMessage);
			})
			.then(() => {
				setSubmitting(false);
				reset();
			});
	};

	return (
		<div id="change-monitoring-password-tab">
			<form>
				<Row>
					<Col>
						<h5>Monitoring Security Settings</h5>
					</Col>
				</Row>

				<Row className="mt-4">
					<Col xs={12} md={12} lg={3}>
						<span className="text-muted">Password</span>
					</Col>

					<Col>
						<Row>
							<Col>
								<InputField
									{...register(monitoringSecurityFields.newPassword)}
									label="New password"
									type="password"
									errors={errors}
								/>
							</Col>
							<Col>
								<InputField
									{...register(monitoringSecurityFields.retypeNewPassword)}
									label="Re-type new password"
									type="password"
									errors={errors}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<div className="mt-5 d-flex flex-row-reverse">
					<Button width={128} role="primary" onClick={handleSubmit(onSubmit)} disabled={submitting}>
						Change Password
					</Button>
				</div>
			</form>
		</div>
	);
};

export default ChangeMonitoringPasswordTab;
