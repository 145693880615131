import moment from 'moment';

class DateFormatterService {
	static convertMonthsToYears(objectWithDurationProp) {
		if (objectWithDurationProp >= 12) {
			return `${objectWithDurationProp / 12} ${objectWithDurationProp / 12 > 1 ? 'years' : 'year'}`;
		} else {
			return `${objectWithDurationProp} ${objectWithDurationProp > 1 ? 'months' : 'months'}`;
		}
	}

	static formatDateToSpecificFormat(objectWithDateProp, dateProperty, dateFormat) {
		let formatResult = null;
		let durationIndex = Object.keys(objectWithDateProp).indexOf(dateProperty);

		//TODO - add a regular expression to test date format
		if (durationIndex !== -1 && dateFormat) {
			formatResult = moment(objectWithDateProp[dateProperty]).format(dateFormat);
		} else {
			throw Error('Invalid date format or non-existent date property on object');
		}

		return formatResult;
	}
}

export default DateFormatterService;
