import axios from 'axios';
import { gql } from 'apollo-boost';

export class AuthenticationService {
	constructor(graphqlClient) {
		this.graphqlClient = graphqlClient;
	}

	#setAuthToken = (verifyToken) => {
		const BearerToken = `Bearer ${verifyToken}`;

		localStorage.setItem('authToken', BearerToken);
		axios.defaults.headers.common['Authorization'] = BearerToken;
	};

	authenticate = async (inputData) => {
		const authenticationResponse = await this.graphqlClient.query({
			query: gql`
        query ($email: String!, $password: String!) {
          authenticate(email: $email, password: $password) {
            mfaEnabled
            requiresMfaSetup
          }
        }
      `,
			variables: {
				email: inputData.email,
				password: inputData.password,
			},
			fetchPolicy: 'no-cache',
		});

		if (authenticationResponse.data.authenticate.requiresMfaSetup) {
			const setupMfaResponse = await this.setupMfa(inputData);
			return setupMfaResponse.data.setupMfa;
		}

		return null;
	};

	setupMfa = async (inputData) => {
		return await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($email: String!, $password: String!) {
          setupMfa(email: $email, password: $password) {
            qrCode
            secret
          }
        }
      `,
			variables: {
				email: inputData.email,
				password: inputData.password,
			},
			fetchPolicy: 'no-cache',
		});
	};

	verifyToken = async (inputData) => {
		const verifyTokenResponse = await this.graphqlClient.query({
			query: gql`
        query ($email: String!, $password: String!, $token: String!) {
          verifyToken(email: $email, password: $password, token: $token)
        }
      `,
			variables: {
				email: inputData.email,
				password: inputData.password,
				token: inputData.token,
			},
			fetchPolicy: 'no-cache',
		});
		
		this.clearLocalStorage();
		this.#setAuthToken(verifyTokenResponse.data.verifyToken);
		return verifyTokenResponse.data.verifyToken;
	};

	authenticateWithOneTimeToken = async (email, token) => {
		const authenticateWithOneTimeTokenResponse = await this.graphqlClient.query({
			query: gql`
        query ($email: String!, $oneTimeToken: String!) {
          authenticateWithOneTimeToken(email: $email, oneTimeToken: $oneTimeToken)
        }
      `,
			variables: {
				email: email,
				oneTimeToken: token,
			},
			fetchPolicy: 'no-cache',
		});

		this.clearLocalStorage();
		localStorage.setItem('impersonated', true);
		this.#setAuthToken(authenticateWithOneTimeTokenResponse.data.authenticateWithOneTimeToken);
		return authenticateWithOneTimeTokenResponse.data.authenticateWithOneTimeToken;
	};

	logout = async () => {
		const logoutResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation {
          logout
        }
      `,
			fetchPolicy: 'no-cache',
		});

		this.clearLocalStorage();
		return logoutResponse.data.logout;
	};

	clearLocalStorage = () => {
		localStorage.removeItem('impersonated');
		localStorage.removeItem('authToken');
	};
}
