import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, Icon } from '@syneto/compass-react';
import { InputField, Select } from '../../../components/Form';
import { buildSelectOptions } from '../../../helpers/select';
import { profileFields } from '../fields';
import { countries } from '../../../constants/countries';
import { provinces } from '../../../constants/provinces';
import { marketSectors } from '../../../constants/marketSectors';
import { employeesNumber } from '../../../constants/employeesNumber';
import { InputSelectCombo } from '../../../components/Form/InputSelectCombo';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { NotificationContext } from '../../../contexts/NotificationContext';

export const CompanyTab = (props) => {
	const { register, errors, clearErrors, setValue, loggedInUser } = props;
	const { userService } = useContext(ServiceContext);
	const { pushDangerNotification } = useContext(NotificationContext);

	const [showVatCheck, setShowVatCheck] = useState(false);
	const [showVatCheckModal, setShowVatCheckModal] = useState(false);
	const [country, setCountry] = useState(loggedInUser.company.country);

	const validateVAT = async (vatNumber) => {
		if (vatNumber === '' || !vatNumber) {
			setShowVatCheck(false);
			return;
		}

		try {
			const validateVATResult = await userService.validateVAT(vatNumber);

			if (validateVATResult === null) {
				return;
			}

			if (validateVATResult.isValid && validateVATResult.companyName) {
				setValue(profileFields.companyName, validateVATResult.companyName);
				setShowVatCheck(true);
			} else if (validateVATResult.isValid && !validateVATResult.companyName) {
				setShowVatCheck(true);
			} else {
				setShowVatCheck(false);
				setShowVatCheckModal(true);
			}
		} catch (error) {
			pushDangerNotification('Failed to fetch VAT data.');
		}
	};

	return (
		<>
			<Row>
				<Col>
					<h5>Company Information</h5>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col xs={12} md={12} lg={3}>
					<span className="text-muted">General Information</span>
				</Col>

				<Col>
					<Row>
						<Col>
							<InputField
								{...register(profileFields.companyName)}
								label="Name*"
								defaultValue={loggedInUser.company.name}
								errors={errors}
							/>
						</Col>
						<Col className="position-relative">
							<InputField
								{...register(profileFields.companyVat)}
								label="VAT number*"
								onBlur={(event) => { return validateVAT(event.target.value); }}
								defaultValue={loggedInUser.company.vat}
								errors={errors}
							/>
							{showVatCheck && <Icon name="fa fa-check" className="text-success" style={{ position: 'absolute', top: '35px', right: '25px' }} />}
						</Col>
					</Row>

					<Row>
						<Col>
							<Select
								{...register(profileFields.companyMarketSector)}
								label="Market sector*"
								emptyOption="Please select"
								options={buildSelectOptions(marketSectors)}
								defaultValue={loggedInUser.company.marketSector}
								errors={errors}
							/>
						</Col>
						<Col>
							<Select
								{...register(profileFields.companyNumberEmployees)}
								label="Number of employees*"
								emptyOption="Please select"
								options={buildSelectOptions(employeesNumber)}
								optionLabel={[]}
								defaultValue={loggedInUser.company.numberOfEmployees}
								errors={errors}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<InputField
								{...register(profileFields.companyEmail)}
								label="Email*"
								defaultValue={loggedInUser.company.email}
								errors={errors}
							/>
						</Col>
						<Col>
							<InputField
								{...register(profileFields.companyWebsite)}
								label="Website*"
								defaultValue={loggedInUser.company.website}
								errors={errors}
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col xs={12} md={12} lg={3}>
					<span className="text-muted">Location</span>
				</Col>

				<Col>
					<Row>
						<Col>
							<Select
								{...register(profileFields.companyCountry)}
								label="Country*"
								emptyOption="Please select"
								options={buildSelectOptions(countries)}
								defaultValue={country}
								errors={errors}
								onChange={(event) => {
									if (event.target.value !== country && (event.target.value === 'Italy' || country === 'Italy')) {
										setValue(profileFields.companyState, '', true);
									}

									setCountry(event.target.value);
								}}
							/>
						</Col>

						<Col>
							<InputSelectCombo
								{...register(profileFields.companyState)}
								label="Province*"
								showSelect={country === 'Italy'}
								emptyOption="Select province"
								options={buildSelectOptions(
									provinces,
									(province) => { return `${province.abbreviation} (${province.name})`; },
									(province) => { return province.abbreviation; }
								)}
								errors={errors}
								defaultValue={loggedInUser.company.province}
								onChange={(event) => {
									setValue(profileFields.companyState, event.target.value, true);
									clearErrors(profileFields.companyState);
								}}
								onInput={(event) => {
									if (event.target.value) {
										clearErrors(profileFields.companyState);
									}
								}}
							/>
						</Col>

						<Col>
							<InputField
								{...register(profileFields.companyCity)}
								label="City*"
								defaultValue={loggedInUser.company.city}
								errors={errors}
							/>
						</Col>
					</Row>
					<Row>
						<Col lg={8}>
							<InputField
								{...register(profileFields.companyAddress)}
								label="Address*"
								defaultValue={loggedInUser.company.address}
								errors={errors}
							/>
						</Col>
						<Col>
							<InputField
								{...register(profileFields.companyPostalCode)}
								label="Postal Code*"
								defaultValue={loggedInUser.company.postalCode}
								errors={errors}
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<Modal
				show={showVatCheckModal}
				onHide={() => { return setShowVatCheckModal(false); }}
				content="Please note that the VAT number is invalid."
				title="Warning"
				actions={{
					secondary: {
						label: 'Close',
						onClick: () => { return setShowVatCheckModal(false); },
					},
				}}
			/>
		</>
	);
};

CompanyTab.propTypes = {
	register: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	clearErrors: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
	loggedInUser: PropTypes.shape({
		id: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		profile: PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
			phoneNumber: PropTypes.string.isRequired,
			roleInTheCompany: PropTypes.string.isRequired,
			hasVirtualApplianceAccess: PropTypes.bool,
			subscribeToNews: PropTypes.bool.isRequired,
			subscribeToProductNews: PropTypes.bool.isRequired,
			subscribeToSatisfactionSurveys: PropTypes.bool.isRequired,
			dataCollectionConsent: PropTypes.bool.isRequired,
		}).isRequired,
		company: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			marketSector: PropTypes.string.isRequired,
			city: PropTypes.string.isRequired,
			province: PropTypes.string.isRequired,
			country: PropTypes.string.isRequired,
			address: PropTypes.string.isRequired,
			numberOfEmployees: PropTypes.string.isRequired,
			vat: PropTypes.string.isRequired,
			postalCode: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired,
			website: PropTypes.string.isRequired,
			locationIds: PropTypes.array.isRequired,
		}).isRequired,
	}).isRequired,
};
