import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@syneto/compass-react';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { ServiceContext } from '../../../contexts/ServiceContext';

export const DeleteContactModal = ({ contactToRemove, onHide, fetchAlertRecipients }) => {
	const { monitoringService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);

	const removeUser = () => {
		monitoringService.removeCompanyAlertEmailContact(contactToRemove.id)
			.then((successMessage) => {
				fetchAlertRecipients();
				pushSuccessfulNotification(successMessage);
				onHide();
			})
			.catch((errorMessage) => {
				pushDangerNotification(errorMessage);
			});
	};

	return (
		<Modal
			show={true}
			onHide={onHide}
			content={`Are you sure you want to delete the ${contactToRemove.email} from alert notification list?`}
			title="Confirm Delete"
			actions={{
				primary: {
					label: 'Delete',
					onClick: removeUser
				},
				secondary: {
					label: 'Cancel',
					onClick: onHide,
				},
			}}
		/>
	);
};

DeleteContactModal.propTypes = {
	contactToRemove: PropTypes.object.isRequired,
	fetchAlertRecipients: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired
};
