import React, { useContext } from 'react';
import { Button } from '@syneto/compass-react';
import { RasContext } from '../../../../contexts/RasContext';

import '../../style.scss';

export const RetryFetchRasDeployments = () => {
	const { getRasTableData } = useContext(RasContext);

	return (
		<div>
			<div className="no-ras-deployments my-5" />
			<h1 className="text-primary text-center mb-3">Failed to fetch RAS table data.</h1>
			<h6 className="text-muted text-center">An error has occured while fetching RAS table data. Please retry.</h6>
			<div className="d-flex justify-content-center mt-5">
				<Button role="secondary" onClick={getRasTableData}>
					Retry
				</Button>
			</div>
		</div>
	);
};
