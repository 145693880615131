import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, ComboBox, Icon } from '@syneto/compass-react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputField } from '../../../components/Form';
import { StoreContext } from '../../../contexts/StoreContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { buildComboBoxOptions } from '../../../helpers/select';
import { StandaloneSearchBox, GoogleMap, LoadScriptNext, Marker } from '@react-google-maps/api';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { DashboardContext } from '../../../contexts/DashboardContext';
import { Does } from '../../../components/Access/Does';

export const addLocationValidationSchema = yup.object().shape({
	name: yup.string().required('Cannot be empty'),
	address: yup.string().required('Cannot be empty'),
});

const libraries = ['places'];

export const AddLocationModal = (props) => {
	const { show, onHide } = props;
	const { getData, unassignedMachines, users, usersWithFleetAdminRole } = useContext(DashboardContext);
	const { locationService } = useContext(ServiceContext);

	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { register, handleSubmit, setValue, setFocus, formState:{ errors } } = useForm({
		resolver: yupResolver(addLocationValidationSchema),
	});

	const idsOfUsersWithFleetAdminRole = usersWithFleetAdminRole.map(
		(userWithFleetAdminRole) => { return userWithFleetAdminRole.id; }
	);

	const [selectedMachines, setSelectedMachines] = useState([]);
	const [selectedManagers, setSelectedManagers] = useState(
		buildComboBoxOptions(
			usersWithFleetAdminRole,
			(user) => { return `${user.profile.firstName} ${user.profile.lastName}`; },
			'id',
			undefined,
			idsOfUsersWithFleetAdminRole
		)
	);
	const [submitting, setSubmitting] = useState(false);

	const [mapPosition, setMapPosition] = useState({});
	const [searchedLocation, setSearchedLocation] = useState({});

	const [pinDragged, setPinDragged] = useState(false);
	const [showPinWarningModal, setShowPinWarningModal] = useState(false);

	useEffect(() => {
		register('machineSerialNumbers');
		register('managerUserIds');
	}, []);

	const onSubmit = async (values) => {
		try {
			if (pinDragged) { return setShowPinWarningModal(true); }
			values.addressCoordinates = mapPosition;

			setSubmitting(true);
			await locationService.addLocation({
				companyId: loggedInUser.company.id,
				...values,
			});
			await getData();
			onHide();
			pushSuccessfulNotification('Location created successfully.');
		} catch (error) {
			pushDangerNotification('Failed to create location.');
			setSubmitting(false);
		}
	};

	const onPlacesChanged = () => {
		const places = searchedLocation.getPlaces();

		if (places && places.length) {
			setMapPosition({
				lat: places[0].geometry.location.lat(),
				lng: places[0].geometry.location.lng(),
			});
			setValue('address', places[0].formatted_address);
		}
	};

	const onMarkerDragEnd = (e) => {
		const lat = e.latLng.lat();
		const lng = e.latLng.lng();

		setMapPosition({ lat, lng });
		setPinDragged(true);
	};

	const handleMultiChangeMachines = (selectedOptions) => {
		selectedOptions = selectedOptions || [];

		setValue(
			'machineSerialNumbers',
			selectedOptions.map((option) => { return option.value; })
		);
		setSelectedMachines(selectedOptions);
	};

	const handleMultiChangeManagers = (
		selectedOptions,
		{ action, removedValue } = { action: undefined, removedValue: undefined }
	) => {
		switch (action) {
			case 'remove-value':
			case 'pop-value':
				if (removedValue.isFixed) {
					return;
				}
				break;
			case 'clear':
				selectedOptions = selectedManagers.filter((option) => { return !option.isClearable; });
				break;
			default:
				break;
		}

		setValue(
			'managerUserIds',
			selectedOptions.filter((option) => { return option.isClearable; }).map((option) => { return option.value; })
		);
		setSelectedManagers(selectedOptions);
	};

	const focusInputAddress = () => { setFocus('address'); };

	return (
		<Modal slidingPanel={true} show={show} onHide={onHide}>
			<Modal.Header>
				<Modal.Title>New Location</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<form>
					<InputField {...register('name')} label="Location Name*" errors={errors} />

					<LoadScriptNext googleMapsApiKey="AIzaSyBUEuEuAKxRVphfHZoUbfDbmEAerBI2jKI" libraries={libraries}>
						<StandaloneSearchBox
							onLoad={(autocomplete) => { return setSearchedLocation(autocomplete); }}
							onPlacesChanged={onPlacesChanged}
						>
							<InputField
								{...register('address')}
								label="Address*"
								errors={errors}
							/>
						</StandaloneSearchBox>

						{mapPosition && mapPosition.lat ? (
							<GoogleMap
								center={mapPosition}
								zoom={18}
								mapContainerStyle={{
									width: '100%',
									height: 250,
									marginBottom: 20,
								}}
							>
								<Marker position={mapPosition} onDragEnd={(e) => { return onMarkerDragEnd(e); }} draggable={true} />
							</GoogleMap>
						) : (
							<div
								className="new-location-card d-flex align-items-center justify-content-center h-100 mb-3"
								onClick={focusInputAddress}
							>
								<div className="pt-4 pb-4 text-center">
									<Icon name="fa fa-map" color="#5b69e1" style={{ fontSize: '48px' }} />
									<h3 className="mt-4 mb-0 font-weight-bold text-accent">Please enter the address and hit enter</h3>
								</div>
							</div>
						)}
					</LoadScriptNext>

					<Does
						loggedInUser
						havePermission="location.appliance.assign"
						onAnyResource={true}
						yes={() => {
							return (
								<>
									<p>
										<b>Add machines</b>
									</p>
									<ComboBox
										options={buildComboBoxOptions(unassignedMachines, 'serialNumber', 'serialNumber')}
										value={selectedMachines}
										name="machineSerialNumbers"
										placeholder="Select machines"
										onChange={handleMultiChangeMachines}
										isMulti
										styles={{ menu: (styles) => { return { ...styles, zIndex: 999 }; } }}
									/>
								</>
							);
						}}
					/>

					<Does
						loggedInUser
						havePermission="location.manager.assign"
						onCompanyResource
						yes={() => {
							return (
								<>
									<p className="mt-3">
										<b>Managed by</b>
									</p>
									<ComboBox
										options={buildComboBoxOptions(
											users,
											(user) => { return `${user.profile.firstName} ${user.profile.lastName}`; },
											'id',
											undefined,
											idsOfUsersWithFleetAdminRole
										)}
										value={selectedManagers}
										name="managerUserIds"
										placeholder="Select managers"
										onChange={handleMultiChangeManagers}
										isMulti
										styles={{
											menu: (styles) => { return { ...styles, zIndex: 999 }; },
											multiValue: (base, state) => {
												return !state.data.isClearable ? { ...base, backgroundColor: 'gray' } : base;
											},
											multiValueLabel: (base, state) => {
												return !state.data.isClearable ? { ...base, backgroundColor: 'gray', paddingRight: 6 } : base;
											},
											multiValueRemove: (base, state) => {
												return !state.data.isClearable ? { ...base, display: 'none' } : base;
											},
										}}
									/>
								</>
							);
						}}
					/>

					{showPinWarningModal && (
						<Modal
							show={showPinWarningModal}
							onHide={() => { return setShowPinWarningModal(false); }}
							title="Warning"
							content="It looks like you have changed the location pin on the map. Please review if the address inserted
									in the address field is correct: The accuracy of this address is very important to us, as this is where any
									spare parts for your machines will be shipped."
							actions={{
								primary: {
									label: 'The address is correct',
									onClick: () => {
										setPinDragged(false);
										setShowPinWarningModal(false);
									},
								},
								secondary: {
									label: 'Modify the address',
									onClick: () => {
										setShowPinWarningModal(false);
										setTimeout(focusInputAddress, 300);
									},
								},
							}}
						/>
					)}
				</form>
			</Modal.Body>

			<Modal.Footer>
				<Button role="secondary" onClick={onHide}>
					Cancel
				</Button>

				<Button onClick={handleSubmit(onSubmit)} disabled={submitting}>
					Create Location
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

AddLocationModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
};
