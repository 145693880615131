import React, { useContext } from 'react';
import { Table, Icon, Badge } from '@syneto/compass-react';
import { RasContext } from '../../../../contexts/RasContext';
import { StoreContext } from '../../../../contexts/StoreContext';
import { RasStatusLabel } from '../../../../components/RasStatus/RasStatusLabel';
import { RasDeploymentDropdown } from '../RasDeploymentDropdown';

export const RASTable = (props) => {
	const { locationsWithRasDetails, onLocationSelect } = props;
	const { locationsWithoutRas, locationsWithRas, machinesRasInfo, selectedLocation, updateSelectedLocation } =
    useContext(RasContext);
	const { state } = useContext(StoreContext);
	const { services } = state;

	const onDropdownSelect = (eventKey) => {
		const selectedLocation = locationsWithoutRas.find((location) => { return location.name === eventKey; });
		onLocationSelect(selectedLocation);
	};

	const renderStatus = (cell, row) => {
		return services?.ras?.active ? (
			<RasStatusLabel rasInfo={machinesRasInfo.fulfilled[row.gatewayMachineSerialNumber] || { status: 'LOADING' }} />
		) : (
			<p className="text-danger">Service expired</p>
		);
	};

	const renderUsers = (cell, row) => {
		return (
			machinesRasInfo.fulfilled[row.gatewayMachineSerialNumber] &&
      machinesRasInfo.fulfilled[row.gatewayMachineSerialNumber].users &&
      machinesRasInfo.fulfilled[row.gatewayMachineSerialNumber].users.length
		);
	};

	const renderPublicEndpoint = (cell, row) => {
		const ras = locationsWithRas.find((l) => { return l.gatewayMachineSerialNumber === row.gatewayMachineSerialNumber; })?.rasConfig;
		return ras?.publicIpOrFqdn;
	};

	const renderLocationAccess = (cell, row) => {
		return row?.allowedDestinations?.length > 0
			? row?.allowedDestinations.map((destination, index) => { return <Badge key={index}>{destination}</Badge>; })
			: 'All traffic';
	};

	const expandRow = {
		renderer: (row) => {
			return (
				<div className="ms-3">
					<Table
						classes="collapsedSerenityEventsTable"
						data={row[row.gatewayMachineSerialNumber].users}
						noDataIndication="No users"
						keyField="email"
					>
						<Table.Col field="email">User</Table.Col>
						<Table.Col field="access" renderCell={renderLocationAccess}>
            Access
						</Table.Col>
					</Table>
				</div>
			); 
		},
		showExpandColumn: true,
		expandColumnPosition: 'right',
		expandHeaderColumnRenderer: () => {
			return <div />;
		},
		expandColumnRenderer: ({ expanded, rowKey }) => {
			const badge = document.getElementById(rowKey);

			if (expanded) {
				if (badge) {
					badge.classList.remove('badge-secondary');
					badge.classList.add('strong');
					badge.classList.add('badge-info');
				}

				return <Icon name="fas fa-caret-up" color="#4f5ed7" size="lg" style={{ cursor: 'pointer' }} />;
			} else {
				if (badge) {
					badge.classList.add('badge-secondary');
					badge.classList.remove('strong');
					badge.classList.remove('badge-info');
				}

				return <Icon name="fas fa-caret-down" size="lg" style={{ cursor: 'pointer' }} />;
			}
		},
	};

	return (
		<Table
			data={locationsWithRasDetails}
			keyField="id"
			search
			expandRow={expandRow}
			extra={
				<div style={{ display: 'flex', flex: 1 }}>
					<RasDeploymentDropdown label="Deploy on" onSelect={(eventKey) => { return onDropdownSelect(eventKey); }} />
				</div>
			}
			selectRow={{
				mode: 'radio',
				classes: 'selected',
				clickToSelect: true,
				hideSelectColumn: true,
				selected: [selectedLocation && selectedLocation.id],
				onSelect: (location) => { return updateSelectedLocation(location); },
			}}
		>
			<Table.Col field="name" align="left">
        Location
			</Table.Col>

			<Table.Col
				align="left"
				filter="select"
				filterOptions={[
					{ value: 'Inactive', label: 'Inactive' },
					{ value: 'Active', label: 'Active' },
					{ value: 'Deploying', label: 'Deploying' },
					{ value: 'Failed', label: 'Failed' },
					{
						value: 'CONNECTION_PROBLEMS',
						label: 'Connection Problems',
					},
				]}
				renderCell={renderStatus}
			>
        Deployment Status
			</Table.Col>

			<Table.Col field="users" align="left" renderCell={renderUsers}>
        Users
			</Table.Col>

			<Table.Col field="publicEndpoint" align="left" renderCell={renderPublicEndpoint}>
        Public endpoint
			</Table.Col>
		</Table>
	);
};
