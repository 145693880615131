import React, { createContext, useReducer } from 'react';

export const StoreContext = createContext();

export const StoreContextProvider = (props) => {
	const [state, dispatch] = useReducer((state, action) => {
		// we won't implement action types for now, we're just using the payload for a Redux store-like approach
		return {
			...state,
			...action.payload,
		};
	}, {});

	return (
		<StoreContext.Provider
			value={{
				state,
				dispatch,
			}}
		>
			{props.children}
		</StoreContext.Provider>
	);
};
