import React, { useContext, useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { StoreContext } from '../../../../contexts/StoreContext';
import { StartTourModal } from './components/startTourModal';
import { ServiceContext } from '../../../../contexts/ServiceContext';
import { DashboardContext } from '../../../../contexts/DashboardContext';

export const OnboardingTour = () => {
	const { unassignedMachines } = useContext(DashboardContext);
	const { userService } = useContext(ServiceContext);

	const { state, dispatch } = useContext(StoreContext);

	const [showModal, setShowModal] = useState(true);
	const [run, setRun] = useState(false);
	const [steps, setSteps] = useState(tourSteps);

	useEffect(() => {
		if (unassignedMachines && unassignedMachines.length) {
			setSteps([
				{
					content: (
						<h6 className="text-justify">
              These are all your registered Syneto machines. Since you haven't created any location yet, they are all
              unassigned.
						</h6>
					),
					placement: 'right',
					target: '.unassigned-machines',
					disableBeacon: true,
					hideCloseButton: true,
				},
				...tourSteps,
			]);
		}
	}, [unassignedMachines]);

	const { loggedInUser } = state;

	const handleJoyrideCallback = (data) => {
		// making this async won't work but it's ok, we can execute markWelcomeBannerAsSeen async
		const { status } = data;

		if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
			!loggedInUser.profile.seenWelcomeBanner && markWelcomeBannerAsSeen();
		}
	};

	const markWelcomeBannerAsSeen = async () => {
		await userService.markWelcomeBannerAsSeen(loggedInUser.profile.id);
		dispatch({
			payload: {
				loggedInUser: await userService.getLoggedInUser()
			},
		});
	};

	const startTour = () => {
		setShowModal(false);
		setRun(true);
	};

	const stopTour = () => { return setShowModal(false); };

	const skipTour = () => {
		!loggedInUser.profile.seenWelcomeBanner && markWelcomeBannerAsSeen();
	};

	return (
		<>
			<StartTourModal show={showModal} hide={stopTour} onStartTour={startTour} onSkipTour={skipTour} />

			<Joyride
				run={run}
				steps={steps}
				continuous={true}
				showProgress={true}
				showSkipButton={true}
				disableOverlayClose={true}
				disableScrolling={true}
				callback={handleJoyrideCallback}
				styles={{
					buttonNext: {
						backgroundColor: '#5b69e1',
						borderRadius: 0,
						color: '#fff',
					},
				}}
			/>
		</>
	);
};

const tourSteps = [
	{
		content: (
			<h6 className="text-justify">
        For each physical site of your company, create a location. Give it a simple name (eg. "Milano"), specify its
        address and the list of machines which run at that location.
			</h6>
		),
		placement: 'right',
		target: '.new-location-card',
		disableBeacon: true,
		hideCloseButton: true,
	},
	{
		content: (
			<h6 className="text-justify">
        After you create a new location, use the context menu to deploy the new Remote Access Service (RAS) on one of
        the machines assigned to it.
			</h6>
		),
		placement: 'right',
		target: '.new-location-card',
		disableBeacon: true,
		hideCloseButton: true,
	},
	{
		content: <h6 className="text-justify">See the full list of machines you activated in Central</h6>,
		placement: 'right',
		target: '.fa-server',
		hideCloseButton: true,
		styles: {
			options: {
				zIndex: 9999,
			},
		},
	},
	{
		content: <h6 className="text-justify">See any open SerenITy events</h6>,
		placement: 'right',
		target: '.fa-bell',
		hideCloseButton: true,
		styles: {
			options: {
				zIndex: 9999,
			},
		},
	},
	{
		content: <h6 className="text-justify">Create new users and give them granular service access.</h6>,
		placement: 'right',
		target: '.fa-users',
		hideCloseButton: true,
		styles: {
			options: {
				zIndex: 9999,
			},
		},
	},
];
