import { gql } from 'apollo-boost';

export class SerenityEventService {
	constructor(graphqlClient) {
		this.graphqlClient = graphqlClient;
	}

	async getSerenityEventsBySerialNumbers(serialNumbers) {
		const serenityEventsBySerialNumbersResponse = await this.graphqlClient.query({
			query: gql`
        query ($serialNumbers: [String]!) {
          serenityEventsBySerialNumbers(serialNumbers: $serialNumbers) {
            id
            occurrences
            recordTimestamp
            severity
            sourceTimestamp
            state {
              timestamp
              value
            }
            userState {
              timestamp
              value
            }
            content {
              body
              subject
            }
            emitter {
              serialNumber
              type
            }
          }
        }
      `,
			variables: {
				serialNumbers: serialNumbers,
			},
			fetchPolicy: 'no-cache',
		});

		return serenityEventsBySerialNumbersResponse.data.serenityEventsBySerialNumbers;
	}
}
