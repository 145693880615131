import * as yup from 'yup';
import { registrationFields } from '../Registration/fields';

export const userValidationSchema = {
	[registrationFields.firstName]: yup.string().required('Cannot be empty'),
	[registrationFields.lastName]: yup.string().required('Cannot be empty'),
	[registrationFields.phoneNumber]:
		yup
			.string()
			.required('Cannot be empty')
			.matches(/^\+{0,1}\d*$/, 'Phone number is invalid'),
};
