import React, { forwardRef } from 'react';
import { Form } from '@syneto/compass-react';

export const Textarea = forwardRef((props, ref) => {
	const { label, icon, id, required } = props;
	const { name, placeholder, style, className } = props;
	const { disabled, readOnly, errors, input, defaultValue, onBlur } = props;

	return (
		<Form.Group className="mb-3" controlId={id}>
			{label ? (
				<Form.Label>
					{label}
					{required ? '*' : null} {icon ? icon : null}
				</Form.Label>
			) : null}

			<Form.Control
				as="textarea"
				name={name}
				placeholder={placeholder}
				ref={ref}
				style={style}
				className={className}
				disabled={disabled}
				readOnly={readOnly}
				onBlur={onBlur}
				defaultValue={defaultValue}
				isInvalid={!!errors[name]}
				{...input}
			/>

			<Form.Control.Feedback type="invalid">{errors[name] && errors[name].message}</Form.Control.Feedback>
		</Form.Group>
	);
});
