export const monitoringSecurityFields = {
	companyId: 'companyId',
	newPassword: 'newPassword',
	retypeNewPassword: 'retypeNewPassword'
};

export const emailNotificationsFields = {
	companyId: 'company_id',
	email: 'email',
	firstName: 'first_name',
	lasttName: 'last_name',
};
