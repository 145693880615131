import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { InputField } from '../../../components/Form';
import { MfaFields } from '../../Authentication/components/MfaFields';
import { slugify } from '../../../helpers/string';
import { rasFields } from '../fields';

export const identityVerificationValidationSchema = yup.object().shape({
	[rasFields.password]: yup.string().required('Cannot be empty').min(6, 'The password has to have minimum 6 characters'),
});

export const IdentityVerificationModal = (props) => {
	const { show, onHide, user, location } = props;
	const { rasService, machineService } = useContext(ServiceContext);
	const { pushDangerNotification } = useContext(NotificationContext);

	const [disabled, setDisabled] = useState(true);
	const [token, setToken] = useState('');
	const [password, setPassword] = useState('');
	const [submitting, setSubmitting] = useState(false);

	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(identityVerificationValidationSchema)
	});

	useEffect(() => {
		if (!show) {
			setSubmitting(false);
		}
	}, [show]);

	useEffect(() => {
		setDisabled(submitting || token.length !== 6 || password === '');
	}, [submitting, token, password]);

	const downloadBase64File = (contentType, base64Data, fileName) => {
		const linkSource = `data:${contentType};base64,${base64Data}`;
		const downloadLink = document.createElement('a');
		downloadLink.href = linkSource;
		downloadLink.download = fileName;
		downloadLink.click();
	};

	const onSubmit = async (values) => {
		try {
			setSubmitting(true);
			const base64RasConfigurationFile = await rasService.downloadRasConfigurationFile(
				user.email,
				values.password,
				token,
				location.gatewayMachineSerialNumber,
				machineService.getGatewayMachineOsVersion(location)
			);
			downloadBase64File(
				'text/plain',
				base64RasConfigurationFile,
				`${slugify(`${user.email.split('@')[0]} ${location.name}`)}.ovpn`
			);
			onHide();
			setSubmitting(false);
		} catch (error) {
			pushDangerNotification(error.message.replace(/GraphQL error:/g, '').trim());
			setSubmitting(false);
		}
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			content={
				<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
					<p>In order to download the configuration file, please enter your password and your Syneto Central MFA PIN</p>
					<InputField 
						{...register(rasFields.password)} 
						label="Password" 
						type="password" 
						errors={errors}
						onChange={(event) => { 
							setPassword(event.target.value);
							return setValue(rasFields.password, event.target.value); 
						}}
						autoFocus 
					/>
					<div className="form-group">
						<label className="form-label">MFA PIN</label>
						<div className="d-flex justify-content-between">
							<MfaFields setToken={setToken} />
						</div>
					</div>
					{/* The hack below (hidden submit button) was done because we wanted to submit the form when hitting enter but the action button is outside the form, in the modal footer */}
					{!disabled && <Button type="submit" className="d-none" />}
				</form>
			}
			title="Identity Verification"
			actions={{
				primary: {
					label: 'Verify & Download',
					onClick: handleSubmit(onSubmit),
					disabled: disabled,
				},
				secondary: {
					label: 'Cancel',
				},
			}}
		/>
	);
};

IdentityVerificationModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};
