import * as yup from 'yup';
import { monitoringSecurityFields } from './fields';

export const monitoringSecurityValidationSchema = {
	[monitoringSecurityFields.newPassword]: yup
		.string()
		.min(6, 'Must have at least 6 characters'),
	[monitoringSecurityFields.retypeNewPassword]: yup.string()
		.test([monitoringSecurityFields.newPassword], 'Passwords must match', function (val) {
			const { newPassword } = this.parent;
			return newPassword.trim() === val.trim();
		})
};
