import React, { useState } from 'react';
import './style.scss';
import { MfaForm } from './components/MfaForm';
import { AuthForm } from './components/AuthForm';

export const Authentication = () => {
	const [mfaDetails, setMfaDetails] = useState(undefined);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const renderContent = () => {
		switch (mfaDetails) {
			case null: {
				return <MfaForm email={email} password={password} mfaDetails={mfaDetails} setMfaDetails={setMfaDetails} />;
			}
			case undefined: {
				return <AuthForm setMfaDetails={setMfaDetails} setEmail={setEmail} setPassword={setPassword} />;
			}
			default: {
				return <MfaForm email={email} password={password} mfaDetails={mfaDetails} setMfaDetails={setMfaDetails} />;
			}
		}
	};

	return (
		<div className="authentication min-vh-100">
			<div className="container d-flex justify-content-center">{renderContent()}</div>
		</div>
	);
};
