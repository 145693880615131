import React from 'react';
import { ComboBox } from '@syneto/compass-react';

export const ComboxBoxField = (props) => {
	const { placeholder, name, options, value, defaultValue, onChange, isMulti, isClearable, errors } = props;

	const selectStyles = { menu: (styles) => { return { ...styles, zIndex: 999 }; } };

	return (
		<div style={{ margin: '1rem 0' }}>
			<ComboBox
				placeholder={placeholder}
				name={name}
				options={options}
				value={value}
				defaultValue={defaultValue}
				onChange={onChange}
				isMulti={isMulti}
				isClearable={isClearable}
				styles={selectStyles}
			/>

			<p className="my-2" style={{ fontSize: '80%', color: '#e45664' }}>
				{errors && errors[name] && errors[name].message}
			</p>
		</div>
	);
};
