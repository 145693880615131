import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputField } from '../../../components/Form';

export const MfaFields = (props) => {
	const { setToken } = props;

	const { register, setValue } = useForm();

	const [fieldValues, setFieldValues] = useState([]);
	const [indexField, setIndexField] = useState(0);
	const [currentInputField, setCurrentInputField] = useState(document.getElementsByName(`digit${indexField}`));
	const [pastedCode, setPastedCode] = useState([]);

	useEffect(() => {
		setCurrentInputField(document.getElementsByName(`digit${indexField}`));
	}, [indexField]);

	useEffect(() => {
		currentInputField.item(0).focus();
		currentInputField.item(0).select();
		deleteDigits();

		fieldValues.length > 0 &&
			setToken(Object.values(fieldValues).reduce((accumulator, currentValue) => { return accumulator + currentValue; }));
	}, [currentInputField]);

	useEffect(() => {
		if (pastedCode && pastedCode.length) {
			// this is used just for the visually complete the input fields
			pastedCode.forEach((item, index) => { return setValue(`digit${index}`, item); });
		}

		setToken(pastedCode.join(''));
	}, [pastedCode, setToken, setValue]);

	const deleteDigits = () => {
		currentInputField.item(0).onkeyup = (e) => {
			if (e.keyCode === 8 || e.keyCode === 46) {
				indexField > 0 ? setIndexField(indexField - 1) : setIndexField(5);
			}
		};
	};

	const limitToDigits = (e) => {
		const newFieldValues = [...fieldValues];

		if (e.target.validity.patternMismatch) {
			newFieldValues[indexField] = '';
		} else {
			newFieldValues[indexField] = e.target.value;
			if (e.target.value) {
				indexField < 5 ? setIndexField(indexField + 1) : setIndexField(0);
			}
		}

		setFieldValues(newFieldValues);
	};

	const pasteNumbers = (event) => {
		event.clipboardData.items[0].getAsString((text) => {
			const code = text.split('').map((char) => {
				if (Number.isInteger(Number(char))) { return Number(char); }

				return '';
			});

			setPastedCode(code);
		});
	};

	return [...Array(6).keys()].map((key) => {
		return (
			<InputField
				{...register(`digit${key}`)}
				key={key}
				className="digitField rounded"
				maxLength={1}
				pattern="[0-9]"
				onInput={limitToDigits}
				onFocus={() => { return setIndexField(key); }}
				onPaste={pasteNumbers}
			/>
		);
	});
};
