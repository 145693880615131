import * as yup from 'yup';
import { registrationFields } from '../Registration/fields';

export const invitationRegistrationValidationSchema = {
	[registrationFields.firstName]: yup.string().required('Cannot be empty'),
	[registrationFields.lastName]: yup.string().required('Cannot be empty'),
	[registrationFields.password]: yup
		.string()
		.required('Cannot be empty')
		.min(6, 'The password has to have minimum 6 characters'),
	[registrationFields.checkPassword]: yup
		.string()
		.required('Cannot be empty')
		.test('passwords-match', 'Passwords do not match', function (value) {
			return this.parent.password === value;
		}),
	[registrationFields.phoneNumber]: yup.string().matches(/^\+{0,1}\d*$/, 'Phone number is invalid'),
	[registrationFields.subscribeNews]: yup.bool(),
	[registrationFields.subscribeProduct]: yup.bool(),
	[registrationFields.subscribeSatisfactionSurveys]: yup.bool(),
	[registrationFields.dataCollectionConsent]: yup
		.bool()
		.required('You must agree with data being collected and stored')
		.oneOf([true], 'You must agree with data being collected and stored'),
};
