import { profileFields, profileWithoutCompanyFields } from './fields';
import { registrationValidationSchema } from '../Registration/validation';
import * as yup from 'yup';

const commonValidationSchema = { ...registrationValidationSchema };
Object.keys(commonValidationSchema).forEach((field) => {
	if (!profileFields[field]) {
		delete commonValidationSchema[field];
	}
});

export const profileValidationSchema = {
	...commonValidationSchema,
	[profileFields.password]: yup
		.string()
		.min(6, 'Must have at least 6 characters')
		.nullable()
		.transform((value, originalValue) => { return (originalValue.trim() === '' ? null : value); })
		.test([profileFields.password], 'Cannot be empty', function (val) {
			const { newPassword } = this.parent;
			return !(newPassword && !val);
		}),
	[profileFields.newPassword]: yup.string()
		.test([profileFields.newPassword], 'Cannot be empty', function (val) {
			const { password } = this.parent;
			return !(password && !val);
		})
		.test([profileFields.newPassword], 'Must have at least 6 characters', function (val) {
			const { password } = this.parent;
			return !(password && val.trim().length < 6);
		}),
	[profileFields.retypeNewPassword]: yup.string()
		.test([profileFields.newPassword], 'Passwords must match', function (val) {
			const { newPassword } = this.parent;
			return newPassword.trim() === val.trim();
		})
};

const commonProfileValidationSchema = { ...profileValidationSchema };
Object.keys(commonProfileValidationSchema).forEach((field) => {
	if (!profileWithoutCompanyFields[field]) {
		delete commonProfileValidationSchema[field];
	}
});

export const profileWithoutCompanyValidationSchema = {
	...commonProfileValidationSchema,
};
