import { gql } from 'apollo-boost';

export class PolicyService {
	constructor(graphqlClient) {
		this.graphqlClient = graphqlClient;
	}

	async getPoliciesByCompanyId(companyId) {
		const policiesByCompanyIdResponse = await this.graphqlClient.query({
			query: gql`
        query policiesByCompanyId($companyId: String!) {
          policiesByCompanyId(companyId: $companyId) {
            resource {
              id
              type
              referenceId
            }
            bindings {
              userIds
              role {
                id
                name
                permissions {
                  id
                  name
                }
              }
            }
          }
        }
      `,
			variables: {
				companyId: companyId,
			},
			fetchPolicy: 'no-cache',
		});

		return policiesByCompanyIdResponse.data.policiesByCompanyId;
	}
}
