import React from 'react';
import { Container, Card } from '@syneto/compass-react';
import './style.scss';

export const PageNotFound = () => {
	return (
		<Container className="h-100">
			<Card
				className="px-3 mt-2 mb-4 h-100"
				content={
					<div>
						<div className="page-not-found my-5" />
						<h1 className="text-primary text-center">404 Page Not Found</h1>
						<h6 className="text-muted text-center">Uh-oh ! It seems like something got misplaced.</h6>
						<h6 className="text-muted text-center">
              If you see this page, the link that brought you here might no longer be valid. Pardon our dust.
						</h6>
						<h6 className="text-muted text-center">
              Click <a href={'/'}>here</a> to go back to the main page.
						</h6>
					</div>
				}
			/>
		</Container>
	);
};
