import React, { useContext, useEffect, useState } from 'react';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { StoreContext } from '../../../contexts/StoreContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { Table, Button } from '@syneto/compass-react';
import { AddEmailModal } from './AddEmailModal';
import { DeleteContactModal } from './DeleteContactModal';

const EmailNotificationsTab = () => {
	const { monitoringService } = useContext(ServiceContext);
	const { pushDangerNotification } = useContext(NotificationContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;
	const [recipientsList, setRecipientsList] = useState([]);
	const [showAddEmailModal, setShowAddEmailModal] = useState(false);
	const [contactToRemove, setContactToRemove] = useState(null);

	useEffect(() => {
		fetchAlertRecipients();
	}, []);

	const fetchAlertRecipients = () => {
		monitoringService.getCompanyAlertEmailContact(loggedInUser?.company?.id)
			.then((response) => {
				setRecipientsList(response);
			})
			.catch((errorMessage) => {
				pushDangerNotification(errorMessage);
			});
	};

	const renderAddEmailButton = () => {
		return (
			<div className='d-flex flex-grow-1'>
				<Button role="secondary" onClick={() => { return setShowAddEmailModal(true); }}>
					Add Contact
				</Button>
			</div>
		);
	};

	const renderActions = (cell, row) => {
		return (
			<Button appearance="danger" onClick={() => { return setContactToRemove(row); }}>
				Remove User
			</Button>
		);
	};

	const renderTable = () => {
		return (
			<Table
				data={recipientsList}
				keyField="id"
				search
				extra={renderAddEmailButton()}
			>
				<Table.Col field="email" align="left">
					Email
				</Table.Col>
				<Table.Col field="first_name" align="left">
					First Name
				</Table.Col>
				<Table.Col field="last_name" align="left">
					Last Name
				</Table.Col>
				<Table.Col width={130} align="left" renderCell={renderActions} />
			</Table>
		);
	};

	const renderNoDataPage = () => {
		return (
			<div className='d-flex flex-column align-items-center'>
				<div className="w-100 no-recipients-data" />
				<h1 className="text-primary text-center mb-3">Email Alert Notifications</h1>
				<p className="text-muted text-center">
					No email addresses have been configured for alert notifications.
				</p>
				{renderAddEmailButton()}
			</div>
		);
	};

	return (
		<div id="email-notifications-tab">
			{
				recipientsList.length <= 0
					? renderNoDataPage()
					: renderTable()
			}
			{showAddEmailModal && <AddEmailModal show={showAddEmailModal} onHide={() => { return setShowAddEmailModal(false); }} fetchAlertRecipients={fetchAlertRecipients} />}
			{!!contactToRemove && <DeleteContactModal contactToRemove={contactToRemove} fetchAlertRecipients={fetchAlertRecipients} onHide={() => { return setContactToRemove(null); }} />}
		</div>
	);
};

export default EmailNotificationsTab;
