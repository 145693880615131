import axios from 'axios';
import queryString from 'query-string';
const https = require('https');

export const getDAS = async () => {
	try {
		const axiosHyper = axios.create({
			baseURL: 'https://ultra220-vsa',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
				'X-Requested-With': 'XMLHttpRequest',
				httpsAgent: new https.Agent({ rejectUnauthorized: false }),
			},
		});

		axiosHyper.defaults.httpsAgent = new https.Agent({
			rejectUnauthorized: false,
		});

		const res = await axiosHyper.post(
			'/cake/login.json',
			queryString.stringify({
				method: 'credentials',
				username: 'admin',
				password: 'admin',
			})
		);
		
		return res;
	} catch (e) {
		console.log(e.message);
	}
};
