import { registrationFields } from '../Registration/fields';

const commonFields = { ...registrationFields };
const fieldsToBeExcluded = [
	'password',
	'checkPassword',
	'companyName',
	'companyVat',
	'companyMarketSector',
	'companyNumberEmployees',
	'companyCountry',
	'companyState',
	'companyCity',
	'companyAddress',
	'companyPostalCode',
	'companyEmail',
	'companyWebsite',
	'subscribeNews',
	'subscribeProduct',
	'subscribeSatisfactionSurveys',
	'dataCollectionConsent',
];

fieldsToBeExcluded.forEach((field) => {
	delete commonFields[field];
});

export const userFields = {
	...commonFields,
};
