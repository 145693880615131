import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { StoreContext } from '../../contexts/StoreContext';
import noAccessUser from '../../../src/assets/img/no-access-user.svg';
import { NotificationContext } from '../../contexts/NotificationContext';
import { Loading } from '../../components/Loading';
import { ServiceContext } from '../../contexts/ServiceContext';

export const Welcome = () => {
	const { userService, accessService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { pushDangerNotification } = useContext(NotificationContext);
	const { loggedInUser, services } = state;

	const [masterAccountName, setMasterAccountName] = useState('');
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		loadWelcomeData();
	}, []);

	const loadWelcomeData = async () => {
		try {
			setMasterAccountName(await userService.getMasterAccountName(loggedInUser.company.id));
			setLoading(false);
		} catch (error) {
			pushDangerNotification('Failed to fetch data.');
			setLoading(false);
		}
	};

	const shouldRedirectToDashboardPage = () => {
		return (
			accessService.hasPermissionOnAnyResource('location.list') ||
      accessService.hasPermissionOnAnyResource('appliance.list') ||
      accessService.hasPermissionOnAnyResource('user.list') ||
      (accessService.hasPermissionOnAnyResource('ras.list') && services?.ras?.active)
		);
	};

	if (shouldRedirectToDashboardPage()) {
		return <Navigate to="/dashboard" />;
	}

	return loading ? (
		<Loading />
	) : (
		<div className="h-100" style={{ marginTop: '90px' }}>
			<p className="font-size-20 text-muted d-flex justify-content-center">Welcome {loggedInUser.profile.firstName},</p>
			<p className="text-muted d-flex justify-content-center">It looks like you do not have access to any service.</p>
			<p className="text-muted d-flex justify-content-center">
        To fix this talk to your service administrator
				{masterAccountName ? `, ${masterAccountName},` : ''} to grant you access
			</p>
			<p className="text-muted d-flex justify-content-center">to one or more of the Syneto Services.</p>
			<div className="d-flex justify-content-center mt-5">
				<img src={noAccessUser} alt="" />
			</div>
		</div>
	);
};
