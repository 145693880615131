import { gql } from 'apollo-boost';

export class LocationService {
	constructor(graphqlClient, loggedInUser, accessService) {
		this.graphqlClient = graphqlClient;
		this.loggedInUser = loggedInUser;
		this.accessService = accessService;
	}

	async addLocation(inputData) {
		const addLocationResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($location: AddLocationInput!) {
          addLocation(location: $location)
        }
      `,
			variables: {
				location: {
					name: inputData.name,
					address: inputData.address,
					addressCoordinates: inputData.addressCoordinates,
					machineSerialNumbers: inputData.machineSerialNumbers || [],
					managerUserIds: inputData.managerUserIds || [],
					companyId: inputData.companyId,
				},
			},
			fetchPolicy: 'no-cache',
		});

		return addLocationResponse.data.addLocation;
	}

	async updateLocation(inputData) {
		const updateLocationResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($location: UpdateLocationInput!) {
          updateLocation(location: $location)
        }
      `,
			variables: {
				location: {
					name: inputData.name,
					address: inputData.address,
					addressCoordinates: inputData.addressCoordinates,
					machineSerialNumbers: inputData.machineSerialNumbers || [],
					managerUserIds: inputData.managerUserIds || [],
					locationId: inputData.locationId,
				},
			},
			fetchPolicy: 'no-cache',
		});

		return updateLocationResponse.data.updateLocation;
	}

	async removeLocation(companyId, locationId) {
		const removeLocationResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($companyId: String!, $locationId: String!) {
          removeLocation(companyId: $companyId, locationId: $locationId)
        }
      `,
			variables: {
				companyId: companyId,
				locationId: locationId,
			},
			fetchPolicy: 'no-cache',
		});

		return removeLocationResponse.data.removeLocation;
	}

	async getLocationsByCompanyId(companyId) {
		const locationsByCompanyIdResponse = await this.graphqlClient.query({
			query: gql`
        query locationsByCompanyId($companyId: String!) {
          locationsByCompanyId(companyId: $companyId) {
            id
            name
            address
            addressCoordinates {
              lat
              lng
            }
          }
        }
      `,
			variables: {
				companyId: companyId,
			},
			fetchPolicy: 'no-cache',
		});

		return locationsByCompanyIdResponse.data.locationsByCompanyId;
	}

	async getLocationsByIds(ids) {
		const locationsByIdsResponse = await this.graphqlClient.query({
			query: gql`
        query ($ids: [String]!) {
          locationsByIds(ids: $ids) {
            id
            name
            address
            addressCoordinates {
              lat
              lng
            }
          }
        }
      `,
			variables: {
				ids: ids,
			},
			fetchPolicy: 'no-cache',
		});

		return locationsByIdsResponse.data.locationsByIds;
	}

	async getLocationsByPermission(permission) {
		try {
			let locations = [];

			if (this.accessService.hasPermissionOnCompanyResource('location.create')) {
				locations = await this.getLocationsByCompanyId(this.loggedInUser.company.id);
			} else {
				let resources = await this.accessService.findResourcesByType('location');
				resources = resources.filter((resource) => { return this.accessService.hasPermissionOnResource(permission, 'location', resource.referenceId); }
				);
				const locationIds = resources.map((resource) => { return resource.referenceId; });

				if (locationIds.length) {
					locations = await this.getLocationsByIds(locationIds);
				}
			}

			return locations;
		} catch (e) {
			console.log(e);
		}
	}

	async getLocations() {
		try {
			return (await this.getLocationsByPermission('location.list')) || [];
		} catch (e) {
			console.log(e);
		}
	}

	async getRasLocations() {
		return await this.getLocationsByPermission('ras.list');
	}

	async getLocationsRenewalsSettings() {
		try {
			const locationsRenewalsSettings = await this.graphqlClient.query({
				query: gql`
          query getLocationsRenewalsSettings {
            renewalsSettings {
              expiringIntervalSettingForCustomer
            }
          }
        `,
				fetchPolicy: 'no-cache',
			});

			return locationsRenewalsSettings.data.renewalsSettings;
		} catch (e) {
			console.log(e);
		}
	}

	async attachDependenciesToLocations(machines, policies, locations) {
		return locations.map((location) => {
			location.machines = machines.filter((machine) => {
				return machine.locationId === location.id;
			});

			location.gatewayMachineSerialNumber = '';
			const gatewayMachine = location.machines.find((machine) => { return machine.isRasGateway === true; });
			if (gatewayMachine) {
				location.gatewayMachineSerialNumber = gatewayMachine.serialNumber;
			}

			location.managerUserIds = [];
			const policy = policies.find(
				(policy) => { return policy.resource.type === 'location' && policy.resource.referenceId === location.id; }
			);
			if (policy) {
				const fleetEditorBinding = policy.bindings.find((binding) => { return binding.role.name === 'fleet.editor'; });
				if (fleetEditorBinding) {
					location.managerUserIds = fleetEditorBinding.userIds;
				}
			}

			return location;
		});
	}
}
