import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { StoreContext } from '../../../contexts/StoreContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { Modal } from '@syneto/compass-react';
import { InputField } from '../../../components/Form';
import PropTypes from 'prop-types';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { rEmail } from '../../../constants/validation';
import { emailNotificationsFields } from '../fields';

export const addEmailValidationSchema = yup.object().shape({
	email: yup.string().required('E-mail is required').trim().matches(rEmail, 'Email format is incorrect'),
});

export const AddEmailModal = ({ show, onHide, fetchAlertRecipients }) => {
	const { monitoringService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { register, unregister, setValue, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(addEmailValidationSchema),
	});
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		register(emailNotificationsFields.companyId);

		return () => {
			unregister(emailNotificationsFields.companyId);
		};
	}, []);

	useEffect(() => {
		if (loggedInUser?.company?.id) {
			setValue(emailNotificationsFields.companyId, loggedInUser?.company?.id);
		}
	}, [loggedInUser, loggedInUser.company]);

	const onSubmit = (values) => {
		setSubmitting(true);
		
		monitoringService.addCompanyAlertEmailContact(values)
			.then(() => {
				pushSuccessfulNotification('Successfully added to notification email list.');
				fetchAlertRecipients();
				onHide();
			})
			.catch(() => {
				pushDangerNotification('Failed to add to notification email list.');
			})
			.then(() => {
				setSubmitting(false);
			});
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			slidingPanel={true}
			content={
				<form>
					<InputField {...register(emailNotificationsFields.email)} label="Email*" errors={errors} />
					<InputField {...register(emailNotificationsFields.firstName)} label="First Name" errors={errors} />
					<InputField {...register(emailNotificationsFields.lasttName)} label="Last Name" errors={errors} />
				</form>
			}
			title="Add Email"
			actions={{
				primary: {
					label: 'Add Email',
					onClick: handleSubmit(onSubmit),
					disabled: submitting,
				},
				secondary: {
					label: 'Cancel',
					onClick: onHide,
				},
			}}
		/>
	);
};

AddEmailModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	fetchAlertRecipients: PropTypes.func.isRequired 
};
