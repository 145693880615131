import React, { useContext } from 'react';
import _ from 'lodash';
import { Button, Alert } from '@syneto/compass-react';
import moment from 'moment';
import { Loading } from '../../../../../components/Loading';
import { Does } from '../../../../../components/Access/Does';
import { MachineContext } from '../../../../../contexts/MachineContext';
import { StoreContext } from '../../../../../contexts/StoreContext';

export const StatusActive = (props) => {
	const { rasConfiguration, selectedLocation, onLocationSelect } = props;

	const { machinesWithOsVersionAtLeast470 } = useContext(MachineContext);
	const { state } = useContext(StoreContext);
	const { services } = state;

	const renderEditButton = () => {
		return (
			<Button role="tertiary" onClick={() => { return onLocationSelect(selectedLocation); }} disabled={!services?.ras?.active}>
        Edit
			</Button>
		);
	};

	return rasConfiguration && rasConfiguration?.fqdn ? (
		<>
			{!services?.ras?.active ? (
				<Alert variant="warning" className="d-flex">
          The RAS service for your account has expired{' '}
					{services?.ras?.settings.endingPeriod
						? `on ${moment(services?.ras?.settings.endingPeriod).format('DD/MM/YYYY')}`
						: ''}
          . To continue using the service, please renew your service subscription.
				</Alert>
			) : null}

			<div className="d-flex justify-content-between mb-4">
				<h5 className="d-flex align-self-center">RAS Settings</h5>
				<Does
					loggedInUser
					havePermission="ras.update"
					onCompanyResource
					yes={renderEditButton}
					no={() => {
						return (
							<Does
								loggedInUser
								havePermission="ras.update"
								onResource={{
									type: 'location',
									referenceId: selectedLocation?.id,
								}}
								yes={renderEditButton}
							/>
						); 
					}}
				/>
			</div>
			<div className="d-flex justify-content-between mb-2">
				<p className="text-muted">Gateway Device</p>
				<p>{selectedLocation && selectedLocation.gatewayMachineSerialNumber}</p>
			</div>
			<div className="d-flex justify-content-between mb-2">
				<p className="text-muted">Hostname</p>
				<p>{rasConfiguration.fqdn}</p>
			</div>
			<div className="d-flex justify-content-between mb-2">
				<p className="text-muted">Public Endpoint</p>
				<p>{rasConfiguration.publicIpOrFqdn}</p>
			</div>
			{machinesWithOsVersionAtLeast470.includes(selectedLocation.gatewayMachineSerialNumber) && (
				<div className="d-flex justify-content-between mb-2">
					<p className="text-muted">Route via VPN tunnel</p>
					<div className="text-right">
						{rasConfiguration?.routes?.length > 0
							? _.compact(rasConfiguration.routes).map((route) => {
								return <p key={route.name}>{route.name}</p>;
							})
							: 'All traffic'}
					</div>
				</div>
			)}
			<div className="d-flex justify-content-between mt-4 mb-3">
				<h6 className="d-flex align-self-center">RAS Gateway VM Network Interfaces</h6>
			</div>
			<div className="d-flex justify-content-between mb-2">
				<p className="text-muted">Deployment Method</p>
				<p>{rasConfiguration.internalNetworkConfigurationMethod === 'none' ? 'Behind NAT' : 'DMZ'}</p>
			</div>
			<div className="d-flex justify-content-between mb-2">
				<p className="text-muted">External Virtual Network</p>
				<p>{rasConfiguration.externalNetwork}</p>
			</div>
			<div className="d-flex justify-content-between mb-2">
				<p className="text-muted">External IP/Netmask</p>
				<p>{rasConfiguration.externalIP}</p>
			</div>
			<div className="d-flex justify-content-between mb-2">
				<p className="text-muted">DNS</p>
				<p>{rasConfiguration.dns}</p>
			</div>
			<div className="d-flex justify-content-between mb-2">
				<p className="text-muted">Gateway</p>
				<p>{rasConfiguration.gatewayIP}</p>
			</div>
			{rasConfiguration.internalNetworkConfigurationMethod !== 'none' && (
				<>
					<div className="d-flex justify-content-between mb-2">
						<p className="text-muted">Internal Virtual Network</p>
						<p>{rasConfiguration.internalNetwork}</p>
					</div>
					<div className="d-flex justify-content-between mb-2">
						<p className="text-muted">Internal IP/Netmask</p>
						<p>{rasConfiguration.internalIP}</p>
					</div>
				</>
			)}
		</>
	) : (
		<Loading />
	);
};
