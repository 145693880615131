import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@syneto/compass-react';
import './style.scss';

export const CardItem = (props) => {
	const { link, title, icon, subtitle, coverBackgroundColor } = props;

	return (
		<a href={link} target="_blank" rel="noopener noreferrer" className="item-card">
			<div className="item-card-cover" style={{ background: coverBackgroundColor }}>
				<Icon name={icon} />
				<p>{subtitle}</p>
			</div>
			<div className="item-card-name text-neutral-90">{title}</div>
		</a>
	);
};

CardItem.propTypes = {
	title: PropTypes.string.isRequired,
	link: PropTypes.string,
	icon: PropTypes.string,
	subtitle: PropTypes.string,
	coverBackgroundColor: PropTypes.string,
};
