import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Card, Button, Icon } from '@syneto/compass-react';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { StoreContext } from '../../../contexts/StoreContext';
import { MfaFields } from './MfaFields';
import '../style.scss';
import { ServiceContext } from '../../../contexts/ServiceContext';

export const MfaForm = (props) => {
	const navigate = useNavigate();
	const { email, password, mfaDetails, setMfaDetails } = props;
	const { authenticationService } = useContext(ServiceContext);
	const { pushDangerNotification } = useContext(NotificationContext);
	const { dispatch } = useContext(StoreContext);
	const { handleSubmit } = useForm();

	const [token, setToken] = useState('');

	const onSubmit = async () => {
		try {
			const authorizationToken = await authenticationService.verifyToken({
				email,
				password,
				token,
			});

			if (authorizationToken) {
				dispatch({
					payload: {
						isAuthorized: true,
					},
				});
				navigate('/dashboard');
			}
		} catch (error) {
			pushDangerNotification(error.message.replace(/GraphQL error:/g, '').trim());
		}
	};

	const renderMfaDetails = () => {
		if (mfaDetails) {
			return (
				<>
					<div className="row mt-4">
						<div className="col-4">
							<img src={mfaDetails.qrCode} className="img-fluid" alt="" />
						</div>
						<div className="col-8">
							<p>
								In order to log in, you will need to download the Google Authenticator App on your phone, scan the QR
								code from this page or enter the secret <b>{mfaDetails.secret}</b> manually and fill in the generated
								security code.
							</p>
						</div>
					</div>
					<p className="mt-2">Enter security code</p>
				</>
			);
		}
	};

	return (
		<Card
			title={
				<div className="d-flex justify-content-between">
					<div>Multi-factor authentication</div>
					<div style={{ cursor: 'pointer' }} onClick={() => { return setMfaDetails(undefined); }}>
						<Icon name="fas fa-times" size="sm" />
					</div>
				</div>
			}
			content={
				<form onSubmit={handleSubmit(onSubmit)}>
					{renderMfaDetails()}
					<div className="d-flex justify-content-between mt-4">
						<MfaFields setToken={setToken} />
					</div>
					<div className="d-flex justify-content-between mt-5">
						<Button role="link" href="/password-recovery">
							Reset authentication token
						</Button>
						<Button className="float-right width-96 p-2" type="submit" disabled={token.length !== 6}>
							Confirm
						</Button>
					</div>
				</form>
			}
			className="mfa rounded"
		/>
	);
};
