import { registrationFields } from '../Registration/fields';

const commonFields = { ...registrationFields };

const fieldsToBeExcluded = [
	'email',
	'companyName',
	'companyVat',
	'companyMarketSector',
	'companyNumberEmployees',
	'companyCountry',
	'companyState',
	'companyCity',
	'companyAddress',
	'companyPostalCode',
	'companyEmail',
	'companyWebsite',
];

fieldsToBeExcluded.forEach((field) => {
	delete commonFields[field];
});

export const invitationRegistrationFields = {
	...commonFields,
	hash: 'hash',
};
