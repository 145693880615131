import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Icon, Popover } from '@syneto/compass-react';
import { ServiceContext } from '../../../../../contexts/ServiceContext';

export const MaintenanceStatus = (props) => {
	const { machines } = props;
	const { locationService } = useContext(ServiceContext);

	const [willExpireMachines, setWillExpireMachines] = useState([]);
	const [expiredMachines, setExpiredMachines] = useState([]);
	const [firstExpiringMachine, setFirstExpiringMachine] = useState('');
	const [expiringInterval, setExpiringInterval] = useState(30);

	useEffect(() => {
		const getLocationsRenewalsSettings = async () => {
			const renewalsSettings = await locationService.getLocationsRenewalsSettings();
			const interval = renewalsSettings?.expiringIntervalSettingForCustomer?.split('-');
			setExpiringInterval(parseInt(interval[0]));
		};

		getLocationsRenewalsSettings();
	}, []);

	useEffect(() => {
		const currentDate = moment().format('YYYY-MM-DD');
		let willExpireMachines = [];
		let expiredMachines = [];
		let allMaintenances = [];

		machines.length &&
			machines.forEach((machine) => {
				machine.maintenanceServices.length &&
					machine.maintenanceServices.forEach((ms) => {
						allMaintenances.push({ ...ms, name: machine.name, serialNumber: machine.serialNumber });

						if (ms.expiresOn && moment(ms.expiresOn).diff(currentDate, 'days') < 0) {
							expiredMachines.push({ ...ms, name: machine.name, serialNumber: machine.serialNumber });
						}

						if (
							ms.expiresOn &&
							moment(ms.expiresOn).diff(currentDate, 'days') > 0 &&
							moment(ms.expiresOn).diff(currentDate, 'days') <= expiringInterval
						) {
							willExpireMachines.push({ ...ms, name: machine.name, serialNumber: machine.serialNumber });
						}
					});
			});

		setWillExpireMachines(willExpireMachines.sort());
		setExpiredMachines(expiredMachines.sort());

		const sortedAllMaintenances = allMaintenances.sort((a, b) => {
			let comparison = 0;

			if (a.expiresOn > b.expiresOn) {
				comparison = 1;
			} else if (a.expiresOn < b.expiresOn) {
				comparison = -1;
			}

			return comparison;
		});

		setFirstExpiringMachine(sortedAllMaintenances[0]);
	}, [machines, expiringInterval]);

	if (expiredMachines.length) {
		return (
			<div className="location-card-status-row">
				{expiredMachines.length === 1 ? '1 Machine expired' : ` ${expiredMachines.length} Machines expired`}

				<Popover
					trigger="click"
					placement="top"
					body={
						<>
							{expiredMachines.map((machine, index) => {
								return (
									<div key={index} className="location-card-popover-container">
										<strong>{machine.name || machine.serialNumber}&nbsp;</strong>
										<span>
											|&nbsp;expired on&nbsp;
											{moment(machine.expiresOn).format('DD-MM-YYYY')}
										</span>
									</div>
								);
							})}
						</>
					}
					width={300}
					className="location-popover"
				>
					<span>
						<Icon
							className="pt-1 ms-2"
							name="fas fa-times-circle"
							color="#E45664"
							size="lg"
							style={{ cursor: 'pointer' }}
						/>
					</span>
				</Popover>
			</div>
		);
	} else if (willExpireMachines.length) {
		return (
			<div className="location-card-status-row">
				{willExpireMachines.length === 1 ? '1 Machine expiring' : `${willExpireMachines.length} Machines expiring`}

				<Popover
					trigger="click"
					placement="top"
					body={
						<>
							{willExpireMachines.map((machine, index) => {
								return (
									<div key={index} className="location-card-popover-container">
										<strong>{machine.name || machine.serialNumber}&nbsp;</strong>
										<span>
											|&nbsp;expires in&nbsp;
											{Math.ceil(-moment().diff(moment(machine.expiresOn), 'days', true))} days
										</span>
									</div>
								);
							})}
						</>
					}
					className="location-popover"
				>
					<span>
						<Icon
							className="pt-1 ms-2"
							name="fas fa-exclamation-triangle"
							color="#F4BE00"
							size="lg"
							style={{ cursor: 'pointer' }}
						/>
					</span>
				</Popover>
			</div>
		);
	} else {
		return (
			<div className="location-card-status-row">
				<span>Full support coverage</span>

				<Popover
					trigger="click"
					placement="top"
					body={
						<>
							<div className="location-card-popover-container">
								<strong>{firstExpiringMachine.name || firstExpiringMachine.serialNumber}&nbsp;</strong>
								<span>
									|&nbsp;expires on&nbsp;
									{moment(firstExpiringMachine.expiresOn).format('DD-MM-YYYY')}
								</span>
							</div>
						</>
					}
					width={300}
					className="location-popover"
				>
					<span>
						<Icon
							className="pt-1 ms-2"
							name="fas fa-check-circle"
							color="#00b170"
							size="lg"
							style={{ cursor: 'pointer' }}
						/>
					</span>
				</Popover>
			</div>
		);
	}
};

MaintenanceStatus.propTypes = {
	machines: PropTypes.array.isRequired,
};
