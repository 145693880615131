import moment from 'moment';

const CurrentlyActiveMaintenanceService = (maintenanceService) => {
	let maintenance = maintenanceService;

	if (maintenanceService.maintenanceServiceRenewals && maintenanceService.maintenanceServiceRenewals.length > 0) {
		maintenanceService.maintenanceServiceRenewals.forEach((renewal) => {
			if (moment().isBetween(moment(renewal.startDate), moment(renewal.endDate))) {
				maintenance = renewal;
			}
		});

		if (moment() > moment(maintenanceService.expiresOn)) {
			const indexOfMostRecentMaintenanceRenewal = maintenanceService.maintenanceServiceRenewals.length - 1;
			maintenance = maintenanceService.maintenanceServiceRenewals[indexOfMostRecentMaintenanceRenewal];
		}
	}

	return maintenance;
};

export default CurrentlyActiveMaintenanceService;
