import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Tabs } from '@syneto/compass-react';
import { Navigate } from 'react-router-dom';
import { RasLocation } from './RasLocation';
import { Viscosity } from './ViscositySteps';
import { OpenVPNSteps } from './OpenVPNSteps';
import { IdentityVerificationModal } from '../IdentityVerificationModal';
import { RasContext } from '../../../../contexts/RasContext';
import { StoreContext } from '../../../../contexts/StoreContext';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import { ServiceContext } from '../../../../contexts/ServiceContext';
import { Does } from '../../../../components/Access/Does';

export const RASConfigFiles = () => {
	const { rasService, accessService, userService } = useContext(ServiceContext);

	const { pushDangerNotification } = useContext(NotificationContext);
	const { state } = useContext(StoreContext);
	const { locationsWithRas, machinesRasInfo } = useContext(RasContext);

	const { loggedInUser, services } = state;

	const [locationsTheUserHasRasAccessTo, setLocationsTheUserHasRasAccessTo] = useState([]);
	const [showIdentityVerificationModal, setShowIdentityVerificationModal] = useState(false);
	const [selectedRasLocation, setSelectedRasLocation] = useState(null);
	const [masterAccountName, setMasterAccountName] = useState('');

	useEffect(() => {
		loadRASConfigFilesData();
	}, []);

	useEffect(() => {
		const fetchMasterAccountName = async () => {
			if (!accessService.hasPermissionOnAnyResource('location.list')) {
				setMasterAccountName(await userService.getMasterAccountName(loggedInUser?.company.id));
			}
		};

		fetchMasterAccountName();
	}, [accessService, loggedInUser, userService]);

	const loadRASConfigFilesData = async () => {
		try {
			setLocationsTheUserHasRasAccessTo(
				await rasService.filterLocationsTheUserHasRasAccessTo(
					locationsWithRas,
					machinesRasInfo.fulfilled,
					loggedInUser.email
				)
			);
		} catch (error) {
			pushDangerNotification('Failed to fetch RAS data.');
		}
	};

	// redirect if regular user does not have the ras activated
	if (!services?.ras?.active) { return <Navigate to="/dashboard" />; }

	return locationsTheUserHasRasAccessTo.length ? (
		<Row className="m-5">
			<Col xs={12} sm={6}>
				<h1 className="text-muted font-weight-light mb-4">Remote Access</h1>
				<p className="text-muted font-weight-light">
          The locations below have been enabled for your account. You may download the configuration files and connect
          using the following credentials:
				</p>

				<span className="mt-4">
          Username: <strong>{locationsTheUserHasRasAccessTo[0].user.username}</strong>
				</span>
				<br />
				<p className="mb-4">
          Password: <strong>your central account password</strong>
				</p>

				<div className="d-flex flex-wrap justify-content-start">
					{locationsTheUserHasRasAccessTo.map((rasLocation) => {
						return (
							<Does
								key={rasLocation.id}
								loggedInUser
								havePermission="ras.view"
								onResource={{
									type: 'location',
									referenceId: rasLocation.id,
								}}
								yes={() => {
									return (
										<RasLocation
											key={rasLocation.id}
											rasLocation={rasLocation}
											showIdentityVerificationModal={() => {
												setSelectedRasLocation(rasLocation);
												setShowIdentityVerificationModal(true);
											}}
										/>
									); 
								}}
							/>
						); 
					})}
				</div>
			</Col>
			<Col xs={12} sm={6}>
				<h1 className="text-muted font-weight-light">How do I use Remote Access?</h1>
				<div className="shadow p-4 mb-4 bg-white rounded">
					<Tabs defaultActiveKey="openVPNClient">
						<Tabs.Tab eventKey="openVPNClient" title="OpenVPN Connect">
							<OpenVPNSteps />
						</Tabs.Tab>
						<Tabs.Tab eventKey="viscosity" title="Viscosity">
							<Viscosity />
						</Tabs.Tab>
					</Tabs>
				</div>
			</Col>
			{selectedRasLocation && (
				<IdentityVerificationModal
					show={showIdentityVerificationModal}
					onHide={() => { return setShowIdentityVerificationModal(false); }}
					user={loggedInUser}
					location={selectedRasLocation}
				/>
			)}
		</Row>
	) : (
		<p className="text-disabled font-weight-light">
      You don't have access to any locations. Please contact {masterAccountName}
		</p>
	);
};
