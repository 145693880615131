import React from 'react';
import { getObjectPropertyByKeys } from './object';

export const buildComboBoxOptions = (data, label, value, disabledValues = [], fixedValues = []) => {
	return data.map((entry) => {
		const optionLabel = typeof label === 'function' ? label(entry) : getObjectPropertyByKeys(entry, label);
		const optionValue = typeof value === 'function' ? value(entry) : getObjectPropertyByKeys(entry, value);

		return {
			label: optionLabel,
			value: optionValue,
			isDisabled: disabledValues.includes(value),
			isClearable: !fixedValues.includes(optionValue),
		};
	});
};

export const buildSelectOptions = (data, label = 'label', value = 'value', disabledValues = []) => {
	return data.map((entry, index) => {
		const optionLabel =
			typeof entry === 'string'
				? entry
				: typeof label === 'function'
					? label(entry)
					: getObjectPropertyByKeys(entry, label);
		const optionValue =
			typeof entry === 'string'
				? entry
				: typeof value === 'function'
					? value(entry)
					: getObjectPropertyByKeys(entry, value);

		return (
			<option key={index} value={optionValue} disabled={disabledValues.includes(optionValue)}>
				{optionLabel}
			</option>
		);
	});
};
