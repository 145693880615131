import React, { useContext, useEffect, useState } from 'react';
import { RasContext } from '../../contexts/RasContext';
import { Loading } from '../../components/Loading';
import { RASTable } from './components/RasTable/RASTable';
import { RASConfigFiles } from './components/RasConfigFiles';
import { Does } from '../../components/Access/Does';
import { RasTabs } from './components/RasTabs';
import { RetryFetchRasDeployments } from './components/RasTable/RetryFetchRasDeployments';
import { NoRasDeployments } from './components/RasTable/NoRasDeployments';
import { RasSettingsModal } from './components/RasSettingsModal';
import { StoreContext } from '../../contexts/StoreContext';
import { Navigate } from 'react-router-dom';

export const RAS = () => {
	const {
		locationsWithRas,
		loadingTable,
		loadingTableError,
		machinesRasInfo,
		getRasTableData,
		selectedLocationRasSettingsModal,
		updateSelectedLocation,
		updateSelectedLocationRasSettingsModal,
		poolingRasDeployingMachines,
	} = useContext(RasContext);

	const { state } = useContext(StoreContext);
	const { services } = state;

	const [showRasSettingsModal, setShowRasSettingsModal] = useState(false);

	useEffect(() => {
		// by default, select the first row from the table
		updateSelectedLocation(locationsWithRas[0]);
	}, [locationsWithRas]);

	useEffect(() => {
		getRasTableData();
	}, []);

	useEffect(() => {
		poolingRasDeployingMachines();
	}, [machinesRasInfo]);

	const onLocationSelect = (location) => {
		updateSelectedLocationRasSettingsModal(location);
		setShowRasSettingsModal(true);
	};

	const renderModal = () => {
		return (
			showRasSettingsModal && (
				<RasSettingsModal
					show={showRasSettingsModal}
					onHide={() => {
						setShowRasSettingsModal(false);
						updateSelectedLocationRasSettingsModal(null);
					}}
				/>
			)
		);
	};

	const renderRasTable = () => {
		// we are merging with machinesRasInfo, to trigger re-renders on the rasTable when status and users change
		const locationsWithRasDetails =
			locationsWithRas?.length && locationsWithRas.map((x) => { return { ...x, ...machinesRasInfo.fulfilled }; });

		switch (true) {
			case loadingTableError:
				return <RetryFetchRasDeployments />;
			case locationsWithRasDetails.length > 0:
				return (
					<div className="min-vh-80 row">
						<div className="col-8 border-right">
							<RASTable locationsWithRasDetails={locationsWithRasDetails} onLocationSelect={onLocationSelect} />
						</div>

						<RasTabs onLocationSelect={onLocationSelect} />
					</div>
				);
			default:
				return <NoRasDeployments onLocationSelect={onLocationSelect} />;
		}
	};

	// redirect if the ras service is not activated or the activation day is in the future
	if (!services?.ras || (!services?.ras?.active && !services?.ras?.expired)) {
		return <Navigate to="/dashboard" />;
	}

	return loadingTable ? (
		<Loading />
	) : (
		<Does
			loggedInUser
			havePermission="location.list"
			onAnyResource={true}
			yes={() => {
				return (
					<>
						<div className="min-vh-80 mb-5 shadow p-3 bg-white rounded">
							{renderRasTable()}
						</div>

						<Does
							loggedInUser
							havePermission="ras.update"
							onCompanyResource
							yes={renderModal}
							no={() => {
								return (
									<Does
										loggedInUser
										havePermission="ras.update"
										onResource={{
											type: 'location',
											referenceId: selectedLocationRasSettingsModal?.id,
										}}
										yes={renderModal}
									/>
								);
							}}
						/>
					</>
				);
			}}
			no={() => { return <RASConfigFiles />; }}
		/>
	);
};
