import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { StoreContext } from '../../../contexts/StoreContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { Modal, Form } from '@syneto/compass-react';
import { Checkbox, InputField } from '../../../components/Form';
import PropTypes from 'prop-types';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { Does } from '../../../components/Access/Does';
import { rEmail } from '../../../constants/validation';

export const addUserValidationSchema = yup.object().shape({
	email: yup.string().required('E-mail is required').trim().matches( rEmail , 'Email format is incorrect'),
});

export const AddUserModal = (props) => {
	const { show, onHide } = props;
	const { userService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(addUserValidationSchema),
	});

	const [globalRoles, setGlobalRoles] = useState([]);
	const [submitting, setSubmitting] = useState(false);

	const onSubmit = async (values) => {
		try {
			setSubmitting(true);
			await userService.sendUserInvitationEmail({
				...values,
				companyId: loggedInUser.company.id,
				roles: globalRoles,
			});
			onHide();
			pushSuccessfulNotification(`Registration invitation successfully sent to ${values.email}.`);
		} catch (error) {
			pushDangerNotification(error.message.replace(/GraphQL error:/g, '').trim());
			setSubmitting(false);
		}
	};

	const updateRoles = (role, checked) => {
		let existingRoles = [...globalRoles];

		if (!checked) {
			return setGlobalRoles(existingRoles.filter((existingRole) => { return existingRole !== role; }));
		}

		return setGlobalRoles([...existingRoles, role]);
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			slidingPanel={true}
			content={
				<form>
					<InputField {...register('email')} label="E-mail" errors={errors} />
					<Does
						loggedInUser
						havePermission="user.role.assign"
						onCompanyResource
						yes={() => {
							return (
								<Form.Group controlId="globalPermissions">
									<Form.Label>Global Permissions</Form.Label>
									<div className="mt-2">
										<Checkbox
											id="fleetManager"
											name="fleetManager"
											label="Fleet Manager"
											errors={errors}
											onChange={(event) => { return updateRoles('fleet.admin', event.target.checked); }}
										/>
										<Checkbox
											id="userManager"
											name="userManager"
											label="User Manager"
											errors={errors}
											onChange={(event) => { return updateRoles('user.admin', event.target.checked); }}
										/>
									</div>
								</Form.Group>
							); 
						}}
					/>
				</form>
			}
			title="Add User"
			actions={{
				primary: {
					label: 'Send Invite',
					onClick: handleSubmit(onSubmit),
					disabled: submitting,
				},
				secondary: {
					label: 'Cancel',
					onClick: onHide,
				},
			}}
		/>
	);
};

AddUserModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
};
