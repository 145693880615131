import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Spinner, Tooltip, Icon, Badge, Form } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ServiceContext } from '../../../../../contexts/ServiceContext';
import { RasContext } from '../../../../../contexts/RasContext';
import { ComboxBoxField } from '../../../../../components/Form';
import { buildComboBoxOptions } from '../../../../../helpers/select';
import { RasDestinationsModal } from '../Destinations/RasDestinationsModal';
import { NotificationContext } from '../../../../../contexts/NotificationContext';
import { Does } from '../../../../../components/Access/Does';
import InfoIcon from '../../../../../components/Form/InfoIcon';

export const EditRasAccessModal = (props) => {
	const { show, onHide, user, rasDestinations, isMachineAbove470 } = props;

	const { machineService, rasService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { selectedLocation, getRasByLocations } = useContext(RasContext);

	const { register, setValue, handleSubmit, unregister, formState: { errors } } = useForm({
		validationSchema: yup.object().shape({
			allowedDestinations: yup.array().when('route', {
				is: (val) => { return val && val !== 'allTraffic'; },
				then: () => { return yup.array().required('You must select at least one destination'); },
			}),
		}),
	});

	const [accessType, setAccessType] = useState('allTraffic');
	const [selectedDestinations, setSelectedDestinations] = useState([]);
	const [showDestinationsModal, setShowDestinationsModal] = useState(false);
	const [revokingAccess, setRevokingAccess] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		handleMultiChangeDestinations(buildComboBoxOptions(getSelectedRasDestinations(), 'name', 'name'));
	}, []);

	useEffect(() => {
		if (selectedDestinations?.length > 0) {
			setAccessType('specificDestinations');
		}
	}, [selectedDestinations, rasDestinations]);

	useEffect(() => {
		if (show && isMachineAbove470) {
			register('route');
			register('allowedDestinations');

			setValue('route', accessType);

			if (accessType === 'allTraffic') {
				setValue('allowedDestinations', []);
			} else {
				setValue(
					'allowedDestinations',
					selectedDestinations.map((option) => { return option.value; })
				);
			}
		} else {
			unregister('route');
			unregister('selectDestinations');
		}
	}, [show, isMachineAbove470, accessType]);

	const getSelectedRasDestinations = () => {
		return rasDestinations.filter((rasDestination) => { return user.allowedDestinations?.includes(rasDestination.name); });
	};

	const onRevokeAccess = async () => {
		try {
			setRevokingAccess(true);
			await rasService.removeRasUser(
				selectedLocation?.gatewayMachineSerialNumber,
				machineService.getGatewayMachineOsVersion(selectedLocation),
				user.id,
				user.email,
				selectedLocation.id,
				rasService.prepareDestinationsForHyper(rasDestinations),
				rasService.prepareDestinationsForHyper(getSelectedRasDestinations())
			);

			await getRasByLocations();

			onHide();
			pushSuccessfulNotification('RAS access revoked successfully.');
		} catch (e) {
			setRevokingAccess(true);
			pushDangerNotification('Failed to revoke RAS access.');
		}
	};

	const onSubmit = async (values) => {
		try {
			setSubmitting(true);

			await rasService.setRasUserAllowedDestinations(
				selectedLocation.gatewayMachineSerialNumber,
				user.email,
				rasService.prepareDestinationsForHyper(rasDestinations),
				values.allowedDestinations
					? rasService.prepareDestinationsForHyper(
						rasDestinations.filter((rasDestination) => { return values.allowedDestinations.includes(rasDestination.name); })
					)
					: []
			);

			await getRasByLocations();
			onHide();
			pushSuccessfulNotification('User RAS access updated successfully.');
		} catch (error) {
			pushDangerNotification('Failed to update user RAS access.');
			setSubmitting(false);
		}
	};

	const handleMultiChangeDestinations = (selectedOptions) => {
		selectedOptions = selectedOptions || [];

		setValue(
			'allowedDestinations',
			selectedOptions.map((option) => { return option.value; }),
			true
		);
		setSelectedDestinations(selectedOptions);
	};

	const renderRevokeAccessButton = () => {
		return (
			<Button role="secondary" appearance="danger" onClick={onRevokeAccess} disabled={revokingAccess}>
				{revokingAccess ? (
					<span>
						<Spinner animation="border" variant="primary" size="sm" className="text-muted" />
						&nbsp; Revoking Access...
					</span>
				) : (
					<span>Revoke Access</span>
				)}
			</Button>
		);
	};

	return (
		<>
			<Modal show={show} onHide={onHide} slidingPanel={true}>
				<Modal.Header>
					<Modal.Title>Edit RAS Access</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<form>
						<p className="font-weight-bold">
							Access type
							{!isMachineAbove470 ? (
								<>
									&nbsp;<Badge appearance="warning">Not supported</Badge>
									<InfoIcon 
										placement="bottom" 
										tooltipStyle="pt-1 ms-2" 
										text="The selected RAS Gateway Device is at an older Syneto OS version which does not support restricted access. Please update Syneto OS to the latest version to enable this functionality."
										iconType="question"
									/>
								</>
							) : null}
						</p>

						{isMachineAbove470 ? (
							<>
								<Form.Check
									className="mb-2"
									name="route"
									label="Unrestricted"
									type="radio"
									id="allTraffic"
									checked={accessType === 'allTraffic'}
									onChange={() => {
										setAccessType('allTraffic');
										setValue('allowedDestinations', []);
									}}
									style={{ minHeight: 'auto' }}
								/>

								<Form.Check
									className="mb-4"
									name="route"
									label="Restricted to the following destinations"
									type="radio"
									id="specificDestinations"
									checked={accessType === 'specificDestinations'}
									onChange={() => { return setAccessType('specificDestinations'); }}
									style={{ minHeight: 'auto' }}
								/>

								{accessType === 'specificDestinations' && (
									<>
										<div className="d-flex justify-content-between">
											<p>Select allowed destinations</p>
											<div>
												<Button role="link" onClick={() => { return setShowDestinationsModal(true); }}>
													Add new
												</Button>
											</div>
										</div>

										<ComboxBoxField
											options={buildComboBoxOptions(rasDestinations, 'name', 'name')}
											name="allowedDestinations"
											placeholder="Select allowed destinations"
											value={selectedDestinations}
											onChange={handleMultiChangeDestinations}
											isMulti
											errors={errors}
										/>
									</>
								)}
							</>
						) : null}
					</form>
				</Modal.Body>

				<Modal.Footer>
					<div className="d-flex w-100 justify-content-between">
						<Does
							loggedInUser
							havePermission="ras.user.remove"
							onCompanyResource
							yes={renderRevokeAccessButton}
							no={() => {
								return (
									<Does
										loggedInUser
										havePermission="ras.user.remove"
										onResource={{
											type: 'location',
											referenceId: selectedLocation?.id,
										}}
										yes={renderRevokeAccessButton}
									/>
								);
							}}
						/>

						<div>
							<Button width={96} role="secondary" className="me-3" onClick={onHide}>
								Cancel
							</Button>

							{isMachineAbove470 ? (
								<Button width={96} onClick={handleSubmit(onSubmit)} disabled={submitting}>
									{submitting ? (
										<span>
											<Spinner animation="border" variant="primary" size="sm" className="text-muted" />
											&nbsp; Saving...
										</span>
									) : (
										<span>Save</span>
									)}
								</Button>
							) : null}
						</div>
					</div>
				</Modal.Footer>
			</Modal>

			{showDestinationsModal && (
				<RasDestinationsModal
					show={showDestinationsModal}
					onHide={() => { return setShowDestinationsModal(false); }}
					destinationsCurrentlyInUseInForm={selectedDestinations}
				/>
			)}
		</>
	);
};
