import React from 'react';
import { Tabs } from '@syneto/compass-react';
import MonitoringTab from './components/MonitoringTab';
import ChangeMonitoringPasswordTab from './components/ChangeMonitoringPasswordTab';
import EmailNotificationsTab from './components/EmailNotifications';
import './style.scss';

export const Monitoring = () => {
	return (
		<div className="min-vh-80 shadow p-3 bg-white rounded">
			<Tabs>
				<Tabs.Tab eventKey="monitoring_tab" className="qa-monitoring-tab" title="Monitoring">
					<MonitoringTab/>
				</Tabs.Tab>
				<Tabs.Tab eventKey="email_notifications" className="qa-email-notifications-tab" title="Email Notifications">
					<EmailNotificationsTab/>
				</Tabs.Tab>
				<Tabs.Tab eventKey="change_password" className="qa-change-password-tab" title="Change Password">
					<ChangeMonitoringPasswordTab/>
				</Tabs.Tab>
			</Tabs>
		</div >
	);
};
