import _ from 'lodash';
import moment from 'moment';

export class CompanyService {
	processCompanyServices(services) {
		if (services?.length) {
			const rasService = services.find((s) => { return s?.name === 'Remote Access Service'; });
			const monitoringService = services.find((s) => { return s?.name === 'Monitoring Service'; });

			return {
				ras: rasService ? this._processRas(rasService) : undefined,
				monitoring: monitoringService ? this._processMonitoring(monitoringService) : undefined,
			};
		}
	}

	_processRas(rasService) {
		const settings = {
			availableSeats: _.find(rasService.service_properties, { name: 'availableSeats' })?.value,
			startingPeriod: _.find(rasService.service_properties, { name: 'startingPeriod' })?.value,
			endingPeriod: _.find(rasService.service_properties, { name: 'endingPeriod' })?.value,
		};

		const currentDate = moment().format('YYYY-MM-DD');

		const activeInTheFuture = moment(settings.startingPeriod).diff(currentDate, 'days') > 0;
		const expired = moment(settings.endingPeriod).diff(currentDate, 'days') < 0;
		const active = !expired && !activeInTheFuture;

		return {
			settings,
			active,
			expired,
		};
	}

	_processMonitoring() {
		return {
			active: true,
		};
	}
}
