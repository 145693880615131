import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { NotificationContextProvider } from './contexts/NotificationContext';
import { StoreContextProvider } from './contexts/StoreContext';
import { Notification } from './components/Notification';
import { ServiceContextProvider } from './contexts/ServiceContext';

const root = createRoot(document.getElementById('root'));

root.render(
	<BrowserRouter>
		<NotificationContextProvider>
			<StoreContextProvider>
				<ServiceContextProvider>
					<App />
				</ServiceContextProvider>
			</StoreContextProvider>
			<Notification />
		</NotificationContextProvider>
	</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
