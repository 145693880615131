export const parseOSVersion = (osVersion) => {
	const tempOSVersion = osVersion || 'N/A';
	const osVersionNumbers = [];
	for (let number of tempOSVersion.split('.')) {
		osVersionNumbers.push(number);
	}

	return osVersionNumbers.slice(0, 3).join('.');
};

export const getOSVersion = (osVersion) => {
	const OSNumber = !!osVersion && osVersion.split('.')[0];
	return OSNumber ? `os${OSNumber}` : null;
};
