import React, { useContext } from 'react';
import { Layout } from '@syneto/compass-react';
import { Outlet } from 'react-router-dom';
import { SideNavigation } from './SideNavigation';
import { TitleBar } from './TitleBar';
import { StoreContext } from '../contexts/StoreContext';

export const RouteLayout = () => {
	const { state: {
		isAuthorized
	} } = useContext(StoreContext);

	return (
		<Layout
			hasDrawer={isAuthorized}
			drawer={<SideNavigation />}
			header={<TitleBar/>}
			content={
				<div className="min-vh-80 container-fluid my-4">
					<Outlet/>
				</div>
			}
		/>
	);
};
