import React, { useContext } from 'react';
import { Modal } from '@syneto/compass-react';
import { StoreContext } from '../../../../../contexts/StoreContext';
import '../style.scss';

export const StartTourModal = (props) => {
	const { show, hide, onStartTour, onSkipTour } = props;

	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;

	return (
		<Modal
			show={show}
			onHide={hide}
			actions={{
				primary: {
					label: 'Start the tour',
					onClick: onStartTour,
				},
				tertiary: {
					label: 'Skip',
					onClick: onSkipTour,
				},
			}}
			content={
				<div>
					<div className="welcome-joyride-tour" />
					<h1 className="text-brand-color-dark-40">
						{`Hi ${loggedInUser.profile.firstName}, welcome to the new Syneto Central.`}
					</h1>

					<h6 className="text-muted text-justify mt-4">
            Since your last visit, we have launched a new version of the platform, one with exciting new features and
            many cool improvements to the user-experience.
					</h6>

					<h6 className="text-muted text-justify mt-4">Here's what's new:</h6>
					<h6 className="font-weight-bold text-justify">1. Fleet management:</h6>
					<h6 className="text-muted text-justify">
            To help you better manage your installed machines, we have introduced the concept of "Location". You can now
            group several Syneto installations under the same location, see the license expiration date(s) and available
            system updates – all in a centralized view.
					</h6>
					<h6 className="font-weight-bold text-justify">2. User management:</h6>
					<h6 className="text-muted text-justify">
            You can now create multiple Central accounts and grant them granular access to the various platform
            services.
					</h6>
					<h6 className="font-weight-bold text-justify">3. Remote Access Service (RAS):</h6>
					<h6 className="text-muted text-justify">
            Syneto Central RAS is a solution that answers your smart working / remote working needs. With just a few
            clicks, a company's employees can now easily access the corporate LAN using a simple and fully automated
            solution.
					</h6>
					<h6 className="text-muted text-justify mt-4">
            Click{' '}
						<a
							href="https://syneto.eu/2020/05/15/how-to-make-smart-work-smarter-with-syneto-central/"
							target="_blank"
							rel="noopener noreferrer"
						>
              here
						</a>{' '}
            to find out more.
					</h6>
				</div>
			}
		/>
	);
};
