import React from 'react';

export const Viscosity = () => {
	return (
		<div>
			<p className="font-size-20 text-muted font-weight-light">Step 1</p>
			<p className="text-muted font-weight-light">
        Open Viscosity, go to the Viscosity menu and select “Preferences…”.The preferences window should appear.
			</p>
			<p className="font-size-20 text-muted font-weight-light">Step 2</p>
			<p className="text-muted font-weight-light">
        Make sure the “Connections” toolbar item is selected, and then click the “+” button in the bottom left hand
        corner of the window. Select “Import Connection” then "From File..." from the menu that appears.
			</p>
			<p className="font-size-20 text-muted font-weight-light">Step 3</p>
			<p className="text-muted font-weight-light">
        Select the configuration file (.ovpn ) that you have downloaded. Click the Open button.
			</p>
			<p className="font-size-20 text-muted font-weight-light">Step 4</p>
			<p className="text-muted font-weight-light">
        Your connection will now appear in the Viscosity menu. To connect, right click on your connection line and click
        on Connect option from the menu. After you introduce your username, your Central account password and your
        Syneto Central MFA PIN, the status will update to Connected when your new connection has been established.
			</p>
		</div>
	);
};
