import { gql } from 'apollo-boost';
import { registrationFields } from '../pages/Registration/fields';
import { invitationRegistrationFields } from '../pages/InvitationRegistration/fields';
import { profileFields } from '../pages/Profile/fields';

export class UserService {
	constructor(graphqlClient) {
		this.graphqlClient = graphqlClient;
	}

	async registerUser(inputData) {
		const user = {
			account: {
				email: inputData[registrationFields.email],
				password: inputData[registrationFields.password],
				data_collection_consent: inputData[registrationFields.dataCollectionConsent],
				subscribe_news: inputData[registrationFields.subscribeNews],
				subscribe_product: inputData[registrationFields.subscribeProduct],
				subscribe_satisfaction_surveys: inputData[registrationFields.subscribeSatisfactionSurveys],
			},
			contact: {
				firstName: inputData[registrationFields.firstName],
				lastName: inputData[registrationFields.lastName],
				roleInCompany: inputData[registrationFields.role],
				phoneNumber: inputData[registrationFields.phoneNumber],
				isSupportContact: false,
			},
			company: {
				name: inputData[registrationFields.companyName],
				numberOfEmployees: inputData[registrationFields.companyNumberEmployees],
				marketSector: inputData[registrationFields.companyMarketSector],
				vat: inputData[registrationFields.companyVat],
				city: inputData[registrationFields.companyCity],
				country: inputData[registrationFields.companyCountry],
				province: inputData[registrationFields.companyState],
				address: inputData[registrationFields.companyAddress],
				postalCode: inputData[registrationFields.companyPostalCode],
				email: inputData[registrationFields.companyEmail],
				website: inputData[registrationFields.companyWebsite],
			},
		};

		const registerUserResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($user: RegisterInput!) {
          registerUser(user: $user)
        }
      `,
			variables: {
				user,
			},
			fetchPolicy: 'no-cache',
		});

		return registerUserResponse.data.registerUser;
	}

	async addUser(inputData) {
		const user = {
			hash: inputData[invitationRegistrationFields.hash],
			account: {
				password: inputData[invitationRegistrationFields.password],
				data_collection_consent: inputData[invitationRegistrationFields.dataCollectionConsent],
				subscribe_news: inputData[invitationRegistrationFields.subscribeNews],
				subscribe_product: inputData[invitationRegistrationFields.subscribeProduct],
				subscribe_satisfaction_surveys: inputData[invitationRegistrationFields.subscribeSatisfactionSurveys],
			},
			contact: {
				firstName: inputData[invitationRegistrationFields.firstName],
				lastName: inputData[invitationRegistrationFields.lastName],
				roleInCompany: inputData[invitationRegistrationFields.role],
				phoneNumber: inputData[invitationRegistrationFields.phoneNumber],
				isSupportContact: false,
			},
		};

		const addUserResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($user: AddUserInput!) {
          addUser(user: $user)
        }
      `,
			variables: {
				user,
			},
			fetchPolicy: 'no-cache',
		});

		return addUserResponse.data.addUser;
	}

	async updateUser(inputData) {
		const user = {
			id: inputData[profileFields.id],
			account: {
				email: inputData[profileFields.email],
				data_collection_consent: inputData[profileFields.dataCollectionConsent],
				subscribe_news: inputData[profileFields.subscribeNews],
				subscribe_product: inputData[profileFields.subscribeProduct],
				subscribe_satisfaction_surveys: inputData[profileFields.subscribeSatisfactionSurveys],
			},
			contact: {
				firstName: inputData[profileFields.firstName],
				lastName: inputData[profileFields.lastName],
				roleInCompany: inputData[profileFields.role],
				phoneNumber: inputData[profileFields.phoneNumber],
				isSupportContact: inputData[profileFields.isSupportContact],
			},
			security: {
				password: inputData[profileFields.password],
				newPassword: inputData[profileFields.newPassword],
			},
		};

		if (inputData['globalRoles']) {
			user.globalRoles = inputData['globalRoles'];
		}

		if (inputData[profileFields.hasPermissionToUpdateCompanyProfile]) {
			user.company = {
				name: inputData[profileFields.companyName],
				numberOfEmployees: inputData[profileFields.companyNumberEmployees],
				marketSector: inputData[profileFields.companyMarketSector],
				vat: inputData[profileFields.companyVat],
				city: inputData[profileFields.companyCity],
				country: inputData[profileFields.companyCountry],
				province: inputData[profileFields.companyState],
				address: inputData[profileFields.companyAddress],
				postalCode: inputData[profileFields.companyPostalCode],
				email: inputData[profileFields.companyEmail],
				website: inputData[profileFields.companyWebsite],
			};
		}

		const updateUserResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($user: UpdateUserInput!) {
          updateUser(user: $user)
        }
      `,
			variables: {
				user,
			},
			fetchPolicy: 'no-cache',
		});

		return updateUserResponse.data.updateUser;
	}

	async removeUser(id) {
		const removeUserResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($id: String!) {
          removeUser(id: $id)
        }
      `,
			variables: {
				id: id,
			},
			fetchPolicy: 'no-cache',
		});

		return removeUserResponse.data.removeUser;
	}

	async getLoggedInUser() {
		const loggedInUserResponse = await this.graphqlClient.query({
			query: gql`
        query {
          loggedInUser {
            id
            email
            lastUsed
            mfaTokenUsed
            policies {
              resource {
                id
                type
                referenceId
              }
              bindings {
                userIds
                role {
                  id
                  name
                  permissions {
                    id
                    name
                  }
                }
              }
            }
            profile {
              id
              firstName
              lastName
              phoneNumber
              roleInTheCompany
              hasVirtualApplianceAccess
              seenWelcomeBanner
              isSupportContact
              subscribeToNews
              subscribeToProductNews
              subscribeToSatisfactionSurveys
              dataCollectionConsent
            }
            company {
              id
              name
              marketSector
              city
              province
              country
              address
              numberOfEmployees
              vat
              postalCode
              email
              website
              monitoringNamespace
              locationIds
              services {
                name
                service_properties {
                  name
                  value
                }
              }
            }
          }
        }
      `,
			fetchPolicy: 'no-cache',
		});


		const impersonate = localStorage.getItem('impersonated') ?? false;

		return {
			...loggedInUserResponse.data.loggedInUser,
			impersonated: impersonate
		};
	}

	async getUsersByCompanyId(companyId) {
		const usersByCompanyIdResponse = await this.graphqlClient.query({
			query: gql`
        query usersByCompanyId($companyId: String!) {
          usersByCompanyId(companyId: $companyId) {
            id
            email
            lastUsed
            policies {
              resource {
                id
                type
                referenceId
              }
              bindings {
                userIds
                role {
                  id
                  name
                  permissions {
                    id
                    name
                  }
                }
              }
            }
            profile {
              id
              firstName
              lastName
              phoneNumber
              roleInTheCompany
              hasVirtualApplianceAccess
              seenWelcomeBanner
              isSupportContact
              subscribeToNews
              subscribeToProductNews
              subscribeToSatisfactionSurveys
              dataCollectionConsent
            }
          }
        }
      `,
			variables: {
				companyId: companyId,
			},
			fetchPolicy: 'no-cache',
		});

		return usersByCompanyIdResponse.data.usersByCompanyId;
	}

	async validateVAT(vatNumber) {
		const validateVATResponse = await this.graphqlClient.query({
			query: gql`
        query ($vatNumber: String!) {
          validateVAT(vatNumber: $vatNumber) {
            isValid
            companyName
          }
        }
      `,
			variables: {
				vatNumber: vatNumber,
			},
			fetchPolicy: 'no-cache',
		});

		return validateVATResponse.data.validateVAT;
	}

	async sendUserInvitationEmail(inputData) {
		const invitation = {
			email: inputData.email,
			companyId: inputData.companyId,
			roles: inputData.roles,
		};

		const sendUserInvitationEmailResponse = await this.graphqlClient.query({
			query: gql`
        query ($invitation: UserInvitationInput!) {
          sendUserInvitationEmail(invitation: $invitation)
        }
      `,
			variables: {
				invitation: invitation,
			},
			fetchPolicy: 'no-cache',
		});

		return sendUserInvitationEmailResponse.data.sendUserInvitationEmail;
	}

	async sendUserPasswordResetEmail(email) {
		try {
			const sendUserPasswordResetEmailResponse = await this.graphqlClient.query({
				query: gql`
			query ($email: String!) {
			  sendUserPasswordResetEmail(email: $email)
			}
		  `,
				variables: {
					email: email,
				},
				fetchPolicy: 'no-cache',
			});
	
			return sendUserPasswordResetEmailResponse.data.sendUserPasswordResetEmail;
		} catch (error) {
			throw { message: error.graphQLErrors[0].message };
		}
	}

	async resetUserPassword(passwordResetToken, password) {
		const resetUserPasswordResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($passwordResetToken: String!, $password: String!) {
          resetUserPassword(passwordResetToken: $passwordResetToken, password: $password)
        }
      `,
			variables: {
				passwordResetToken: passwordResetToken,
				password: password,
			},
			fetchPolicy: 'no-cache',
		});

		return resetUserPasswordResponse.data.resetUserPassword;
	}

	async markWelcomeBannerAsSeen(profileId) {
		const markWelcomeBannerAsSeenResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($profileId: String!) {
          markWelcomeBannerAsSeen(profileId: $profileId)
        }
      `,
			variables: {
				profileId: profileId,
			},
			fetchPolicy: 'no-cache',
		});

		return markWelcomeBannerAsSeenResponse.data.markWelcomeBannerAsSeen;
	}

	async getMasterAccountName(companyId) {
		const masterAccountNameResponse = await this.graphqlClient.query({
			query: gql`
        query ($companyId: String!) {
          masterAccountName(companyId: $companyId)
        }
      `,
			variables: {
				companyId: companyId,
			},
			fetchPolicy: 'no-cache',
		});

		return masterAccountNameResponse.data.masterAccountName;
	}
}
