// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../../../src/assets/img/generic_map.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-vpn-card{width:292px;height:142px;margin-top:25px;margin-right:25px;border-radius:6px;box-shadow:0 1px 2px 0 rgba(0,0,0,.1),0 3px 6px 0 rgba(0,0,0,.1),0 5px 10px 0 rgba(0,0,0,.1);cursor:pointer}.download-vpn-card .download-vpn-card-cover{height:112px;border-radius:6px 6px 0 0;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.download-vpn-card .download-vpn-card-cover-text{margin-top:5px;text-align:center}`, "",{"version":3,"sources":["webpack://./src/pages/RAS/components/RasTabs/Download/style.scss"],"names":[],"mappings":"AAAA,mBACE,WAAA,CACA,YAAA,CACA,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,4FAAA,CACA,cAAA,CAEA,4CACE,YAAA,CACA,yBAAA,CACA,wDAAA,CAGF,iDACE,cAAA,CACA,iBAAA","sourcesContent":[".download-vpn-card {\n  width: 292px;\n  height: 142px;\n  margin-top: 25px;\n  margin-right: 25px;\n  border-radius: 6px;\n  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.1), 0 5px 10px 0 rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n\n  .download-vpn-card-cover {\n    height: 112px;\n    border-radius: 6px 6px 0 0;\n    background-image: url(\"../../../../../../src/assets/img/generic_map.svg\");\n  }\n\n  .download-vpn-card-cover-text {\n    margin-top: 5px;\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
