import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Container, Tooltip, Icon, Button, Card, Modal, Alert } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { registrationValidationSchema } from './validation';
import { InputField, Select, Checkbox } from '../../components/Form';
import { buildSelectOptions } from '../../helpers/select';
import { marketSectors } from '../../constants/marketSectors';
import { employeesNumber } from '../../constants/employeesNumber';
import { countries } from '../../constants/countries';
import { registrationFields } from './fields';
import { provinces } from '../../constants/provinces';
import { NotificationContext } from '../../contexts/NotificationContext';
import { InputSelectCombo } from '../../components/Form/InputSelectCombo';
import { ServiceContext } from '../../contexts/ServiceContext';
import InfoIcon from '../../components/Form/InfoIcon';
import { profileFields } from '../Profile/fields';

export const Registration = () => {
	const { userService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);

	const { register, handleSubmit, setValue, clearErrors, formState:{ errors } } = useForm({
		resolver: yupResolver( yup.object().shape(registrationValidationSchema))
	});

	const [showVatCheck, setShowVatCheck] = useState(false);
	const [showVatCheckModal, setShowVatCheckModal] = useState(false);
	const [country, setCountry] = useState('');
	const navigate = useNavigate();

	useEffect(() => {
		register(registrationFields.subscribeProduct);
		register(registrationFields.subscribeNews);
		register(registrationFields.subscribeSatisfactionSurveys);
		register(registrationFields.dataCollectionConsent);

		setValue(registrationFields.subscribeProduct, false);
		setValue(registrationFields.subscribeNews, false);
		setValue(registrationFields.subscribeSatisfactionSurveys, false);
		setValue(registrationFields.dataCollectionConsent, false);
	}, []);

	const onSubmit = async (values) => {
		try {
			await userService.registerUser(values);
			navigate('/authentication');
			pushSuccessfulNotification('User registered successfully.');
		} catch (error) {
			pushDangerNotification(error.message.replace(/GraphQL error:/g, '').trim());
		}
	};

	const validateVAT = async (vatNumber) => {
		if (vatNumber === '' || !vatNumber) {
			setShowVatCheck(false);
			return;
		}

		try {
			const validateVATResult = await userService.validateVAT(vatNumber);

			if (validateVATResult === null) {
				return;
			}

			if (validateVATResult.isValid && validateVATResult.companyName) {
				setValue(profileFields.companyName, validateVATResult.companyName);
				setShowVatCheck(true);
			} else if (validateVATResult.isValid && !validateVATResult.companyName) {
				setShowVatCheck(true);
			} else {
				setShowVatCheck(false);
				setShowVatCheckModal(true);
			}
		} catch (error) {
			pushDangerNotification('Failed to fetch VAT data.');
		}
	};

	return (
		<>
			<Container>
				<Card
					className="px-3 mt-2 mb-4"
					content={
						<>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Row>
									<Col xs={12} md={12} lg={3}>
										<h2>1. Account</h2>
									</Col>

									<Col>
										<InputField
											{...register(registrationFields.email)}
											label="Email"
											errors={errors}
											required
											icon={<InfoIcon placement="top" text="This will be used for your account name" />}
										/>

										<Row>
											<Col xs={12} md={6}>
												<InputField
													{...register(registrationFields.password)}
													label="Password*"
													type="password"
													errors={errors}
												/>
											</Col>

											<Col xs={12} md={6}>
												<InputField
													{...register(registrationFields.checkPassword)}
													label="Re-type password*"
													type="password"
													errors={errors}
												/>
											</Col>

											<Col>
												<Alert variant="info" className="d-flex align-items-center">
													<Icon name="fa fa-info-circle" size="lg" color="#22a0e9" className="me-3" />
													<span>
														Keep in mind that in this form you will create an unique Central Master account for this Company and you won't be able to delete it after the creation is done. As a Master user you will be able to send invitations for Regular users to create Central accounts in this Company.
													</span>
												</Alert>
											</Col>

										</Row>
									</Col>
								</Row>

								<Row className="mt-5">
									<Col xs={12} md={12} lg={3}>
										<h2>
											<span style={{ marginTop: '20px' }}>2. Contact details</span>&nbsp;
											<InfoIcon placement="top" text="Used for important communications regarding your account" iconSize='xs' />
										</h2>
									</Col>

									<Col>
										<Row>
											<Col>
												<InputField
													{...register(registrationFields.firstName)}
													label="First name*"
													errors={errors}
												/>
											</Col>
											<Col>
												<InputField
													{...register(registrationFields.lastName)}
													label="Last name*"
													errors={errors}
												/>
											</Col>
										</Row>

										<Row>
											<Col>
												<InputField 
													{...register(registrationFields.role)} 
													label="Role*"
													errors={errors} 
												/>
											</Col>
											<Col>
												<InputField
													{...register(registrationFields.phoneNumber)}
													label="Phone number*"
													errors={errors}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row className="mt-5">
									<Col xs={12} md={12} lg={3}>
										<h2>3. Company</h2>
									</Col>

									<Col>
										<Row>
											<Col>
												<InputField
													{...register(registrationFields.companyName)}
													label="Name*"
													errors={errors}
												/>
											</Col>
											<Col className="position-relative">
												<InputField
													{...register(registrationFields.companyVat)}
													label="VAT number*"
													onBlur={(event) => { return validateVAT(event.target.value); }}
													errors={errors}
												/>
												{showVatCheck && (
													<Icon
														name="fa fa-check"
														className="text-success"
														style={{
															position: 'absolute',
															top: '35px',
															right: '25px',
														}}
													/>
												)}
											</Col>
										</Row>

										<Row>
											<Col>
												<Select
													{...register(registrationFields.companyMarketSector)}
													label="Market sector*"
													emptyOption="Please select"
													options={buildSelectOptions(marketSectors)}
													errors={errors}
												/>
											</Col>
											<Col>
												<Select
													{...register(registrationFields.companyNumberEmployees)}
													label="Number of employees*"
													emptyOption="Please select"
													options={buildSelectOptions(employeesNumber)}
													optionLabel={[]}
													errors={errors}
												/>
											</Col>
										</Row>

										<Row>
											<Col>
												<Select
													{...register(registrationFields.companyCountry)}
													label="Country*"
													emptyOption="Select country"
													options={buildSelectOptions(countries)}
													errors={errors}
													onChange={(event) => {
														if (
															event.target.value !== country &&
															(event.target.value === 'Italy' || country === 'Italy')
														) {
															setValue(registrationFields.companyState, '', true);
														}

														setCountry(event.target.value);
														setValue(registrationFields.companyCountry, event.target.value, true);
													}}
													onInput={(event) => {
														if (event.target.value) {
															clearErrors(registrationFields.companyCountry);
														}
													}}
												/>
											</Col>
											<Col>
												<InputSelectCombo
													{...register(registrationFields.companyState)}
													label="Province*"
													showSelect={country === 'Italy'}
													emptyOption="Select province"
													options={buildSelectOptions(
														provinces,
														(province) => { return `${province.abbreviation} (${province.name})`; },
														(province) => { return province.abbreviation; }
													)}
													errors={errors}
													onChange={(event) => {
														if (event.target.value) {
															setValue(registrationFields.companyState, event.target.value, true);
															clearErrors(registrationFields.companyState);
														}
													}}
													onInput={(event) => {
														if (event.target.value) {
															clearErrors(registrationFields.companyState);
														}
													}}
												/>
											</Col>
											<Col>
												<InputField
													{...register(registrationFields.companyCity)}
													label="City*"
													errors={errors}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={8}>
												<InputField
													{...register(registrationFields.companyAddress)}
													label="Address*"
													errors={errors}
												/>
											</Col>
											<Col>
												<InputField
													{...register(registrationFields.companyPostalCode)}
													label="Postal Code*"
													errors={errors}
												/>
											</Col>
										</Row>
										<Row>
											<Col>
												<InputField
													{...register(registrationFields.companyEmail)}
													label="Email*"
													errors={errors}
												/>
											</Col>
											<Col>
												<InputField
													{...register(registrationFields.companyWebsite)}
													label="Website*"
													errors={errors}
												/>
											</Col>
										</Row>
									</Col>
								</Row>

								<Row className="mt-5">
									<Col xs={12} md={12} lg={3}>
										<h2>4. Consent</h2>
									</Col>

									<Col>
										<InfoIcon placement="top" text="We will send you infrequent newsletters regarding our products and services" />
										<Checkbox
											{ ...register(registrationFields.subscribeNews)}
											id="subscribeNews"
											label="Send me emails about technical news"
											errors={errors}
											icon={<InfoIcon placement="top" text="We will send you infrequent newsletters regarding our products and services" />}
											onChange={(event) => { return setValue(registrationFields.subscribeNews, event.target.checked); }}
										/>
										<Checkbox
											{ ...register(registrationFields.subscribeProduct)}
											id="subscribeProduct"
											label="Send me emails about issues, updates and patches"
											errors={errors}
											icon={<InfoIcon placement="top" text="We will notify you about issues affecting the functionality or security of your account" />}
											onChange={(event) => { return setValue(registrationFields.subscribeProduct, event.target.checked); }}
										/>
										<Checkbox
											{ ...register(registrationFields.subscribeSatisfactionSurveys)}
											id="subscribeSatisfactionSurveys"
											label="Send me customer satisfaction surveys"
											errors={errors}
											icon={<InfoIcon placement="top" text="We will send you infrequent customer satisfaction surveys" />}
											onChange={(event) => { return setValue(registrationFields.subscribeSatisfactionSurveys, event.target.checked); }
											}
										/>
										<Checkbox
											{ ...register(registrationFields.dataCollectionConsent)}
											id="dataCollectionConsent"
											label="I consent to my submitted data being collected and stored."
											errors={errors}
											icon={
												<a
													href="https://syneto.eu/privacy-policy/"
													target="_blank"
													rel="noopener noreferrer"
													className="color-type-default font-weight-bold ms-1"
												>
													Privacy policy
												</a>
											}
											onChange={(event) => { return setValue(registrationFields.dataCollectionConsent, event.target.checked); }}
										/>

										<Alert variant="info" className="d-flex align-items-center">
											<Icon name="fa fa-info-circle" size="lg" color="#22a0e9" className="me-3" />
											<span>
												This account requires a 2-Step Verification. For this you can use Google Authenticator, or any
												other 2-Step Verification App downloaded on your phone or PC. The app can be set up using a QR
												code during your first log-in in Central.
											</span>
										</Alert>
									</Col>
								</Row>

								<div className="mt-5 d-flex flex-row-reverse">
									<Button width={96} type="submit">
										Submit
									</Button>

									<Link to="/" className="me-3">
										<Button width={96} role="secondary">
											Cancel
										</Button>
									</Link>
								</div>
							</form>
						</>
					}
				/>
			</Container>

			<Modal
				show={showVatCheckModal}
				onHide={() => { return setShowVatCheckModal(false); }}
				content="Please note that the VAT number is invalid."
				title="Warning"
				actions={{
					secondary: {
						label: 'Close',
						onClick: () => { return setShowVatCheckModal(false); },
					},
				}}
			/>
		</>
	);
};
