import React, { memo, useContext } from 'react';
import { Dropdown, Tooltip, Icon } from '@syneto/compass-react';
import { RasContext } from '../../../contexts/RasContext';
import { StoreContext } from '../../../contexts/StoreContext';
import InfoIcon from '../../../components/Form/InfoIcon';

export const RasDeploymentDropdown = memo((props) => {
	const { label, onSelect } = props;
	const { locationsWithRas, locationsWithoutRas, locationsAvailableForRas, locationsUnavailableForRas } =
    useContext(RasContext);

	const { state } = useContext(StoreContext);
	const { services } = state;

	return (
		<Dropdown label={label} onSelect={onSelect} disabledToggle={!services?.ras?.active}>
			<Dropdown.Menu>
				{locationsWithoutRas.length ? (
					<>
						{locationsAvailableForRas.length ? (
							<>
								<Dropdown.Header>Available</Dropdown.Header>
								{locationsAvailableForRas.map((location) => {
									return (
										<Dropdown.Item key={location.id} eventKey={location.name} style={{ width: 300, overflow: 'auto' }}>
											<div className="d-flex justify-content-between">{location.name}</div>
										</Dropdown.Item>
									);
								})}
							</>
						) : null}

						{locationsUnavailableForRas.length ? (
							<>
								<Dropdown.Header>Unavailable</Dropdown.Header>
								{locationsUnavailableForRas.map((location) => {
									return (
										<Dropdown.Item
											key={location.id}
											eventKey={location.name}
											disabled={true}
											style={{ width: 300, overflow: 'auto' }}
										>
											<div className="d-flex justify-content-between">
												{location.name}
												<InfoIcon 
													placement="top" 
													tooltipStyle="pt-1 ps-2" 
													text="Please update at least one device to Syneto OS 4.6.0 or later to benefit from being able to use the Remote Access Service on your infrastructure." 
													iconColor="#c09500" 
													iconType="exclamation" 
													iconOutline="triangle"
												/>
											</div>
										</Dropdown.Item>
									);
								})}
							</>
						) : null}
					</>
				) : (
					<Dropdown.Item disabled={true}>
						{locationsWithRas.length ? 'RAS is deployed on all your locations' : 'No locations'}
					</Dropdown.Item>
				)}
			</Dropdown.Menu>
		</Dropdown>
	);
});
