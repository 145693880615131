export const rasFields = {
	externalNetworkConfigurationMethod: 'externalNetworkConfigurationMethod',
	internalNetworkConfigurationMethod: 'internalNetworkConfigurationMethod',
	gatewayMachineSerialNumber: 'gatewayMachineSerialNumber',
	fqdn: 'fqdn',
	deploymentMethod: 'deploymentMethod',
	externalNetwork: 'externalNetwork',
	externalIP: 'externalIP',
	dns: 'dns',
	gatewayIP: 'gatewayIP',
	internalNetwork: 'internalNetwork',
	internalSettingsType: 'internalSettingsType',
	internalIP: 'internalIP',
	publicIpOrFqdn: 'publicIpOrFqdn',
	route: 'route',
	selectDestinations: 'selectDestinations',
	name: 'name',
	password: 'password',
	networkDestinations: 'networkDestinations'
};
