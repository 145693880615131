import React from 'react';
import '../../style.scss';
import PropTypes from 'prop-types';

export const RasLocation = (props) => {
	const { rasLocation, showIdentityVerificationModal } = props;
	return (
		<div className="ras-location-card">
			<div
				className="ras-location-card-top d-flex align-items-center justify-content-center"
				onClick={showIdentityVerificationModal}
			>
				<div>
					<i className="fas fa-cloud-download-alt download-icon d-flex justify-content-center w-100" />
					<div className="download-text">Download</div>
				</div>
			</div>

			<h5 className="ras-location-card-name">{rasLocation.name}</h5>
		</div>
	);
};

RasLocation.propTypes = {
	rasLocation: PropTypes.object.isRequired,
	showIdentityVerificationModal: PropTypes.func.isRequired,
};
