export const marketSectors = [
	'Charity',
	'Commercial',
	'Communication',
	'Enterprise',
	'Entertainment',
	'Financial',
	'Gaming',
	'Healthcare',
	'Education',
	'Legal',
	'Manufacturing',
	'Public sector',
	'Retail',
	'Transportation',
	'Utilities',
	'Information technology',
].sort();
