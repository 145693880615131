import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@syneto/compass-react';
import { StoreContext } from '../../../contexts/StoreContext';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { DashboardContext } from '../../../contexts/DashboardContext';

export const DeleteLocationModal = (props) => {
	const { show, onHide, onHideEditModal, location } = props;
	const { getData } = useContext(DashboardContext);
	const { locationService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);

	const [submitting, setSubmitting] = useState(false);

	useEffect(() => {
		if (!show) {
			setSubmitting(false);
		}
	}, [show]);

	const onSubmit = async () => {
		try {
			setSubmitting(true);
			await locationService.removeLocation(loggedInUser.company.id, location.id);
			await getData();
			onHide();
			onHideEditModal();
			pushSuccessfulNotification('Location removed successfully.');
		} catch (error) {
			pushDangerNotification('Failed to remove location.');
			setSubmitting(false);
		}
	};

	return (
		<Modal
			show={show}
			onHide={onHide}
			content={`Are you sure you want to delete the ${location.name} location?`}
			title="Confirm Delete"
			actions={{
				primary: {
					label: 'Delete',
					onClick: onSubmit,
					disabled: submitting,
				},
				secondary: {
					label: 'Cancel',
					onClick: onHide,
				},
			}}
		/>
	);
};

DeleteLocationModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	onHideEditModal: PropTypes.func.isRequired,
	location: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
		address: PropTypes.string.isRequired,
		machineSerialNumbers: PropTypes.array,
	}).isRequired,
};
