import { gql } from 'apollo-boost';

export class AuthorizationService {
	constructor(graphqlClient) {
		this.graphqlClient = graphqlClient;
	}

	async authorize(resourceType, resourceReferenceId, permissionName) {
		const authorizationResponse = await this.graphqlClient.query({
			query: gql`
        query authorize($resourceType: String!, $resourceReferenceId: String!, $permissionName: String!) {
          authorize(
            resourceType: $resourceType
            resourceReferenceId: $resourceReferenceId
            permissionName: $permissionName
          )
        }
      `,
			variables: {
				resourceType: resourceType,
				resourceReferenceId: resourceReferenceId,
				permissionName: permissionName,
			},
			fetchPolicy: 'no-cache',
		});

		return authorizationResponse.data.authorize;
	}
}
