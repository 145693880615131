import React, { useEffect, useState, forwardRef } from 'react';
import { Form } from '@syneto/compass-react';

export const InputSelectCombo = forwardRef((props, ref) => {
	const { label, icon, id, required } = props;
	const { name, placeholder, style, className, maxLength, pattern, onChange, onFocus, onBlur, onInput } =
    props;
	const { disabled, readOnly, errors, input, defaultValue } = props;
	const { showSelect, emptyOption, options } = props;

	const [didMount, setDidMount] = useState(false);
	const [selectValue, setSelectValue] = useState(defaultValue);

	useEffect(() => {
		setDidMount(true);
	}, []);

	useEffect(() => {
		if (didMount) {
			setSelectValue('');
		}
	}, [showSelect]);

	return (
		<Form.Group className="mb-3" controlId={id}>
			{label ? (
				<Form.Label>
					{label}
					{required ? '*' : null} {icon ? icon : null}
				</Form.Label>
			) : null}

			<Form.Control
				as="select"
				name={`${name}_select`}
				isInvalid={errors && errors[name]}
				onChange={(event) => {
					setSelectValue(event.target.value);
					onChange(event);
				}}
				style={{ display: showSelect ? 'block' : 'none' }}
				value={selectValue}
			>
				{emptyOption && <option value={''}>{emptyOption}</option>}
				{options}
			</Form.Control>

			<Form.Control
				type="text"
				name={name}
				placeholder={placeholder}
				ref={ref}
				style={style}
				className={className}
				maxLength={maxLength}
				pattern={pattern}
				disabled={disabled}
				readOnly={readOnly}
				isInvalid={errors && errors[name]}
				onFocus={onFocus}
				onBlur={onBlur}
				onInput={onInput}
				defaultValue={defaultValue}
				hidden={!!showSelect}
				{...input}
			/>

			<Form.Control.Feedback type="invalid">{errors && errors[name] && errors[name].message}</Form.Control.Feedback>
		</Form.Group>
	);
});
