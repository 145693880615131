import React, { useContext, useState } from 'react';
import { Card, Button } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import './style.scss';
import { NotificationContext } from '../../contexts/NotificationContext';
import { InputField } from '../../components/Form';
import { ServiceContext } from '../../contexts/ServiceContext';
import { rEmail } from '../../constants/validation';

export const passwordRecoveryValidationSchema = yup.object().shape({
	email: yup.string().required('Cannot be empty').trim().matches( rEmail , 'Email format is incorrect'),
});

export const PasswordRecovery = () => {
	const { userService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(passwordRecoveryValidationSchema),
	});
	const [passwordResetEmailSent, setPasswordResetEmailSent] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	const onSubmit = async (values) => {
		try {
			setSubmitting(true);
			await userService.sendUserPasswordResetEmail(values.email);
			pushSuccessfulNotification(`Password reset instructions successfully sent to ${values.email}.`);
			setPasswordResetEmailSent(true);
			setSubmitting(false);
		} catch (error) {
			pushDangerNotification(`Failed to send password reset instructions. ${error.message ? error.message : ''}`);
			setSubmitting(false);
		}
	};

	return (
		<div className="authentication min-vh-100">
			<div className="container d-flex justify-content-center">
				<Card
					title={'Password recovery'}
					content={
						passwordResetEmailSent ? (
							<p>Please check your email address for instructions on how to reset your password.</p>
						) : (
							<form onSubmit={handleSubmit(onSubmit)} className="mt-4">
								<InputField {...register('email')} label="Email address" errors={errors} />
								<Button type="submit" disabled={submitting}>
									Send
								</Button>
							</form>
						)
					}
					className="password-recovery-card rounded"
				/>
			</div>
		</div>
	);
};
