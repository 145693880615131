// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../src/assets/img/no-users-data.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../src/assets/img/no-user-details.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-users-data{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-position:center}.no-user-details{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___});background-repeat:no-repeat;background-size:contain;background-position:top center}.add-user-button{top:70%;left:40%}`, "",{"version":3,"sources":["webpack://./src/pages/Users/style.scss"],"names":[],"mappings":"AAAA,eACE,wDAAA,CACA,2BAAA,CACA,0BAAA,CAGF,iBACE,wDAAA,CACA,2BAAA,CACA,uBAAA,CACA,8BAAA,CAGF,iBACE,OAAA,CACA,QAAA","sourcesContent":[".no-users-data {\n  background-image: url(\"../../../src/assets/img/no-users-data.svg\");\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n.no-user-details {\n  background-image: url(\"../../../src/assets/img/no-user-details.svg\");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: top center;\n}\n\n.add-user-button {\n  top: 70%;\n  left: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
