// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/login-background-image.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.digitField{width:47px;height:54px;border:2px solid #3646c4;font-size:26px;font-weight:bold;line-height:30px;padding:0;text-align:center}.authentication{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-position:50% 0;background-size:cover;display:flex;justify-content:center;align-items:center;height:100%;width:100%}.authentication .container{max-width:731px}.authentication .container .card{border:0;border-radius:0}.authentication .container .card.mfa{width:100%;max-width:400px}`, "",{"version":3,"sources":["webpack://./src/pages/Authentication/_mfaFields.scss","webpack://./src/pages/Authentication/style.scss"],"names":[],"mappings":"AAAA,YACE,UAAA,CACA,WAAA,CACA,wBAAA,CACA,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,SAAA,CACA,iBAAA,CCNF,gBACE,wDAAA,CACA,2BAAA,CACA,yBAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,UAAA,CAEA,2BACE,eAAA,CAEA,iCACE,QAAA,CACA,eAAA,CAGF,qCACE,UAAA,CACA,eAAA","sourcesContent":[".digitField {\n  width: 47px;\n  height: 54px;\n  border: 2px solid #3646c4;\n  font-size: 26px;\n  font-weight: bold;\n  line-height: 30px;\n  padding: 0;\n  text-align: center;\n}\n","@import \"../Authentication/_mfaFields.scss\";\n\n.authentication {\n  background-image: url(\"../../assets/img/login-background-image.png\");\n  background-repeat: no-repeat;\n  background-position: 50% 0;\n  background-size: cover;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n\n  .container {\n    max-width: 731px;\n\n    .card {\n      border: 0;\n      border-radius: 0;\n    }\n\n    .card.mfa {\n      width: 100%;\n      max-width: 400px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
