import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams, us } from 'react-router-dom';
import { Row, Col, Container, Icon, Button, Card, Spinner, Alert } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContext } from '../../contexts/NotificationContext';
import { ServiceContext } from '../../contexts/ServiceContext';
import { InputField, Checkbox } from '../../components/Form';
import { invitationRegistrationValidationSchema } from './validation';
import { invitationRegistrationFields } from './fields';
import InfoIcon from '../../components/Form/InfoIcon';

export const InvitationRegistration = () => {
	const { userService, tokenService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);

	const { register, unregister, handleSubmit, setValue, formState: { errors } } = useForm({
		resolver: yupResolver(yup.object().shape(invitationRegistrationValidationSchema)),
	});

	const navigate = useNavigate();
	let { hash } = useParams();

	const [tokenValid, setTokenValid] = useState(undefined);

	useEffect(() => {
		verifyTokenHash();

		register(invitationRegistrationFields.subscribeProduct);
		register(invitationRegistrationFields.subscribeNews);
		register(invitationRegistrationFields.subscribeSatisfactionSurveys);
		register(invitationRegistrationFields.dataCollectionConsent);

		setValue(invitationRegistrationFields.subscribeProduct, false);
		setValue(invitationRegistrationFields.subscribeNews, false);
		setValue(invitationRegistrationFields.subscribeSatisfactionSurveys, false);
		setValue(invitationRegistrationFields.dataCollectionConsent, false);

		return () => {
			unregister(invitationRegistrationFields.subscribeProduct);
			unregister(invitationRegistrationFields.subscribeNews);
			unregister(invitationRegistrationFields.subscribeSatisfactionSurveys);
			unregister(invitationRegistrationFields.dataCollectionConsent);
		};
	}, []);

	const verifyTokenHash = async () => {
		try {
			const isTokenValid = await tokenService.verifyTokenHash(encodeURIComponent(hash));
			setTokenValid(isTokenValid);
		} catch (error) {
			setTokenValid(false);
		}
	};

	const onSubmit = async (values) => {
		try {
			await userService.addUser({
				[invitationRegistrationFields.hash]: encodeURIComponent(hash),
				...values,
			});
			navigate('/authentication');
			pushSuccessfulNotification('User registered successfully.');
		} catch (error) {
			pushDangerNotification(error.message.replace(/GraphQL error:/g, '').trim());
		}
	};

	if (tokenValid === undefined) {
		return (
			<div className="d-flex justify-content-center w-100 h-100">
				<Spinner animation="border" variant="primary" className="align-self-center" />
			</div>
		);
	}

	return (
		<Container>
			<Card
				className="px-3 mt-2 mb-4"
				content={
					tokenValid ? (
						<form onSubmit={handleSubmit(onSubmit)}>
							<Row>
								<Col xs={12} md={12} lg={3}>
									<h2>1. Account</h2>
								</Col>

								<Col>
									<Row>
										<Col xs={12} md={6}>
											<InputField
												{...register(invitationRegistrationFields.password)}
												label="Password*"
												type="password"
												errors={errors}
											/>
										</Col>

										<Col xs={12} md={6}>
											<InputField
												{...register(invitationRegistrationFields.checkPassword)}
												label="Re-type password*"
												type="password"
												errors={errors}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row className="mt-5">
								<Col xs={12} md={12} lg={3}>
									<h2>
										2. Contact details
										<InfoIcon placement="top" tooltipStyle="ms-1" text="Used for important communications regarding your account" iconType="question" iconSize="xs" />
									</h2>
								</Col>

								<Col>
									<Row>
										<Col>
											<InputField
												{...register(invitationRegistrationFields.firstName)}
												label="First name*"
												errors={errors}
											/>
										</Col>
										<Col>
											<InputField
												{...register(invitationRegistrationFields.lastName)}
												label="Last name*"
												errors={errors}
											/>
										</Col>
									</Row>

									<Row>
										<Col>
											<InputField
												{...register(invitationRegistrationFields.role)}
												label="Role"
												errors={errors}
											/>
										</Col>
										<Col>
											<InputField
												{...register(invitationRegistrationFields.phoneNumber)}
												label="Phone number"
												errors={errors}
											/>
										</Col>
									</Row>
								</Col>
							</Row>

							<Row className="mt-5">
								<Col xs={12} md={12} lg={3}>
									<h2>3. Consent</h2>
								</Col>

								<Col>
									<Checkbox
										id="subscribeNews"
										{...register(invitationRegistrationFields.subscribeNews)}
										label="Send me emails about technical news"
										errors={errors}
										icon={
											<InfoIcon placement="top" text="We will send you infrequent newsletters regarding our products and services" iconType="question" />
										}
										onChange={(event) => { return setValue(invitationRegistrationFields.subscribeNews, event.target.checked); }}
									/>
									<Checkbox
										id="subscribeProduct"
										{...register(invitationRegistrationFields.subscribeProduct)}
										label="Send me emails about issues, updates and patches"
										errors={errors}
										icon={
											<InfoIcon placement="top" text="We will notify you about issues affecting the functionality or security of your account" iconType="question" />
										}
										onChange={(event) => { return setValue(invitationRegistrationFields.subscribeProduct, event.target.checked); }}
									/>
									<Checkbox
										id="subscribeSatisfactionSurveys"
										{...register(invitationRegistrationFields.subscribeSatisfactionSurveys)}
										label="Send me customer satisfaction surveys"
										errors={errors}
										icon={
											<InfoIcon placement="top" text="We will send you infrequent customer satisfaction surveys" iconType="question" />
										}
										onChange={(event) => { 
											return setValue(invitationRegistrationFields.subscribeSatisfactionSurveys, event.target.checked); 
										}}
									/>
									<Checkbox
										id="dataCollectionConsent"
										{...register(invitationRegistrationFields.dataCollectionConsent)}
										label="I consent to my submitted data being collected and stored."
										errors={errors}
										icon={
											<a
												href="https://syneto.eu/privacy-policy/"
												target="_blank"
												rel="noopener noreferrer"
												className="color-type-default font-weight-bold ms-1"
											>
												Privacy policy
											</a>
										}
										onChange={(event) => { 
											return setValue(invitationRegistrationFields.dataCollectionConsent, event.target.checked); 
										}}
									/>

									<Alert variant="info" className="d-flex align-items-center">
										<Icon name="fa fa-info-circle" size="lg" color="#22a0e9" className="me-3" />
										<span>
											This account requires a 2-Step Verification. For this you can use Google Authenticator, or any
											other 2-Step Verification App downloaded on your phone or PC. The app can be set up using a QR
											code during your first log-in in Central.
										</span>
									</Alert>
								</Col>
							</Row>

							<div className="mt-5 d-flex flex-row-reverse">
								<Button width={96} type="submit">
									Submit
								</Button>

								<Link to="/" className="me-3">
									<Button width={96} role="secondary">
										Cancel
									</Button>
								</Link>
							</div>
						</form>
					) : (
						<p>Account already created or invitation is no longer available.</p>
					)
				}
			/>
		</Container>
	);
};
