import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import queryString from 'query-string';
import { NotificationContext } from '../../contexts/NotificationContext';
import { StoreContext } from '../../contexts/StoreContext';
import { ServiceContext } from '../../contexts/ServiceContext';
import { Loading } from '../../components/Loading';


export const Impersonate = (() => {
	const location = useLocation();
	const { authenticationService, userService } = useContext(ServiceContext);
	const { pushDangerNotification } = useContext(NotificationContext);
	const { dispatch } = useContext(StoreContext);
	const { email, token } = queryString.parse(location.search);

	const [loginResponse, setLoginResponse] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function loginWithOneTimeToken(email, token) {
			try {
				const authenticationResponse = await authenticationService.authenticateWithOneTimeToken(
					decodeURIComponent(email),
					decodeURIComponent(token)
				);
				dispatch({
					payload: {
						loggedInUser: await userService.getLoggedInUser()
					},
				});
				setLoginResponse(authenticationResponse);
				setLoading(false);
			} catch (error) {
				setLoginResponse(null);
				setLoading(false);
			}
		}

		loginWithOneTimeToken(email, token);
	}, []);

	if (loading) {
		return <Loading />;
	}

	if (loginResponse !== null) {
		return <Navigate to="/dashboard" />;
	}

	pushDangerNotification('Failed to impersonate user.');
	return <Navigate to="/authentication" />;
});
