import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Button } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { NotificationContext } from '../../contexts/NotificationContext';
import { InputField } from '../../components/Form';
import { ServiceContext } from '../../contexts/ServiceContext';
import './style.scss';

export const passwordResetValidationSchema = yup.object().shape({
	password: yup.string().required('Cannot be empty').min(6, 'The password has to have minimum 6 characters'),
	checkPassword: yup
		.string()
		.required('Cannot be empty')
		.test('passwords-match', 'Passwords do not match', function (value) {
			return this.parent.password === value;
		}),
});

export const PasswordReset = () => {
	const { userService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(passwordResetValidationSchema)
	});

	let { passwordResetToken } = useParams();

	const [submitting, setSubmitting] = useState(false);

	const navigate = useNavigate();

	const onSubmit = async (values) => {
		try {
			setSubmitting(true);
			await userService.resetUserPassword(passwordResetToken, values.password);
			pushSuccessfulNotification('Password changed successfully.');
			setSubmitting(false);
			navigate('/authentication');
		} catch (error) {
			pushDangerNotification('Failed to change password.');
			setSubmitting(false);
		}
	};

	return (
		<div className="authentication min-vh-100">
			<div className="container d-flex justify-content-center">
				<Card
					title={'Password reset'}
					content={
						<form onSubmit={handleSubmit(onSubmit)} className="mt-4">
							<InputField {...register('password')} label="Password*" type="password" errors={errors} />
							<InputField {...register('checkPassword')} label="Re-type password*" type="password" errors={errors}
							/>

							<Button type="submit" disabled={submitting}>
								Send
							</Button>
						</form>
					}
					className="password-reset-card rounded"
				/>
			</div>
		</div>
	);
};
