export const restrictInputDestinationsName = (event) => {
	if (
		event.charCode === 32 || // space
    (event.charCode >= 45 && event.charCode <= 46) || // dash and dot
    (event.charCode >= 48 && event.charCode <= 57) || // numbers
    (event.charCode >= 65 && event.charCode <= 90) || // uppercase chars
    event.charCode === 95 || // underscore
    (event.charCode >= 97 && event.charCode <= 122) || // lowercase chars
    (event.charCode >= 128 && event.charCode <= 165) // accented characters chars)
	) {
		return true;
	}

	return event.preventDefault();
};
