import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from '@syneto/compass-react';
import { Checkbox, InputField } from '../../../components/Form';
import { profileFields } from '../fields';
import InfoIcon from '../../../components/Form/InfoIcon';

export const ProfileTab = (props) => {
	const { register, errors, setValue, clearErrors, loggedInUser } = props;

	const [subscribeNews, setSubscribeNews] = useState(loggedInUser.profile.subscribeToNews);
	const [subscribeProduct, setSubscribeProduct] = useState(loggedInUser.profile.subscribeToProductNews);
	const [subscribeSatisfactionSurveys, setSubscribeSatisfactionSurveys] = useState(
		loggedInUser.profile.subscribeToSatisfactionSurveys
	);
	const [dataCollectionConsent, setDataCollectionConsent] = useState(loggedInUser.profile.dataCollectionConsent);

	return (
		<>
			<Row>
				<Col>
					<h5>User Details</h5>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col xs={12} md={12} lg={3}>
					<span className="text-muted">Account</span>
				</Col>

				<Col>
					<InputField
						label="Email"
						defaultValue={loggedInUser.email}
						disabled={true}
					/>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col xs={12} md={12} lg={3}>
					<span className="text-muted">Contact Details</span>
				</Col>

				<Col>
					<Row>
						<Col>
							<InputField
								{...register(profileFields.firstName)}
								label="First Name"
								defaultValue={loggedInUser.profile.firstName}
								errors={errors}
								required
							/>
						</Col>

						<Col>
							<InputField
								{...register(profileFields.lastName)}
								label="Last Name"
								defaultValue={loggedInUser.profile.lastName}
								errors={errors}
								required
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<InputField
								{...register(profileFields.role)}
								label="Role"
								defaultValue={loggedInUser.profile.roleInTheCompany}
								errors={errors}
								required
							/>
						</Col>

						<Col>
							<InputField
								{...register(profileFields.phoneNumber)}
								label="Phone Number"
								defaultValue={loggedInUser.profile.phoneNumber}
								errors={errors}
								required
							/>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col xs={12} md={12} lg={3}>
					<span className="text-muted">Notification Preferences</span>
				</Col>

				<Col>
					<Checkbox
						id="subscribeNews"
						name={profileFields.subscribeNews}
						label="Send me emails about technical news"
						errors={errors}
						icon={
							<InfoIcon placement="top" text="We will send you infrequent newsletters regarding our products and services" iconType="question"/>
						}
						onChange={(event) => {
							setValue(profileFields.subscribeNews, event.target.checked);
							setSubscribeNews(event.target.checked);
						}}
						checked={subscribeNews}
					/>

					<Checkbox
						id="subscribeProduct"
						name={profileFields.subscribeProduct}
						label="Send me emails about issues, updates and patches"
						errors={errors}
						icon={
							<InfoIcon placement="top" text="We will notify you about issues affecting the functionality or security of your account" iconType="question"/>
						}
						onChange={(event) => {
							setValue(profileFields.subscribeProduct, event.target.checked);
							setSubscribeProduct(event.target.checked);
						}}
						checked={subscribeProduct}
					/>
					<Checkbox
						id="subscribeSatisfactionSurveys"
						name={profileFields.subscribeSatisfactionSurveys}
						label="Send me customer satisfaction surveys"
						errors={errors}
						icon={
							<InfoIcon placement="top" text="We will send you infrequent customer satisfaction surveys" iconType="question"/>
						}
						onChange={(event) => {
							setValue(profileFields.subscribeSatisfactionSurveys, event.target.checked);
							setSubscribeSatisfactionSurveys(event.target.checked);
						}}
						checked={subscribeSatisfactionSurveys}
					/>
				</Col>
			</Row>

			<Row className="mt-4">
				<Col xs={12} md={12} lg={3}>
					<span className="text-muted">Data Collection</span>
				</Col>

				<Col>
					<Checkbox
						id="dataCollectionConsent"
						name={profileFields.dataCollectionConsent}
						label="I consent to my submitted data being collected and stored."
						errors={errors}
						icon={
							<a
								href="https://syneto.eu/privacy-policy/"
								target="_blank"
								rel="noopener noreferrer"
								className="color-type-default font-weight-bold ms-1"
							>
								Privacy policy
							</a>
						}
						onChange={(event) => {
							setValue(profileFields.dataCollectionConsent, event.target.checked);
							setDataCollectionConsent(event.target.checked);
							if (event.target.checked) {
								clearErrors(profileFields.dataCollectionConsent);
							}
						}}
						checked={dataCollectionConsent}
						disabled={loggedInUser.profile.dataCollectionConsent}
					/>
				</Col>
			</Row>
		</>
	);
};

ProfileTab.propTypes = {
	register: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	setValue: PropTypes.func.isRequired,
	loggedInUser: PropTypes.shape({
		id: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		profile: PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
			phoneNumber: PropTypes.string.isRequired,
			roleInTheCompany: PropTypes.string.isRequired,
			hasVirtualApplianceAccess: PropTypes.bool,
			subscribeToNews: PropTypes.bool.isRequired,
			subscribeToProductNews: PropTypes.bool.isRequired,
			subscribeToSatisfactionSurveys: PropTypes.bool.isRequired,
			dataCollectionConsent: PropTypes.bool.isRequired,
		}).isRequired,
		company: PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			marketSector: PropTypes.string.isRequired,
			city: PropTypes.string.isRequired,
			province: PropTypes.string.isRequired,
			country: PropTypes.string.isRequired,
			address: PropTypes.string.isRequired,
			numberOfEmployees: PropTypes.string.isRequired,
			vat: PropTypes.string.isRequired,
			postalCode: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired,
			website: PropTypes.string.isRequired,
			locationIds: PropTypes.array.isRequired,
		}).isRequired,
	}).isRequired,
};
