import React from 'react';

export const OpenVPNSteps = () => {
	return (
		<div>
			<p className="font-size-20 text-muted font-weight-light">Step 1</p>
			<p className="text-muted font-weight-light">
        Open the OpenVPN Connect client, go to the menu and select "Import Profile"
			</p>
			<p className="font-size-20 text-muted font-weight-light">Step 2</p>
			<p className="text-muted font-weight-light">
        Select the "File" tab, navigate to the .ovpn configuration file on your computer and import it
			</p>
			<p className="font-size-20 text-muted font-weight-light">Step 3</p>
			<p className="text-muted font-weight-light">Introduce the username and click on the Add button</p>
			<p className="font-size-20 text-muted font-weight-light">Step 4</p>
			<p className="text-muted font-weight-light">
        Switch the toggle of your profile, in order to connect and enter your Central account password and your Syneto
        Central MFA PIN. After you introduce the correct credentials you should get the Connected message.
			</p>
		</div>
	);
};
