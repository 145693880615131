import { gql } from 'apollo-boost';

export class DestinationService {
	constructor(graphqlClient) {
		this.graphqlClient = graphqlClient;
	}

	async setDestinations(locationId, destinations) {
		const setDestinationsResponse = await this.graphqlClient.mutate({
			mutation: gql`
        mutation ($locationId: String!, $destinations: [AddDestinationInput]!) {
          setDestinations(locationId: $locationId, destinations: $destinations)
        }
      `,
			variables: {
				locationId: locationId,
				destinations: destinations,
			},
			fetchPolicy: 'no-cache',
		});

		return setDestinationsResponse.data.setDestinations;
	}

	async destinationsByLocationId(locationId) {
		const destinationsByLocationIdResponse = await this.graphqlClient.query({
			query: gql`
        query destinationsByLocationId($locationId: String!) {
          destinationsByLocationId(locationId: $locationId) {
            id
            createdAt
            updatedAt
            locationId
            name
            networkDestinations
          }
        }
      `,
			variables: {
				locationId: locationId,
			},
			fetchPolicy: 'no-cache',
		});

		return destinationsByLocationIdResponse.data.destinationsByLocationId;
	}

	filterDestinationsByFormat(destinations, formats) {
		const destinationsMatchingFormat = [];

		for (const destination of destinations) {
			let allNetworkDestinationsMatch = true;

			for (const networkDestination of destination.networkDestinations) {
				if (!this.networkDestinationMatchesAnyFormat(networkDestination, formats)) {
					allNetworkDestinationsMatch = false;
					break;
				}
			}

			if (allNetworkDestinationsMatch) {
				destinationsMatchingFormat.push(destination);
			}
		}

		return destinationsMatchingFormat;
	}

	networkDestinationMatchesAnyFormat(networkDestination, formats) {
		const formatRegExpMappings = {
			ip: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/g,
			ipAndMask: /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]).){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/g,
			allIpsWithPort: /^(\*:[0-9]+)$/g
		};

		let networkDestinationMatchesAtLeastOneFormat = false;
		for (const networkDestinationFormat of formats) {
			if (formatRegExpMappings[networkDestinationFormat].test(networkDestination)) {
				networkDestinationMatchesAtLeastOneFormat = true;
			}
		}

		return networkDestinationMatchesAtLeastOneFormat;
	}
}
