import React from 'react';
import { Tooltip, Icon } from '@syneto/compass-react';

const InfoIcon = ((props) => {
	const { placement, text, trigger, theme, iconType, iconSize, iconStyle, iconOutline, iconColor, tooltipStyle } = props;

	return text ? (
		<Tooltip  placement={placement} text={text} trigger={trigger} theme={theme}>
			<span className={tooltipStyle}>
				<Icon className={iconStyle} name={`far fa-${iconType}-${iconOutline}`} size={iconSize} color={iconColor}/>
			</span>
		</Tooltip>
	) : (
		<Icon name={`far ${iconType}`} size={iconSize} />
	);


});

InfoIcon.defaultProps = {
	placement: 'top',
	trigger: 'hover',
	theme: 'dark',
	iconSize: 'lg',
	iconType: 'info',
	iconOutline: 'circle'
};

export default InfoIcon;
