import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, Icon, Tooltip, Button } from '@syneto/compass-react';
import { RasStatus } from './components/RasStatus';
import { UpdateStatus } from './components/UpdateStatus';
import { MaintenanceStatus } from './components/MaintenanceStatus';
import image from '../../../../assets/img/icon-synetoMachine.svg';
import { Does } from '../../../../components/Access/Does';
import './style.scss';
import { StoreContext } from '../../../../contexts/StoreContext';
import InfoIcon from '../../../../components/Form/InfoIcon';

export const LocationCard = (props) => {
	const { location, showEditLocationModal } = props;

	const { state } = useContext(StoreContext);
	const { services } = state;

	const renderNotApplicable = (text = 'No machines assigned to this location') => {
		return (
			<span>
				N/A
				<InfoIcon placement="top" text={text} iconStyle="pt-1 ms-2"/>
			</span>
		);
	};

	const renderEditButton = () => {
		return (
			<Button role="tertiary" onClick={showEditLocationModal}>Edit</Button>
		);
	};

	return (
		<Card
			className="shadow h-100"
			content={
				<>
					<div className="d-flex justify-content-between border-bottom pb-3">
						<h5 className="m-0 location-card-title">{location.name}</h5>
						<Does
							loggedInUser
							havePermission="location.update"
							onCompanyResource
							yes={renderEditButton}
							no={() => {
								return (
									<Does
										loggedInUser
										havePermission="location.update"
										onResource={{
											type: 'location',
											referenceId: location.id,
										}}
										yes={renderEditButton}
									/>
								); 
							}}
						/>
					</div>
					<div className="d-flex justify-content-center align-items-center my-5">
						<img src={image} width="46" height="39" alt="machine" />
						<h1 className="mx-3 mb-0">{location.machines.length}</h1>
						Machines
					</div>
					<>
						<Does
							loggedInUser
							havePermission="ras.view"
							onAnyResource={true}
							yes={() => {
								return (
									<div className="d-flex justify-content-between">
										{services?.ras?.active || services?.ras?.expired ? (
											<Link to="/ras" className="text-primary">
												<p className="font-weight-bold">Remote Access Service</p>
											</Link>
										) : (
											<p className="text-disabled font-weight-bold">Remote Access Service</p>
										)}

										{location.gatewayMachineSerialNumber ? (
											<RasStatus location={location} />
										) : (
											renderNotApplicable('No gateway device selected on this location')
										)}
									</div>
								); 
							}}
						/>

						<div className="d-flex justify-content-between">
							<Link
								to={{
									pathname: '/inventory',
									state: { locationName: location.name },
								}}
								className="text-primary"
							>
								<p className="font-weight-bold">Maintenance</p>
							</Link>

							{location.machines.length ? <MaintenanceStatus machines={location.machines} /> : renderNotApplicable()}
						</div>

						<div className="d-flex justify-content-between mb-4">
							<Link
								to={{
									pathname: '/inventory',
									state: { locationName: location.name },
								}}
								className="text-primary"
							>
								<p className="font-weight-bold">Updates</p>
							</Link>

							{location.machines.length ? <UpdateStatus machines={location.machines} /> : renderNotApplicable()}
						</div>
					</>
				</>
			}
		/>
	);
};

LocationCard.propTypes = {
	location: PropTypes.object.isRequired,
	showEditLocationModal: PropTypes.func,
};
