import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ServiceContext } from '../../../../../contexts/ServiceContext';
import { StoreContext } from '../../../../../contexts/StoreContext';
import { NotificationContext } from '../../../../../contexts/NotificationContext';
import { RasStatusLabel } from '../../../../../components/RasStatus/RasStatusLabel';
import { Loading } from '../../../../../components/Loading';

export const RasStatus = (props) => {
	const { location } = props;
	const { machineService, rasService } = useContext(ServiceContext);
	const { pushDangerNotification } = useContext(NotificationContext);
	const { state } = useContext(StoreContext);
	const { services } = state;

	const [rasInfo, setRasInfo] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (services?.ras?.active) { loadRasStatus(); }
	}, [services]);

	const loadRasStatus = async () => {
		try {
			setLoading(true);

			const rasInfo = await rasService.getRas(
				location.gatewayMachineSerialNumber,
				machineService.getGatewayMachineOsVersion(location)
			);
			setRasInfo(rasInfo);

			if (rasInfo.status === 'DEPLOYING') {
				setTimeout(loadRasStatus, 10000);
			}

			setLoading(false);
		} catch (error) {
			setLoading(false);
			pushDangerNotification('Failed to fetch RAS status data.');
			showConnectionProblems();
		}
	};

	const showConnectionProblems = () => {
		setRasInfo({ status: 'CONNECTION_PROBLEMS' });
	};

	if (loading) { return <Loading size="sm" type="inline" />; }

	if (services?.ras?.active) { return <RasStatusLabel rasInfo={rasInfo} />; }

	if (services?.ras?.expired) { return <p className="text-danger">Service expired</p>; }

	return null;
};

RasStatus.propTypes = {
	location: PropTypes.object.isRequired,
};
