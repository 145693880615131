import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Spinner, Popover, Icon } from '@syneto/compass-react';
import './style.scss';

export const RasStatusLabel = (props) => {
	const { rasInfo } = props;

	const renderCurrentStep = (step) => {
		return (
			<div className="location-card-popover-container">
				<span>{`${moment.unix(step.dateTime).format('HH:mm')} ${step.message}`}</span>
			</div>
		);
	};

	switch (rasInfo && rasInfo.status) {
		case 'INACTIVE':
			return <span>Inactive</span>;
		case 'ACTIVE':
			return (
				<span>
					Active
					<Icon className="pt-1 ms-2" name="fas fa-check-circle" color="#00b170" size="lg" />
				</span>
			);
		case 'DEPLOYING':
			return (
				<Popover
					trigger="click"
					placement="top"
					body={rasInfo.currentStep && renderCurrentStep(rasInfo.currentStep)}
					width={300}
					className="location-popover"
				>
					<div className="location-card-status-row">
						<span className="text-primary">Deploying... &nbsp;</span>
						<Spinner animation="border" variant="primary" size="sm" className="text-muted" />
					</div>
				</Popover>
			);
		case 'FAILED':
			return (
				<Popover
					trigger="click"
					placement="top"
					body={<div>{rasInfo.currentStep && renderCurrentStep(rasInfo.currentStep)}</div>}
					className="location-popover"
				>
					<div className="location-card-status-row">
						<span className="text-primary">
							Failed to deploy
							<Icon className="pt-1 ms-2" name="fas fa-times-circle" color="#E45664" size="lg" />
						</span>
					</div>
				</Popover>
			);
		case 'CONNECTION_PROBLEMS':
			return (
				<Popover
					trigger="click"
					placement="top"
					body="Can't contact RAS Gateway"
					width={300}
					className="location-popover"
				>
					<div className="location-card-status-row">
						<span className="text-primary">
							Connection Problems{' '}
							<Icon className="pt-1 ms-2" name="fas fa-exclamation-triangle" color="#F4BE00" size="lg" />
						</span>
					</div>
				</Popover>
			);
		default:
			return (
				<Popover
					trigger="click"
					placement="top"
					body="Can't contact RAS Gateway"
					width={300}
					className="location-popover"
				>
					<div className="location-card-status-row">
						<span className="text-primary">
							Connection Problems{' '}
							<Icon className="pt-1 ms-2" name="fas fa-exclamation-triangle" color="#F4BE00" size="lg" />
						</span>
					</div>
				</Popover>
			);
	}
};

RasStatusLabel.propTypes = {
	rasInfo: PropTypes.object,
};
