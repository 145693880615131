export class MonitoringService {
	constructor(backEndAxios) {
		this.backEndAxios = backEndAxios;
		this.apiVersion = 'v1';
	}

	changeAdminPassword = (formData) => {
		return this.backEndAxios.post('/central-backend/monitoring/reset-grafana-password', formData)	
			.then((response) => {
				return response?.data?.message ?? 'Password has been changed!';
			})
			.catch((error) => {
				throw error?.response?.data?.detail ?? 'Failed to Change Password...';
			});
	};

	getCompanyAlertEmailContact = (companyId) => {
		return this.backEndAxios.get(`/central-backend/${this.apiVersion}/fleet-management-crud/get-company-alert-contacts?id=${companyId}`)
			.then((response) => {
				return response?.data?.contacts ?? [];
			})
			.catch((error) => {
				throw error?.response?.data?.detail ?? 'Failed to fetch recipients list...';
			});
	};

	addCompanyAlertEmailContact = (data) => {
		return this.backEndAxios.post(`/central-backend/${this.apiVersion}/fleet-management-crud/add-company-alert-contact`, data)	
			.then((response) => {
				return response?.data?.message ?? 'Password has been changed!';
			})
			.catch((error) => {
				throw error?.response?.data?.detail ?? 'Failed to Change Password...';
			});
	};

	removeCompanyAlertEmailContact = (userId) => {
		return this.backEndAxios.delete(`/central-backend/${this.apiVersion}/fleet-management-crud/delete-company-alert-contact?id=${userId}`)
			.then((response) => {
				return response?.data?.message ?? 'Alert contact deleted successfully!';
			})
			.catch((error) => {
				throw error?.response?.data?.detail ?? 'Failed to remove contact from list!';
			});
	};
}
