import React from 'react';
import { virtualDesktops } from './constants';
import { DasCard } from './components/DasCard';
import { getDAS } from '../../services/Daas';

export const DAS = () => {
	const handleCardOnClick = () => { return getDAS(); };

	return (
		<div className="mt-5 ms-5">
			<h1 className="text-muted font-weight-light">Virtual Desktops</h1>
			<p className="text-muted font-weight-light mt-4">
				The following virtual desktops have been enabled for your account
			</p>

			<div className="desktops-container">
				{virtualDesktops &&
					virtualDesktops.length &&
					virtualDesktops.map((desktop) => {
						return <DasCard key={desktop.id} onClick={handleCardOnClick} os={desktop.os} name={desktop.name} />;
					})}
			</div>
		</div>
	);
};
