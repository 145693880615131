import React, { useContext, useState, useEffect } from 'react';
import { Button, Modal, Spinner, Badge, Form } from '@syneto/compass-react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import _ from 'lodash';
import { NotificationContext } from '../../../../../contexts/NotificationContext';
import { RasContext } from '../../../../../contexts/RasContext';
import { buildComboBoxOptions } from '../../../../../helpers/select';
import { ComboxBoxField } from '../../../../../components/Form';
import { RasDestinationsModal } from '../Destinations/RasDestinationsModal';
import { ServiceContext } from '../../../../../contexts/ServiceContext';
import { AddUserModal } from '../../../../Users/components/AddUserModal';
import { Does } from '../../../../../components/Access/Does';
import { StoreContext } from '../../../../../contexts/StoreContext';
import InfoIcon from '../../../../../components/Form/InfoIcon';

export const GrantRasAccessModal = (props) => {
	const { show, onHide, unassignedUsers, location, rasDestinations, isMachineAbove470 } = props;
	const { machineService, rasService } = useContext(ServiceContext);

	const { getRasByLocations, machinesRasInfo } = useContext(RasContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);

	const { state } = useContext(StoreContext);
	const { services } = state;

	const { register, handleSubmit, setValue, errors, unregister } = useForm({
		validationSchema: yup.object().shape({
			users: yup.array().required('You must select at least one user').nullable(),
			allowedDestinations: yup.array().when('route', {
				is: (val) => { return val && val !== 'allTraffic'; },
				then: () => { return yup.array().required('You must select at least one destination'); },
			}),
		}),
	});

	const [accessType, setAccessType] = useState('allTraffic');
	const [selectedDestinations, setSelectedDestinations] = useState([]);
	const [showDestinationsModal, setShowDestinationsModal] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);

	useEffect(() => {
		register('users');
	}, []);

	useEffect(() => {
		if (show && isMachineAbove470) {
			register('route');
			register('allowedDestinations');

			setValue('route', accessType);

			if (accessType === 'allTraffic') {
				setValue('allowedDestinations', []);
			} else {
				setValue(
					'allowedDestinations',
					selectedDestinations.map((option) => { return option.value; })
				);
			}
		} else {
			unregister('route');
			unregister('selectDestinations');
		}
	}, [show, isMachineAbove470, accessType]);

	const selectedLocationRasUsers =
		machinesRasInfo.fulfilled[location.gatewayMachineSerialNumber] &&
		machinesRasInfo.fulfilled[location.gatewayMachineSerialNumber].users?.length;

	const availableRasUsersSeats = services?.ras?.settings.availableSeats - selectedLocationRasUsers;

	const availableUsersSeatsString = `${availableRasUsersSeats} available ${availableRasUsersSeats === 1 ? ' seat' : ' seats'
	}`;

	const onSubmit = async (values) => {
		try {
			if (values?.users?.length > availableRasUsersSeats) {
				return pushDangerNotification(`Too many users selected. ${availableUsersSeatsString} left.`);
			}

			setSubmitting(true);

			values = {
				...values,
				hyperSerialNumber: location.gatewayMachineSerialNumber,
				hyperOsVersion: machineService.getGatewayMachineOsVersion(location),
				locationId: location.id,
				networkDestinations: rasService.prepareDestinationsForHyper(rasDestinations),
				allowedDestinations: values.allowedDestinations
					? rasService.prepareDestinationsForHyper(
						rasDestinations.filter((rasDestination) => { return values.allowedDestinations.includes(rasDestination.name); })
					)
					: [],
			};

			const addRasUsersResult = await rasService.addRasUsers(values);

			if (Object.keys(addRasUsersResult.rejected).length) {
				Object.keys(addRasUsersResult.rejected).forEach((email) => {
					pushDangerNotification(`Failed to grant RAS access to user with email ${email}.`);
				});

				setSubmitting(false);
				return false;
			}

			await getRasByLocations();
			onHide();
			pushSuccessfulNotification('Users were granted RAS access successfully.');
		} catch (e) {
			setSubmitting(false);
			onHide();
			pushDangerNotification('Failed to grant RAS access to users.');
		}
	};

	const handleMultiChangeDestinations = (selectedOptions) => {
		selectedOptions = selectedOptions || [];

		setValue(
			'allowedDestinations',
			selectedOptions.map((option) => { return option.value; }),
			true
		);
		setSelectedDestinations(selectedOptions);
	};

	return (
		<>
			<Modal show={show} onHide={onHide} slidingPanel={true}>
				<Modal.Header>
					<Modal.Title>Grant RAS Access</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<form>
						<div className="d-flex justify-content-between">
							<p className="font-weight-bold">Select Users</p>

							<Does
								loggedInUser
								havePermission="user.create"
								onCompanyResource
								yes={() => {
									return (
										<>
											<Button role="link" onClick={() => { return setShowAddModal(true); }}>
												Add User
											</Button>
											{showAddModal && <AddUserModal show={showAddModal} onHide={() => { return setShowAddModal(false); }} />}
										</>
									);
								}}
							/>
						</div>

						<p>{availableUsersSeatsString}</p>

						<ComboxBoxField
							options={unassignedUsers}
							name="users"
							placeholder="Select users"
							onChange={(selectedUsers) => { return setValue('users', selectedUsers, true); }}
							isMulti
							errors={errors}
						/>
						<p className="font-weight-bold mt-3">
							Access type
							{!isMachineAbove470 ? (
								<>
									&nbsp;<Badge appearance="warning">Not supported</Badge>
									<InfoIcon 
										placement="top" 
										tooltipStyle="pt-1 ms-2" 
										text="The selected RAS Gateway Device is at an older Syneto OS version which does not support restricted access. Please update Syneto OS to the latest version to enable this functionality."
										iconType="question"
									/>
								</>
							) : null}
						</p>
						{isMachineAbove470 ? (
							<>
								<Form.Check
									className="mb-2"
									name="route"
									label="Unrestricted"
									type="radio"
									id="allTraffic"
									checked={accessType === 'allTraffic'}
									onChange={() => {
										setAccessType('allTraffic');
										setValue('allowedDestinations', []);
									}}
									style={{ minHeight: 'auto' }}
								/>

								<Form.Check
									className="mb-4"
									name="route"
									label="Restricted to the following destinations"
									type="radio"
									id="specificDestinations"
									checked={accessType === 'specificDestinations'}
									onChange={() => { return setAccessType('specificDestinations'); }}
									style={{ minHeight: 'auto' }}
								/>

								{accessType === 'specificDestinations' && (
									<>
										<div className="d-flex justify-content-between">
											<p>Select allowed destinations</p>
											<div>
												<Button role="link" onClick={() => { return setShowDestinationsModal(true); }}>
													Add new
												</Button>
											</div>
										</div>

										<ComboxBoxField
											options={buildComboBoxOptions(rasDestinations, 'name', 'name')}
											name="allowedDestinations"
											placeholder="Select allowed destinations"
											value={selectedDestinations}
											onChange={handleMultiChangeDestinations}
											isMulti
											errors={errors}
										/>
									</>
								)}
							</>
						) : null}
					</form>
				</Modal.Body>

				<Modal.Footer>
					<div>
						<Button width={96} role="secondary" className="me-3" onClick={onHide}>
							Cancel
						</Button>

						<Button width={96} onClick={handleSubmit(onSubmit)} disabled={submitting}>
							{submitting ? (
								<span>
									<Spinner animation="border" variant="primary" size="sm" className="text-muted" />
									&nbsp; Saving...
								</span>
							) : (
								<span>Save</span>
							)}
						</Button>
					</div>
				</Modal.Footer>
			</Modal>

			{showDestinationsModal && (
				<RasDestinationsModal
					show={showDestinationsModal}
					onHide={() => { return setShowDestinationsModal(false); }}
					destinationsCurrentlyInUseInForm={selectedDestinations}
				/>
			)}
		</>
	);
};
