import React, { useContext } from 'react';
import { StoreContext } from '../../../contexts/StoreContext';

const MonitoringTab = () => {
	const { state } = useContext(StoreContext);
	const { loggedInUser } = state;
	const link = loggedInUser.company.monitoringNamespace
		? `https://${window.GRAFANA_ENVIRONMENT ?? ''}${loggedInUser.company.monitoringNamespace}.my.syneto.eu`
		: null;

	return (
		<div id="monitoring-tab">
			<div className="monitoring-splash my-5" />
			<h1 className="text-primary text-center mb-3">Monitoring details</h1>
			<p className="text-muted text-center">
				{link ? (
					<div className='d-flex flex-column'>
						<span>
							To view your monitoring data, access Cloud Analytics at the following URL: <a rel="noreferrer" href={link} target="_blank">{link}</a>
						</span>
						<span>Use 'admin' username and set a password in the 'Change Password' tab.</span>
					</div>
					
				) : (
					'Your monitoring infrastructure is not yet set up.'
				)}
			</p>
		</div>
	);
};

export default MonitoringTab;
