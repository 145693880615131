import React, { useState, useEffect, useContext } from 'react';
import { Tabs } from '@syneto/compass-react';
import { RasContext } from '../../../../contexts/RasContext';
import { ServiceContext } from '../../../../contexts/ServiceContext';
import { Does } from '../../../../components/Access/Does';
import { RASSettings } from './Settings';
import { RASDestinations } from './Destinations';
import { RasAcl } from './ACL';
import { Download } from './Download';
import { StoreContext } from '../../../../contexts/StoreContext';

export const RasTabs = (props) => {
	const { onLocationSelect } = props;

	const { accessService } = useContext(ServiceContext);
	const { machinesRasInfo, selectedLocation, getRasDestinations } = useContext(RasContext);
	const { state } = useContext(StoreContext);
	const { services } = state;

	const [activeKey, setActiveKey] = useState('settings');

	useEffect(() => {
		setActiveKey('settings');
		getRasDestinations();
	}, [selectedLocation]);

	const isStatusActive = machinesRasInfo.fulfilled[selectedLocation?.gatewayMachineSerialNumber]?.status === 'ACTIVE';

	const isDestinationsDisabled = () => {
		return (
			!services?.ras?.active ||
      !isStatusActive ||
      machinesRasInfo.rejected[selectedLocation?.gatewayMachineSerialNumber] ||
      (!accessService.hasPermissionOnCompanyResource('location.destination.list') &&
        !accessService.hasPermissionOnResource('location.destination.list', 'location', selectedLocation?.id))
		);
	};

	const isAclDisabled = () => {
		return (
			!services?.ras?.active ||
      !isStatusActive ||
      machinesRasInfo.rejected[selectedLocation?.gatewayMachineSerialNumber] ||
      (!accessService.hasPermissionOnCompanyResource('ras.user.list') &&
        !accessService.hasPermissionOnResource('ras.user.list', 'location', selectedLocation?.id))
		);
	};

	const isDownloadDisabled = () => {
		return (
			!services?.ras?.active ||
      !isStatusActive ||
      machinesRasInfo.rejected[selectedLocation?.gatewayMachineSerialNumber] ||
      (!accessService.hasPermissionOnCompanyResource('ras.view') &&
        !accessService.hasPermissionOnResource('ras.view', 'location', selectedLocation?.id))
		);
	};

	return (
		<div className="col-4 p-4">
			<Tabs activeKey={activeKey} onSelect={(key) => { return setActiveKey(key); }}>
				<Tabs.Tab eventKey="settings" title="Settings">
					<Does
						loggedInUser
						havePermission="ras.view"
						onCompanyResource
						yes={() => { return <RASSettings onLocationSelect={onLocationSelect} />; }}
						no={() => {
							return (
								<Does
									loggedInUser
									havePermission="ras.view"
									onResource={{
										type: 'location',
										referenceId: selectedLocation?.id,
									}}
									yes={() => { return <RASSettings onLocationSelect={onLocationSelect} />; }}
								/>
							); 
						}}
					/>
				</Tabs.Tab>

				<Tabs.Tab eventKey="destinations" title="Destinations" disabled={isDestinationsDisabled()}>
					<Does
						loggedInUser
						havePermission="location.destination.list"
						onCompanyResource
						yes={() => { return <RASDestinations />; }}
						no={() => {
							return (
								<Does
									loggedInUser
									havePermission="location.destination.list"
									onResource={{
										type: 'location',
										referenceId: selectedLocation?.id,
									}}
									yes={() => { return <RASDestinations />; }}
								/>
							); 
						}}
					/>
				</Tabs.Tab>

				<Tabs.Tab eventKey="ACL" title="ACL" disabled={isAclDisabled()}>
					<Does
						loggedInUser
						havePermission="ras.user.list"
						onCompanyResource
						yes={() => { return <RasAcl />; }}
						no={() => {
							return (
								<Does
									loggedInUser
									havePermission="ras.user.list"
									onResource={{
										type: 'location',
										referenceId: selectedLocation?.id,
									}}
									yes={() => { return <RasAcl />; }}
								/>
							); 
						}}
					/>
				</Tabs.Tab>

				<Tabs.Tab eventKey="download" title="Download" disabled={isDownloadDisabled()}>
					<Does
						loggedInUser
						havePermission="ras.view"
						onCompanyResource
						yes={() => { return <Download />; }}
						no={() => {
							return (
								<Does
									loggedInUser
									havePermission="ras.view"
									onResource={{
										type: 'location',
										referenceId: selectedLocation?.id,
									}}
									yes={() => { return <Download />; }}
								/>
							); 
						}}
					/>
				</Tabs.Tab>
			</Tabs>
		</div>
	);
};
