import React, { useState } from 'react';
import { Button } from '@syneto/compass-react';
import { UndeployRASConfirmationModal } from '../../RasSettingsModal/UndeployRASConfirmationModal';

export const StatusFailed = (props) => {
	const { selectedLocation } = props;

	const [showUndeployRASConfirmationModal, setShowUndeployRASConfirmationModal] = useState(false);

	return (
		<div className="mt-4 ps-2 pe-2">
			<div>
        The deployment of your RAS appliance appears to have failed. Please undeploy Remote Access, and start again the
        deploying process.
			</div>

			<div className="mt-4 ps-2 pe-2">
				<Button role="secondary" appearance="danger" block onClick={() => { return setShowUndeployRASConfirmationModal(true); }}>
          Undeploy
				</Button>
			</div>

			{showUndeployRASConfirmationModal && (
				<UndeployRASConfirmationModal
					show={showUndeployRASConfirmationModal}
					onHide={() => { return setShowUndeployRASConfirmationModal(false); }}
					onHideRASSettingsModal={() => {}}
					undeployMessage="Are you sure you want to disable RAS on this location?"
					location={selectedLocation}
				/>
			)}
		</div>
	);
};
