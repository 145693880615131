// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/img/welcome-joyride-tour.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome-joyride-tour{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-repeat:no-repeat;background-position:center;margin-bottom:30px;height:150px}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/components/OnboardingTour/style.scss"],"names":[],"mappings":"AAAA,sBACE,wDAAA,CACA,2BAAA,CACA,0BAAA,CACA,kBAAA,CACA,YAAA","sourcesContent":[".welcome-joyride-tour {\n  background-image: url(\"../../../../assets/img/welcome-joyride-tour.svg\");\n  background-repeat: no-repeat;\n  background-position: center;\n  margin-bottom: 30px;\n  height: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
