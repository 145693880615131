import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Modal } from '@syneto/compass-react';
import { StoreContext } from '../../../contexts/StoreContext';
import { InputField, Textarea } from '../../../components/Form';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { ServiceContext } from '../../../contexts/ServiceContext';

let schema = yup.object().shape({
	subject: yup.string().required('Subject is required'),
	comment: yup.string().required('Comment is required'),
});

export const CreateTicketModal = (props) => {
	const { showTicketModal, toggleTicketModal, selectedDevice } = props;
	const { machineService } = useContext(ServiceContext);
	const { state } = useContext(StoreContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { loggedInUser } = state;

	const { register, handleSubmit, formState:{ errors } } = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = async (values) => {
		try {
			await machineService.sendFreshDeskTicketEmail({
				name: `${loggedInUser.profile.firstName} ${loggedInUser.profile.lastName}`,
				email: loggedInUser.email,
				company: loggedInUser.company.name,
				serialNumber: selectedDevice.serialNumber,
				osVersion: selectedDevice.osVersion,
				esxiVersion: selectedDevice.esxiVersion,
				subject: values.subject,
				comment: values.comment,
			});
			toggleTicketModal(false);
			pushSuccessfulNotification('Ticket created successfully.');
		} catch (error) {
			pushDangerNotification('Failed to create new ticket.');
		}
	};

	return (
		<Modal
			show={showTicketModal}
			onHide={() => { return toggleTicketModal(false); }}
			content={
				<form>
					<InputField {...register('subject')} label="Summary" errors={errors} />
					<Textarea {...register('comment')} rows="5" label="Describe your request" errors={errors} />
				</form>
			}
			title="New ticket"
			actions={{
				primary: {
					label: 'Send',
					onClick: handleSubmit(onSubmit),
				},
				secondary: {
					label: 'Cancel',
				},
			}}
		/>
	);
};
