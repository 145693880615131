import moment from 'moment';

class MaintenanceStatusFormatter {
	static maintenanceStatus(expiresOn) {
		let maintenanceStatus = '';
		if (!expiresOn) {
			return (maintenanceStatus = 'Pending');
		}

		let maintenanceStatusFormatted = moment(expiresOn).add(1, 'day').format('YYYY-MM-DD');
		if (moment().isBefore(maintenanceStatusFormatted)) {
			maintenanceStatus = 'Active';
		} else {
			maintenanceStatus = 'Expired';
		}

		return maintenanceStatus;
	}

	static setExpiresOnBadge(status) {
		switch (status) {
			case 'Active':
				return 'secondary';
			case 'Expired':
				return 'danger';
			default:
				return null;
		}
	}

	static setStyle(status) {
		switch (status) {
			case 'Active':
				return 'success';
			case 'Pending':
				return 'warning';
			case 'Expired':
				return 'danger';
			default:
				return null;
		}
	}

	static setTooltipInformation(expiresOn) {
		let tooltipInformation = '';

		if (!expiresOn) {
			tooltipInformation = 'Pending. Product not activated yet!';
		} else {
			let expiresOnFormatted = moment(expiresOn).format('YYYY-MM-DD');
			if (moment().isBefore(expiresOnFormatted)) {
				tooltipInformation = `Expires on ${expiresOnFormatted}`;
			} else {
				tooltipInformation = `Expired on ${expiresOnFormatted}`;
			}
		}

		return tooltipInformation;
	}

	static showTooltipDate(expiresOn) {
		if (!expiresOn) {
			return 'Pending. Product not activated yet!';
		}

		if (moment().isBefore(moment(expiresOn, 'MM-DD-YYYY'))) {
			return `Expires on ${expiresOn}`;
		} else {
			return `Expired on ${expiresOn}`;
		}
	}
}

export default MaintenanceStatusFormatter;
