import React, { useState, useContext } from 'react';
import { Badge, Button, Alert, Tooltip } from '@syneto/compass-react';
import { RasDestinationsModal } from './RasDestinationsModal';
import { RasContext } from '../../../../../contexts/RasContext';
import { MachineContext } from '../../../../../contexts/MachineContext';
import { Does } from '../../../../../components/Access/Does';
import { Loading } from '../../../../../components/Loading';

export const RASDestinations = () => {
	const { loadingRasDestination, selectedLocation, rasDestinations } = useContext(RasContext);
	const { machinesWithOsVersionAtLeast470 } = useContext(MachineContext);

	const [showModal, setShowModal] = useState(false);

	const renderEditButton = () => {
		if (machinesWithOsVersionAtLeast470.includes(selectedLocation?.gatewayMachineSerialNumber)) {
			return (
				<>
					<Button role="tertiary" onClick={() => { return setShowModal(true); }}>
						Edit
					</Button>
					{showModal && <RasDestinationsModal show={showModal} onHide={() => { return setShowModal(false); }} />}
				</>
			);
		}

		return (
			<Tooltip
				placement="top"
				text="Add destinations to allow granular access to network services (per user firewall). Available on RAS gateway
          devices with SynetoOS version 4.7.0 and later."
			>
				<div>
					<Button role="tertiary" disabled>
						Edit
					</Button>
				</div>
			</Tooltip>
		);
	};

	if (loadingRasDestination) { return <Loading />; }

	return (
		<>
			{!machinesWithOsVersionAtLeast470.includes(selectedLocation?.gatewayMachineSerialNumber) ? (
				<Alert variant="warning" className="d-flex">
					Add destinations to allow granular access to network services (per user firewall). Available on RAS gateway
					devices with SynetoOS version 4.7.0 and later.
				</Alert>
			) : null}

			<div className="d-flex justify-content-between mb-4">
				<h5 className="d-flex align-self-center">Current Destinations</h5>
				<Does
					loggedInUser
					havePermission="location.destination.update"
					yes={renderEditButton}
					no={() => {
						return (
							<Does
								loggedInUser
								havePermission="location.destination.update"
								onResource={{
									type: 'location',
									referenceId: selectedLocation?.id,
								}}
								yes={renderEditButton}
							/>
						);
					}}
				/>
			</div>

			{rasDestinations.length ? (
				rasDestinations.map((destination) => {
					return (
						<div className="d-flex justify-content-between mb-2" key={destination.id}>
							<p>{destination.name}</p>
							<div className="d-flex flex-wrap justify-content-end">
								{destination.networkDestinations.map((networkDestination, index) => {
									return (
										<Badge className="mb-2 ms-1" key={index}>
											{networkDestination}
										</Badge>
									);
								})}
							</div>
						</div>
					);
				})
			) : (
				<div>No destinations</div>
			)}
		</>
	);
};
