import React, { useState, useContext, useEffect } from 'react';
import { Badge, Button, Icon, Alert, Tooltip } from '@syneto/compass-react';
import _ from 'lodash';
import { NotificationContext } from '../../../../../contexts/NotificationContext';
import { StoreContext } from '../../../../../contexts/StoreContext';
import { RasContext } from '../../../../../contexts/RasContext';
import { MachineContext } from '../../../../../contexts/MachineContext';
import { GrantRasAccessModal } from './GrantRasAccessModal';
import { EditRasAccessModal } from './EditRasAccessModal';
import { Loading } from '../../../../../components/Loading';
import { ServiceContext } from '../../../../../contexts/ServiceContext';
import { Does } from '../../../../../components/Access/Does';

export const RasAcl = () => {
	const { userService, machineService } = useContext(ServiceContext);
	const { machinesRasInfo, selectedLocation, rasDestinations } = useContext(RasContext);
	const { machinesWithOsVersionAtLeast470 } = useContext(MachineContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { state } = useContext(StoreContext);
	const { loggedInUser, services } = state;

	const [showGrantRasAccessModal, setShowGrantRasAccessModal] = useState(false);
	const [showEditRasAccessModal, setShowEditRasAccessModal] = useState(false);
	const [isMachineAbove470, setIsMachineAbove470] = useState(false);
	const [isRasUserSeatAvailable, setIsRasUserSeatAvailable] = useState(true);
	const [selectedUser, setSelectedUser] = useState(null);
	const [loading, setLoading] = useState(false);
	const [unassignedUsers, setUnassignedUsers] = useState([]);
	const [machineUsersDetails, setMachineUsersDetails] = useState([]);

	useEffect(() => {
		if (selectedLocation) {
			setIsMachineAbove470(!!machinesWithOsVersionAtLeast470.includes(selectedLocation.gatewayMachineSerialNumber));
		}
	}, [selectedLocation]);

	useEffect(() => {
		const machineUsers = machinesRasInfo?.fulfilled[selectedLocation?.gatewayMachineSerialNumber]?.users || [];

		setIsRasUserSeatAvailable(machineUsers.length < services?.ras?.settings.availableSeats);

		const getCompanyUsers = async () => {
			try {
				setLoading(true);

				// build machine users details array
				const companyUsers = await userService.getUsersByCompanyId(loggedInUser.company.id);
				const machineUsersDetails = _.compact(
					companyUsers.map((companyUser) => {
						const machineUser = machineUsers.find((machineUser) => { return machineUser.email === companyUser.email; });

						if (machineUser) {
							return {
								...companyUser,
								allowedDestinations: machineUser.allowedDestinations,
							};
						}
					})
				);

				setMachineUsersDetails(machineUsersDetails);

				// build unassigned users array
				let users = [];

				const unassignedUsers = _.differenceBy(companyUsers, machineUsers, 'email');

				unassignedUsers?.length &&
					unassignedUsers.forEach((unassignedUser) => {
						users.push({
							label: `${unassignedUser.profile.firstName} ${unassignedUser.profile.lastName}`,
							value: unassignedUser.email,
							id: unassignedUser.id,
						});
					});

				setUnassignedUsers(users);
				setLoading(false);
			} catch (e) {
				pushDangerNotification('Failed to fetch users data.');
			}
		};

		getCompanyUsers();
	}, [selectedLocation, machinesRasInfo]);

	const renderGrantAccessButton = () => {
		return (
			<>
				{isRasUserSeatAvailable ? (
					<Button role="link" onClick={() => { return setShowGrantRasAccessModal(true); }}>
						Grant Access
					</Button>
				) : (
					<Tooltip
						placement="top"
						text={`The maximum allowed number of users (${machinesRasInfo?.fulfilled[selectedLocation?.gatewayMachineSerialNumber]?.users?.length}) has been reached.`}
					>
						<div>
							<Button role="link" disabled>
								Grant Access
							</Button>
						</div>
					</Tooltip>
				)}

				{showGrantRasAccessModal && (
					<GrantRasAccessModal
						unassignedUsers={unassignedUsers}
						rasDestinations={rasDestinations}
						isMachineAbove470={isMachineAbove470}
						location={selectedLocation}
						show={showGrantRasAccessModal}
						onHide={() => { return setShowGrantRasAccessModal(false); }}
					/>
				)}
			</>
		);
	};

	const renderEditButton = (user) => {
		return (
			<Button
				role="link"
				onClick={() => {
					setShowEditRasAccessModal(true);
					setSelectedUser(user);
				}}
			>
				Edit
			</Button>
		);
	};

	const renderEditModal = () => {
		return (
			showEditRasAccessModal && (
				<EditRasAccessModal
					user={selectedUser}
					rasDestinations={rasDestinations}
					isMachineAbove470={isMachineAbove470}
					show={showEditRasAccessModal}
					onHide={() => { return setShowEditRasAccessModal(false); }}
				/>
			)
		);
	};

	const sendRASAdditionalSeatsRequestEmail = async () => {
		try {
			await machineService.sendRASAdditionalSeatsRequestEmail(
				selectedLocation?.gatewayMachineSerialNumber,
				loggedInUser.id
			);
			pushSuccessfulNotification('Additional users request has been successfully sent.');
		} catch (error) {
			pushDangerNotification('Failed to send additional users request.');
		}
	};

	if (loading) { return <Loading />; }

	return machineUsersDetails.length ? (
		<>
			{!isRasUserSeatAvailable ? (
				<>
					<Alert variant="warning" className="d-flex">
						<div className="d-flex flex-column">
							<p>
								The maximum number of licensed RAS users has been reached. If you need additional RAS users, you can
								request them using the button below. An email will be sent with your request and you will be contacted
								immediately.
							</p>
							<Button
								role="secondary"
								appearance="warning"
								className="mb-4"
								onClick={sendRASAdditionalSeatsRequestEmail}
							>
								Request additional users
							</Button>
						</div>
					</Alert>
				</>
			) : null}

			<div className="d-flex justify-content-between mb-4">
				<div className="d-flex flex-row">
					<h5 className="me-2">Users</h5>
					<Badge>{machineUsersDetails.length}</Badge>
				</div>
				<Does
					loggedInUser
					havePermission="ras.user.create"
					onCompanyResource
					yes={renderGrantAccessButton}
					no={() => {
						return (
							<Does
								loggedInUser
								havePermission="ras.user.create"
								onResource={{
									type: 'location',
									referenceId: selectedLocation?.id,
								}}
								yes={renderGrantAccessButton}
							/>
						);
					}}
				/>
			</div>

			{machineUsersDetails.map((user, index) => {
				return (
					<div className="d-flex justify-content-between mb-4" key={index}>
						<div className="d-flex justify-content-between">
							<Icon name="fas fa-user-circle" style={{ fontSize: '30px' }} className="me-2" />

							<div className="d-flex flex-column">
								<b className="me-2">{`${user.profile.firstName} ${user.profile.lastName}`}</b>
								<span className="me-2 text-muted">{user.email}</span>
								{isMachineAbove470 && (
									<div className="me-2 mt-2">
										<span className="text-muted">Access:</span>{' '}
										<span>
											{user?.allowedDestinations?.length > 0 ? user.allowedDestinations.join(', ') : 'All traffic'}
										</span>
									</div>
								)}
							</div>
						</div>

						<Does
							loggedInUser
							havePermission="ras.user.update"
							onCompanyResource
							yes={() => { return renderEditButton(user); }}
							no={() => {
								return (
									<Does
										loggedInUser
										havePermission="ras.user.update"
										onResource={{
											type: 'location',
											referenceId: selectedLocation?.id,
										}}
										yes={() => { return renderEditButton(user); }}
									/>
								);
							}}
						/>
					</div>
				);
			})}

			<Does
				loggedInUser
				havePermission="ras.user.update"
				onCompanyResource
				yes={renderEditModal}
				no={() => {
					return (
						<Does
							loggedInUser
							havePermission="ras.user.update"
							onResource={{
								type: 'location',
								referenceId: selectedLocation?.id,
							}}
							yes={renderEditModal}
						/>
					);
				}}
			/>
		</>
	) : (
		<>
			<div className="no-deployment-permissions" />
			<div className="text-muted text-center">You haven’t given permission to any users</div>
			<div className="text-muted text-center">to access this deployment</div>
			<div className="mt-4 d-flex justify-content-center">
				<Does
					loggedInUser
					havePermission="ras.user.create"
					onCompanyResource
					yes={renderGrantAccessButton}
					no={() => {
						return (
							<Does
								loggedInUser
								havePermission="ras.user.create"
								onResource={{
									type: 'location',
									referenceId: selectedLocation?.id,
								}}
								yes={renderGrantAccessButton}
							/>
						);
					}}
				/>
			</div>
		</>
	);
};
