import React from 'react';
import { Icon } from '@syneto/compass-react';
import '../style.scss';

export const DasCard = (props) => {
	const { onClick, os, name } = props;

	const selectOS = selectDesktopOS(os);

	return (
		<div className="das-card" onClick={onClick}>
			<div className="das-card-cover" style={{ background: selectOS && selectOS.background }}>
				<Icon name={selectOS && selectOS.icon} />
				<p>{os}</p>
			</div>
			<div className="das-card-name">{name}</div>
		</div>
	);
};

const selectDesktopOS = (osName) => {
	if (RegExp('osx', 'i').test(osName)) {
		return desktopOS.osx;
	} else if (RegExp('windows 10', 'i').test(osName)) {
		return desktopOS.windows10;
	} else if (RegExp('windows server', 'i').test(osName)) {
		return desktopOS.windowsServer;
	} else if (RegExp('ubuntu', 'i').test(osName)) {
		return desktopOS.ubuntu;
	}
};

const desktopOS = {
	windows10: {
		icon: 'fab fa-windows',
		background: '#1D87FF',
	},
	windowsServer: {
		icon: 'fab fa-windows',
		background: '#004592',
	},
	ubuntu: {
		icon: 'fab fa-ubuntu',
		background: '#C63B00',
	},
	osx: {
		icon: 'fab fa-apple',
		background: '#A8ACBB',
	},
};
