import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@syneto/compass-react';
import _ from 'lodash';
import { ServiceContext } from '../../../contexts/ServiceContext';
import { Loading } from '../../../components/Loading';
import { NotificationContext } from '../../../contexts/NotificationContext';

export const SerenityEventsButton = (props) => {
	const { machine } = props;
	const { serenityEventService } = useContext(ServiceContext);
	const { pushDangerNotification } = useContext(NotificationContext);

	const [serenityEvents, setSerenityEvents] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		loadSerenityEventsData();
	}, [machine]);

	const loadSerenityEventsData = async () => {
		try {
			setSerenityEvents(await serenityEventService.getSerenityEventsBySerialNumbers([machine.serialNumber]));
			setLoading(false);
		} catch (error) {
			pushDangerNotification('Failed to fetch SerenITy events data.');
		}
	};

	const stringOfTotalSerenityEvents = (events) => {
		let pendingEvents = null;
		let resolvedEvents = null;
		let closedEvents = null;

		events.forEach((event) => {
			const orderedStates = _.orderBy(event.state, 'timestamp', 'desc');

			if (orderedStates[0].value === 'pending') { pendingEvents += 1; }
			if (orderedStates[0].value === 'resolved') { resolvedEvents += 1; }
			if (orderedStates[0].value === 'closed') { closedEvents += 1; }
		});

		return (
			<>
				{pendingEvents && <p className="m-0">{pendingEvents + ' Pending '}</p>}
				{resolvedEvents && <p className="m-0">{resolvedEvents + ' Resolved '}</p>}
				{closedEvents && <p className="m-0">{closedEvents + ' Closed '}</p>}
				{!pendingEvents && !resolvedEvents && !closedEvents && <p className="m-0">no events</p>}
			</>
		);
	};

	return loading ? (
		<Loading type="inline" />
	) : (
		<Button role="link" href="/serenity-events" className="pt-0">
			{stringOfTotalSerenityEvents(serenityEvents)}
		</Button>
	);
};

SerenityEventsButton.propTypes = {
	machine: PropTypes.object.isRequired,
};
