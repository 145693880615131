export const virtualDesktops = [
	{
		id: 1,
		os: 'Windows 10 Home',
		name: 'narcisa-It',
	},
	{
		id: 2,
		os: 'Ubuntu 18',
		name: 'mihai-It',
	},
	{
		id: 3,
		os: 'Ubuntu 18 LTS',
		name: 'razvan-It',
	},
	{
		id: 4,
		os: 'Windows Server',
		name: 'smb-fs',
	},
	{
		id: 5,
		os: 'OSX 10.8',
		name: 'simina-mbp',
	},
	{
		id: 6,
		os: 'OSX 10.7',
		name: 'sorin-mbp',
	},
];
