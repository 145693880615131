import React, { useContext } from 'react';
import { Spinner, Button } from '@syneto/compass-react';
import { ServiceContext } from '../../../../../contexts/ServiceContext';
import { NotificationContext } from '../../../../../contexts/NotificationContext';
import { RasContext } from '../../../../../contexts/RasContext';
import { MachineContext } from '../../../../../contexts/MachineContext';

export const StatusDeploying = (props) => {
	const { selectedLocation } = props;
	const { rasService, machineService } = useContext(ServiceContext);
	const { pushDangerNotification, pushSuccessfulNotification } = useContext(NotificationContext);
	const { getRasTableData, updateLoadingTable } = useContext(RasContext);
	const { machinesWithOsVersionAtLeast470 } = useContext(MachineContext);

	const serialNumber = selectedLocation?.gatewayMachineSerialNumber;
	const rasLogs = JSON.parse(localStorage.getItem(`${serialNumber}`));

	const cancelDeployment = async () => {
		try {
			await rasService.cancelRasDeploy(serialNumber);
			await machineService.removeRasGateway(selectedLocation?.id);

			updateLoadingTable(true);
			getRasTableData();

			localStorage.removeItem('rasLogs');

			pushSuccessfulNotification('RAS deployment process cancelled successfully.');
		} catch (error) {
			pushDangerNotification('Failed to cancel RAS deployment process.');
		}
	};

	return (
		<>
			<div className="d-flex justify-content-between mt-4 ps-2 pe-2">
				<h5 className="text-neutral-90 overflow-auto me-2">
					<b>{selectedLocation?.name}</b>
				</h5>

				<h6 className="text-neutral-90">Deploying...</h6>
			</div>

			<div className="mt-4 ps-2 pe-2">
				<h6 className="text-neutral-90">
					<Spinner animation="border" variant="primary" size="sm" className="text-muted" />
					<b> Stage</b>
				</h6>
				<hr className="neutral-40" />

				{rasLogs && rasLogs.length
					? rasLogs.map((log, index) => {
						return (
							<h6 className="mb-2" key={index}>
								{log}
							</h6>
						); 
					})
					: null}
			</div>

			{machinesWithOsVersionAtLeast470.includes(serialNumber) && (
				<div className="mt-4 ps-2 pe-2">
					<Button role="secondary" appearance="danger" block onClick={cancelDeployment}>
            Cancel Deployment
					</Button>
				</div>
			)}
		</>
	);
};
