import * as yup from 'yup';
import { rasFields } from '../../fields';

export const rasSettingsValidationSchema = {
	[rasFields.gatewayMachineSerialNumber]: yup.string().required('Cannot be empty'),
	[rasFields.fqdn]: yup
		.string()
		.required('Cannot be empty')
		.test('test-fqdn', 'FQDN is invalid (eg. syneto-ras.syneto.eu)', function (value) {
			const fqdnRegex = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/g;
			return fqdnRegex.test(value);
		}),
	[rasFields.externalNetwork]: yup.string().required('Cannot be empty'),
	[rasFields.externalIP]: yup
		.string()
		.required('Cannot be empty')
		.test('test-externalIP', 'External IP is invalid (eg. 192.168.1.252/24)', function (value) {
			const externalIpRegex =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))$/g;
			return externalIpRegex.test(value);
		}),
	[rasFields.dns]: yup
		.string()
		.required('Cannot be empty')
		.test('test-dns', 'DNS is invalid (eg. 8.8.8.8, 8.8.4.4)', function (value) {
			const dnsRegex =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g;
			return dnsRegex.test(value);
		}),
	[rasFields.gatewayIP]: yup
		.string()
		.required('Cannot be empty')
		.test('test-gatewayIP', 'Gateway IP is invalid (eg. 10.12.0.1)', function (value) {
			const gatewayIPRegex =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g;
			return gatewayIPRegex.test(value);
		}),
	[rasFields.internalNetwork]: yup.string().when('internalNetworkConfigurationMethod', {
		is: (val) => { return val !== 'none'; },
		then: () => { return yup.string().required('Cannot be empty'); },
	}),
	[rasFields.internalIP]: yup.string().when('internalNetworkConfigurationMethod', {
		is: (val) => { return val === 'manual'; },
		then: () => {
			return yup
				.string()
				.required('Cannot be empty')
				.test('test-internalIP', 'Internal IP is invalid (eg. 192.168.1.252/24)', function (value) {
					const internalIpRegex =
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))$/g;
					return internalIpRegex.test(value);
				}); 
		},
	}),
	[rasFields.publicIpOrFqdn]: yup
		.string()
		.required('Cannot be empty')
		.test('test-fqdn-publicIp', 'FQDN / Public IP invalid (eg. vpn.syneto.eu:1234 or 12.13.14.15:1617)', (value) => {
			const fqdnRegex =
        /((?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63})(:([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5]))$)/g;
			const publicIpRegex =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):([1-9]|[1-5]?[0-9]{2,4}|6[1-4][0-9]{3}|65[1-4][0-9]{2}|655[1-2][0-9]|6553[1-5])$/g;

			let isValidFqdn = fqdnRegex.test(value);
			let isValidPublicIp = publicIpRegex.test(value);

			return !(!isValidFqdn && !isValidPublicIp);
		}),
	[rasFields.selectDestinations]: yup.array().when('route', {
		is: (val) => { return val && val !== 'allTraffic'; },
		then: () => { return yup.array().required('You must select at least one destination'); },
	}),
};
