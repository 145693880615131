import React from 'react';
import './style.scss';
import { CardItem } from '../../components/CardItem';

export const Support = () => {
	return (
		<div className="mt-5 ms-5">
			<h1 className="text-muted font-weight-light">General Conditions, Knowledge Base & Support Tickets</h1>
			<p className="text-muted font-weight-light mt-4">
				{'Syneto Customers Support is available 24/7 for Mission Critical plans and from Monday to Friday (09:00am > 06:00pm CET) for Pro and SuperPro plans.'}
			</p>
			<p className="text-muted font-weight-light">
				Italy: +39 030 553 7125
				<br />
				Spain: +34 910 78 33 19
				<br />
				UK: +44 117 456 5656
				<br />
				USA: +1 929 955 1467
			</p>
			<p className="text-muted font-weight-light">
				If you have a Mission Critical plan, please dial your dedicated toll-free number.
			</p>
			<div className="items-container">
				<CardItem
					link="https://syneto.eu/wp-content/uploads/2020/11/Customer_Support_EN_v2.0.0.pdf"
					title="Support Guide"
					subtitle="General Conditions"
					coverBackgroundColor="#A63D47"
					icon="far fa-file-pdf"
				/>
				<CardItem
					link="https://helpdesk.syneto.eu/en/support/home"
					title="Support Portal"
					subtitle="KB & Tickets"
					coverBackgroundColor="#008051"
					icon="fas fa-ticket-alt"
				/>
			</div>
		</div>
	);
};
