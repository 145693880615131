const castPathToArray = (path) => {
	if (Array.isArray(path)) {
		return path;
	}

	if (path.includes('[', ']')) {
		return path.match(/[a-zA-Z]+/g);
	}

	return path.split('.');
};

export const getObjectPropertyByKeys = (object, path) => {
	path = castPathToArray(path);
	object = object[path[0]];
	if (object && path.length > 1) {
		return getObjectPropertyByKeys(object, path.slice(1));
	}

	return object;
};
