import React, { useState } from 'react';
import { Button } from '@syneto/compass-react';
import { EditUserModal } from './EditUserModal';
import PropTypes from 'prop-types';
import ConvertTimestampToDateTime from '../../../helpers/ConvertTimestampToDateTime';
import { HasRoleOnCompanyResource } from '../../../components/Access/HasRoleOnCompanyResource';
import { Does } from '../../../components/Access/Does';

export const UserDetails = (props) => {
	const { user, loadUsersData } = props;

	const [showEditModal, setShowEditModal] = useState(false);

	return (
		<>
			<div className="d-flex justify-content-between mb-4">
				<h5 className="d-flex align-self-center">{`${user.profile.firstName} ${user.profile.lastName}`}</h5>
				<Does
					loggedInUser
					havePermission="user.update"
					onCompanyResource
					yes={() => {
						return (
							<>
								<Button role="tertiary" onClick={() => { return setShowEditModal(true); }}>
									Edit
								</Button>
								{showEditModal && (
									<EditUserModal
										show={showEditModal}
										onHide={() => { return setShowEditModal(false); }}
										user={user}
										loadUsersData={loadUsersData}
									/>
								)}
							</>
						);
					}}
				/>
			</div>

			<div className="d-flex justify-content-between mb-4">
				<p className="text-muted">Email</p>
				<p>{user.email}</p>
			</div>

			<div className="d-flex justify-content-between mb-4">
				<p className="text-muted">Role</p>
				<p>{user.profile.roleInTheCompany}</p>
			</div>

			<div className="d-flex justify-content-between mb-4">
				<p className="text-muted">Phone</p>
				<p>{user.profile.phoneNumber}</p>
			</div>

			<div className="d-flex justify-content-between mb-4">
				<p className="text-muted">Syneto Support Contact</p>
				<p>{user.profile.isSupportContact ? 'yes' : 'no'}</p>
			</div>

			<div className="d-flex justify-content-between mb-4">
				<p className="text-muted">Last login</p>
				<p>{user.lastUsed ? ConvertTimestampToDateTime(user.lastUsed) : 'N/A'}</p>
			</div>

			<div className="d-flex justify-content-between mb-4">
				<p className="text-muted">Global permissions</p>
				<div>
					<HasRoleOnCompanyResource name="fleet.admin" user={user}>
						<p>Fleet Manager</p>
					</HasRoleOnCompanyResource>
					<HasRoleOnCompanyResource name="user.admin" user={user}>
						<p>User Manager</p>
					</HasRoleOnCompanyResource>
				</div>
			</div>
		</>
	);
};

UserDetails.propTypes = {
	user: PropTypes.shape({
		id: PropTypes.string.isRequired,
		email: PropTypes.string.isRequired,
		policies: PropTypes.array,
		profile: PropTypes.shape({
			id: PropTypes.string.isRequired,
			firstName: PropTypes.string.isRequired,
			lastName: PropTypes.string.isRequired,
			phoneNumber: PropTypes.string.isRequired,
			roleInTheCompany: PropTypes.string.isRequired,
			hasVirtualApplianceAccess: PropTypes.bool,
			subscribeToNews: PropTypes.bool.isRequired,
			subscribeToProductNews: PropTypes.bool.isRequired,
			subscribeToSatisfactionSurveys: PropTypes.bool.isRequired,
			dataCollectionConsent: PropTypes.bool.isRequired,
		}),
	}).isRequired,
	loadUsersData: PropTypes.func.isRequired,
};
