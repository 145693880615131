import React from 'react';
import { Badge } from '@syneto/compass-react';
import { toUppercase } from '../../../helpers/string';
import ConvertTimestampToDateTime from '../../../helpers/ConvertTimestampToDateTime';

export const SerenityEventDetails = ({ serenityEvent, formatSerenityEventsStatus }) => {
	return (
		<>
			<h5 className="mb-4">SerenITy Event Details</h5>

			<div>
				<p className="text-muted">Status</p>
				<Badge className="mb-3" appearance={formatSerenityEventsStatus(serenityEvent.state.value)}>
					{toUppercase(serenityEvent.state.value)}
				</Badge>
			</div>

			<div>
				<p className="text-muted">Date - time</p>
				<p> {ConvertTimestampToDateTime(serenityEvent.recordTimestamp)}</p>
			</div>

			<div>
				<p className="text-muted">Event title</p>
				<p>{serenityEvent.content.subject}</p>
			</div>

			<div>
				<p className="text-muted">Event details</p>
				<p>{serenityEvent.content.body}</p>
			</div>
		</>
	);
};
